import * as React from "react";
import { useState } from "react";
import MonacoEditor from "react-monaco-editor";
import styles from "./custom-code-editor.module.scss";

function CustomCodeEditor({ codeString, onChanged }: any) {
  const [monacoValue, setMonacoValue] = useState<string>(codeString);

  function handleChange(value: string) {
    onChanged(value);
  }

  return (
    <div className={styles["main"]}>
      <div className={styles["heading"]}>
        <span>Custom Javascript Code</span>
      </div>
      <div className={styles["container"]}>
        <MonacoEditor
          width="100%"
          height="238"
          language="html"
          // theme="vs-dark"
          value={monacoValue}
          options={{
            selectOnLineNumbers: true,
            lineNumbers: "off",
            minimap: { enabled: false },
          }}
          onChange={handleChange}
          editorDidMount={() => {}}
        />
      </div>
    </div>
  );
}

export default CustomCodeEditor;
