import * as React from "react";
import styles from "./form.module.scss";
import FormContainer from "../../../prefabs/form-container/form-container";

import { useState } from "react";
import { useParams } from "react-router-dom";

export const ProjectForm = () => {
  const params = useParams();
  const [unreadedCount, setUnreadedCount] = useState(0);

  return (
    <div className={styles["form-container"]}>
      <div className={styles["top"]}>
        <div className={styles["title"]}>
          <div className={styles["heading-content"]}>
            <div className={styles["left"]}>
              <span>Forms</span>
              <span>{unreadedCount} Unread Forms</span>
            </div>
          </div>
        </div>
      </div>

      <div className={styles["forms-container"]}>
        <FormContainer
          projectId={params.projectId}
          setUnreadedCount={(count) => setUnreadedCount(count)}
          activateScroll={true}
          limit={9}
        />
      </div>
    </div>
  );
};
