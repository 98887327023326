import * as React from "react";
import styles from "./projects.module.scss";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Database } from "../../classes/Database";
import { ProjectCard } from "../../components/project-card/project-card";
import { WButton, WStack } from "../../components/wrappers/wrapper";
import { Forms, Projects as P, User } from "../../classes/bucket";
import { IUserContext, UserContext } from "../../contexts/user";
import { ProjectContext } from "../../contexts/project";
import OverlayPopup, {
  TypeOverlayProps,
} from "../../prefabs/overlay-popup/overlay-popup";
import {
  CreateProject,
  iCreateProjectValues,
} from "../../prefabs/create-project/create-project";
import { ILoadingContext, LoadingContext } from "../../contexts/loading";
import { buttons } from "../../styles/theme.module";
import { AutoContentService } from "../../services/AutoContent";
import { editor } from "../project/editor/editor";

export function Projects() {
  const autoContentService = new AutoContentService();
  const token = localStorage.getItem("token");
  const database = new Database("", token);

  const userContext = React.useContext<IUserContext>(UserContext);
  const loadingContext = React.useContext<ILoadingContext>(LoadingContext);
  const projectContext = React.useContext(ProjectContext);

  const [user, setUser] = useState<User>();

  const [overlayContent, setOverlayContent] = useState<TypeOverlayProps>(null);
  const [creatingProject, setCreatingProject] = useState<boolean>(false);
  const [forceClosePopover, setForceClosePopover] = useState<boolean>(false);

  const navigate = useNavigate();

  useEffect(() => {
    setUser(userContext.user);
    projectContext.getProjects();
    projectContext.setPage(null);
  }, []);

  const setActiveProject = (project: P) => {
    projectContext.setProject(project);
    navigate(`/project/${project._id}/editor`);
  };
  const handleNavigateSettings = (projectId: string) => {
    navigate(`/project/${projectId}/overview`);
  };

  const addProject = async (data: iCreateProjectValues) => {
    loadingContext.setLoading(true);
    setForceClosePopover(true);
    let project = await database
      .addProject(user._id, data.name, data.description, data.features, data.autocontent)
    
    if(project){
      if(data.autocontent){
        project = await autoContentService.projectGenerate(editor, project);
      }
      let projects = [...projectContext.projects, project];
      projectContext.setProjects(projects)
    }
    loadingContext.setLoading(false)
  };

  useEffect(() => {
    creatingProject &&
    setOverlayContent({
      title: "Create Project",
      onClose: () => {
        setCreatingProject(false);
        setForceClosePopover(false);
      },
      content: (
        <CreateProject
          onSave={(data) => addProject(data)}
        />
      ),
      overrideStyle: {
        width: "100%",
        maxWidth: "600px",
        height: "530px",
      },
    });
  }, [creatingProject])

  const getUnreadedFormsCount = async (id: string) => {
    const _forms = await database
      .getUnreadedForms(id)
      .catch(console.error);
    return (_forms as Forms[]).length;
  };

  return (
    <div className={styles["projects"]}>
      <div className={styles["banner"]}>
        <div className={styles["info"]}>
          <span>Upgrade to unlock more power</span>
          <span>
            More extensions. More automations. More syncs. Even more Composer
            for you.
          </span>
          <span>Compare plan details</span>
        </div>
      </div>

      <div className={styles["heading-content"]}>
        <WButton
          variant="contained"
          onClick={() => setCreatingProject(true)}
          className={`${buttons["button"]} ${buttons["primary"]}`}
        >
          Create New Website
        </WButton>
      </div>

      {projectContext.projects?.length ? (
        <div className={styles["sites"]}>
          {projectContext.projects?.map((project: any, index: number) => (
            <ProjectCard
              project={project}
              setActiveProject={() => setActiveProject(project)}
              navigateSettings={() => handleNavigateSettings(project._id)}
              key={`project-${index}`}
              unreadedForms={getUnreadedFormsCount(project._id)}
            />
          ))}
        </div>
      ) : (
        <WStack pt={3} alignItems="center">
          You don't have any website yet
        </WStack>
      )}

      <OverlayPopup
        title={overlayContent?.title}
        onClose={() => {
          overlayContent?.onClose();
          setTimeout(() => setOverlayContent(null), 250);
        }}
        overrideStyle={overlayContent?.overrideStyle}
        forceClose={forceClosePopover}
      >
        {overlayContent?.content}
      </OverlayPopup>
    </div>
  );
}
