import * as React from "react";
import { WTextField } from "../../../components/wrappers/wrapper";
import { InputWrapper } from "./input-wrapper";
import styles from "./input.module.scss";
import { Suffix } from "./suffix";

export const Color = ({ property, value, onChange }: any) => {
  const colorChange = (obj: any) => {
    processChange(obj);
  }

  function debounce(func: any, timeout = 300){
    let timer: any;
    // @ts-ignore
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
  }
  function saveInput(obj: any){
    onChange(obj)
  }
  const processChange = debounce((obj: any) => saveInput(obj));
  
  return (
    <InputWrapper property={property}>
      <WTextField
        fullWidth
        value={value}
        size={"small"}
        className={styles["text-filed"]}
        inputProps={
					{ readOnly: true }
				}
        sx={{
          "&.MuiFormControl-root": {
            ".MuiOutlinedInput-root": {
              padding: 0,
            }
          },
        }}
        suffix={
          <Suffix>
            <div className={styles["color"]} style={{ backgroundColor: value }}>
              <input
                type="color"
                value={value}
                onChange={(e) => colorChange({ property, value: e.target.value })}
              ></input>
            </div>
          </Suffix>
        }
      ></WTextField>
    </InputWrapper>
  );
};
