import * as React from "react";
import styles from "./top-bar.module.scss";
import { useContext, useState } from "react";
import { ProjectContext } from "../../contexts/project";
import { UserContext } from "../../contexts/user";

import PersonIcon from "@mui/icons-material/Person";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";

import { Link, useNavigate } from "react-router-dom";

interface iRoute {
  label: string;
  path: string;
}
const routes: iRoute[] = [
  {
    label: "Feedback",
    path: "feedback",
  },
  {
    label: "Help",
    path: "help",
  },
  {
    label: "Docs",
    path: "docs",
  },
  {
    label: "Profile",
    path: "profile",
  },
];

const logo =
  "https://storage.googleapis.com/download/storage/v1/b/hq-composer-0b0f0/o/643534d768c3c2002cd2dfdd?alt=media&timestamp=1681208536516";

export function TopBar() {
  const pathname = window.location.pathname;
  const pathArr = pathname.split("/");
  const active = pathArr.pop();
  const projectContext = useContext(ProjectContext);
  const userContext = useContext(UserContext);

  const navigate = useNavigate();
  const [activeRoute, setActiveRoute] = useState<string>(active);

  const onChangeRoute = (route: iRoute) => {
    const path = `/${route.path}`;
    setActiveRoute(route.path);
    navigate(path);
  };

  return (
    <div className={styles["container"]}>
      <div className={styles["left"]}>
        <img
          src={logo}
          className={styles["logo"]}
          onClick={() => {
            navigate("/");
            setActiveRoute("");
          }}
        />
      </div>
      {projectContext.project && pathname.endsWith('editor') && (
        <div className={styles["center"]}>
          <Link
            to={`/${projectContext.project?._id}/preview?type=desktop`}
            target="_blank"
            className={styles["preview-btn"]}
          >
            <span>{projectContext.project.name}</span>
            <PlayArrowIcon />
          </Link>
        </div>
      )}
      <div className={styles["right"]}>
        {routes.map((route, index) => (
          <span
            key={`${route.path}-${index}`}
            className={`${activeRoute == route.path ? styles["active"] : ""} ${
              route.path == "profile" && styles["profile"]
            }`}
            onClick={() => {
              onChangeRoute(route);
            }}
          >
            {route.path != "profile" ? (
              route.label
            ) : (
              <>
                {userContext.user.name} <PersonIcon />
              </>
            )}
          </span>
        ))}
      </div>
    </div>
  );
}
