import * as React from "react";
import styles from "./link.module.scss";

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { editor, eventEmitter } from "../../../pages/project/editor/editor";
import { ModalService } from "../../../composer-tools/composer-base-components/modal";
import { iComponent } from "../../../composer-tools/editor-components/EditorComponent";


function ComposerLink({ children, path }: any) {
  const { mod } = useParams();
  const [modal, setModal] = useState<iComponent>();
  const [isLink, setIsLink] = useState<boolean>(true);
  const [pageIndex, setPageIndex] = useState<number>();
  const [isPreview, setIsPreview] = useState<boolean>();
  const [isInternal, setIsInternal] = useState<boolean>(true);

  const handleOpen = () => ModalService.emit("open", { modal });

  useEffect(() => {
    if (!path) return;

    let _isPreview = false;
    if (mod == "preview") _isPreview = true;
    setIsPreview(_isPreview);

    const _pageIndex = editor.getPages().findIndex((page) => page.slug == path);
    const _isInternal = _pageIndex != -1 ? true : false;
    setIsInternal(_isInternal);
    if (_isInternal) {
      setPageIndex(_pageIndex);
      return;
    }

    const modal = editor.getModals().find((el: any) => el.name == path);
    if (!modal) return;
    setIsLink(false);
    setModal(modal.getModal()[0]);
  }, []);

  const changePage = () => {
    if (!pageIndex) return;
    eventEmitter.emit("changePage", { action: "change", index: pageIndex });
  };

  const renderShowModal = () => {
    <div onClick={handleOpen} className={styles["container"]}>
      {children}
    </div>;
  };

  const renderIsLink = () => {
    return (
      <>
        {isInternal ? (
          <div onClick={changePage}>{children}</div>
        ) : (
          <a href={path} target="_blank">
            {children}
          </a>
        )}
      </>
    );
  };

  const renderIsPreview = () => {
    return isLink ? renderIsLink() : renderShowModal();
  };

  return <>{isPreview ? renderIsPreview() : <div>{children}</div>}</>;
}

export default ComposerLink;
