import { loginWithStrategy, initialize } from "@spica-devkit/identity";
import * as Identity from "@spica-devkit/identity";
import {
  login,
  loginWithStrategyFn,
  register,
} from "../classes/functions/Authentication/index";
import { User } from "../classes/bucket";

export interface signUp {
  name: string;
  email: string;
  password: string;
}

export interface signIn {
  email: string;
  password: string;
}
export class Authentication {
  publicUrl = process.env.REACT_APP_API_URL;
  googleStrategyId = "63adb17d9b0b86002b37457d";
  clientKey = "c64t17lchjqx66";

  async loginWithGoogle() {
    initialize({
      apikey: this.clientKey,
      publicUrl: this.publicUrl,
    });

    const promise = new Promise((resolve, reject) => {
      loginWithStrategy(this.googleStrategyId).then((res) => {
        const popupWindow = window.open(
          res.url,
          "popup",
          "width=600,height=600"
        );
        res.token.toPromise().then(async (token) => {
          loginWithStrategyFn({ data: { token } })
            .then((res: { token: string; user: User }) => {
              if (res.token) {
                localStorage.setItem("token", res.token);
                resolve(res);
              }
            })
            .catch((err: string) => {
              console.log("error", err);
              reject();
            });

          popupWindow.close();
        });
      });
    });

    return promise;
  }

  singIn(signInData: signIn) {
    return new Promise((res, rej) => {
      login({ data: signInData })
        .then((result: { token: string; user: User }) => {
          localStorage.setItem("token", result.token);
          res(result);
        })
        .catch((err: any) => {
          console.log(err);
          rej(err);
        });
    });
  }

  logOut() {
    localStorage.clear();
  }

  singUp(signUpData: signUp) {
    return register({ data: signUpData }) as Promise<any>;
  }

  verifyToken = async () => {
    const token = this.getToken();
    Identity.initialize({ publicUrl: this.publicUrl, apikey: this.clientKey });
    return Identity.verifyToken(token);
  };

  setToken(token: string) {
    localStorage.setItem("token", token);
  }

  getToken() {
    return localStorage.getItem("token");
  }
}
