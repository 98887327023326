import * as React from "react";
import styles from "./app.module.scss";
import { TopBar } from "../../components/top-bar/top-bar";
import { NavigationBar } from "../../components/navigation-bar/navigation-bar";
import { Footer } from "../../components/footer/footer";

import { Outlet, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

export function App() {
  let location = useLocation();
  const navbarPaths = ["overview", "domain", "seo", "analytic", "form", "webhook"];
  const footerPaths = ["editor"];

  const [showNavBar, setShowNavBar] = useState<boolean>(false);
  const [showFooter, setShowFooter] = useState<boolean>(true);

  useEffect(() => {
    let navbar = false;
    let footer = true;
    const currentPath = location.pathname.split("/").pop();

    if (navbarPaths.includes(currentPath)) navbar = true;
    if (footerPaths.includes(currentPath)) footer = false;

    setShowNavBar(navbar);
    setShowFooter(footer);
  }, [location]);

  return (
    <div className={styles["main"]}>
      <div>
        <TopBar />
        {showNavBar && <NavigationBar />}
        <Outlet />
      </div>
      {showFooter && <Footer />}
    </div>
  );
}
