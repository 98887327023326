import * as React from "react";
import SettingInput from "../setting-input";
import styles from "./inputs.module.scss";

function InputObject({ value, label, onChange }: any) {

    function handleChange(v: any, propIndex: number) {
        value[propIndex].value = v;
        onChange(value)
    }

    return (
        <div className={styles["object"]}>
            {
                value.map((v: any, index: number) => <SettingInput key={`input-${index}`} propKey={v.key} additionalParams={v.additionalParams} displayer={v.displayer} type={v.type} value={v.value} completion={v.completion} onChange={(_value: any) => { handleChange(_value, index) }}></SettingInput>)
            }
        </div>
    )
}

export default InputObject;