import * as React from "react";
import { useState } from "react";
import styles from "./localization.module.scss";
import {
  WButton,
  WIconButton,
  WMenuItem,
  WRadio,
  WSelect,
} from "../../components/wrappers/wrapper";
import { Projects } from "../../classes/bucket";
import CloseIcon from "@mui/icons-material/Close";
import { editor } from "../../pages/project/editor/editor";

interface iLocalization {
  project: Projects;
  onSave: (data: { currentLanguage: string; languages: string[] }) => void;
}
export const LocalizationPrefab = ({ project, onSave }: iLocalization) => {
  const [active, setActive] = useState<string>(project?.current_language);
  const [projectLanguages, setProjectLanguages] = useState<string[]>(project.languages)

  const handleSave = () => {
    onSave({
      currentLanguage: active,
      languages: projectLanguages.filter((el) => el != ""),
    });
  };

  const add = () => {
    if (projectLanguages.includes("")) return;
    setProjectLanguages([...projectLanguages, ""])
  };

  const remove = (lang: string) => {
    if (projectLanguages.length == 1) return;
    const newLangArr = projectLanguages.filter((el) => el != lang);
    setProjectLanguages([...newLangArr])

    if (active == lang) {
      setActive(newLangArr[0]);
    }
  };

  const change = (prev: string, current: string) => {
    if (projectLanguages.includes(current)) return;
    const index = projectLanguages.indexOf(prev);
    projectLanguages[index] = current;
    setProjectLanguages([...projectLanguages])

    if (!projectLanguages.includes(active)) {
      setActive(projectLanguages[0]);
    }
  };

  return (
    <div className={styles["container"]}>
      <div className={styles["content"]}>
        {projectLanguages?.map((language: any) => (
          <div
            key={`project-${language}`}
            className={`${styles["language-section"]}  ${
              active == language && styles["active"]
            }`}
          >
            <WSelect
              fullWidth
              className={`${styles["language-select"]}`}
              value={language}
              onChange={(_: any) => change(language, _.target.value)}
              displayEmpty
              startAdornment={ <WRadio
              value={language}
              checked={active === language}
              onChange={() => {language && setActive(language)}}
              className={styles["radio"]}
            ></WRadio>}
            >
              {editor.getAvailableLanguages().map((languageItem) => (
                <WMenuItem key={languageItem.code} value={languageItem.code}>
                  {`${languageItem.name} (${languageItem.nativeName})`}
                </WMenuItem>
              ))}
            </WSelect>
            <WIconButton onClick={() => remove(language)} className={styles["remove"]}>
              <CloseIcon />
            </WIconButton>
          </div>
        ))}
        <WButton onClick={add}>
          <span>Add New</span>
        </WButton>
      </div>
      <WButton
        className={`${styles["button"]} ${styles["save-button"]}`}
        onClick={() => {
          handleSave();
        }}
      >
        <span>Save</span>
      </WButton>
    </div>
  );
};
