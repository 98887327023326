import * as React from "react";
import { useEffect, useState } from "react";
import { WMenuItem, WSelect } from "../../wrappers/wrapper";
import styles from "./inputs.module.scss";

function InputSelect({ additionalParams, value, label, onChange }: any) {
  const [inputValue, setInputValue] = useState(value);
  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <div className={styles["select-input"]}>
      <div className={styles["label"]}>{label}</div>
      <WSelect
        className={styles["select"]}
        value={inputValue}
        onChange={(_) => {
          setInputValue(_.target.value);
          onChange(_.target.value)
        }}
      >
        {additionalParams.selectItems.map((item: any, index: number) => (
          <WMenuItem key={`item-${index}`} value={item}>
            {item}
          </WMenuItem>
        ))}
      </WSelect>
    </div>
  );
}
export default InputSelect;
