import * as React from "react";
import styles from "./preview.module.scss";
import AspectRatioIcon from "@mui/icons-material/AspectRatio";
import PersonalVideoIcon from "@mui/icons-material/PersonalVideo";
import TabletAndroidIcon from "@mui/icons-material/TabletAndroid";
import StayPrimaryPortraitIcon from "@mui/icons-material/StayPrimaryPortrait";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

const devices = {
  phone: {
    icon: <StayPrimaryPortraitIcon />,
    width: "360px",
    height: "800px",
  },
  tablet: {
    icon: <TabletAndroidIcon />,
    width: "768px",
    height: "1024px",
  },
  desktop: {
    icon: <PersonalVideoIcon />,
    width: "100%",
    height: "100%",
  },
  custom: {
    icon: <AspectRatioIcon />,
    width: "768px",
    height: "768px",
  },
};

type typeDevice = "phone" | "tablet" | "desktop" | "custom";

function Preview() {
  const searchParams = new URLSearchParams(document.location.search);
  const { projectId } = useParams();
  const ref: any = useRef();

  const [activeDevice, setActiveDevice] = useState<string>(
    searchParams.get("type") || "desktop"
  );
  const handleResize = (device: typeDevice) => {
    window.history.replaceState(null, null, `?type=${device}`);
    if (ref.current) {
      ref.current.style.width = devices[device].width;
      ref.current.style.height = devices[device].height;
    }
    setActiveDevice(device);
  };

  useEffect(() => {
    handleResize(activeDevice as typeDevice);
  }, [activeDevice]);

  return (
    <div className={styles["container"]}>
      <div className={styles["top"]}>
        <div className={styles["devices"]}>
          {Object.entries(devices).map((device, index: number) => (
            <div
              key={`device-${index}`}
              className={`${styles["device"]} ${
                activeDevice == device[0] ? styles["active"] : ""
              }`}
              onClick={() => handleResize(device[0] as typeDevice)}
            >
              {device[1].icon}
            </div>
          ))}
        </div>
      </div>
      <div
        className={`${styles["content"]} ${
          activeDevice == "custom" ? styles["custom"] : ""
        }`}
      >
        <iframe
          ref={ref}
          src={`http://localhost:3000/project/${projectId}/preview`}
          width="100%"
          height="100%"
        ></iframe>
      </div>
    </div>
  );
}

export default Preview;
