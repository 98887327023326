import * as React from "react";
import styles from "./chips.module.scss";
import { WChip, WTooltip } from "../wrappers/wrapper";
import { FC, useRef, useState } from "react";

interface iChips {
  error?: boolean,
  helperText?: string,
  items: any[];
  props?: React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > & { icon?: string };
  addItem: (value: string) => void;
  deleteItem: (value: string) => void;
}
const Chips: FC<iChips> = ({ error, helperText, items, props, addItem, deleteItem }) => {
  const ref: any = useRef();
  const [value, setValue] = useState<string>();

  const handleKeyDown = (ev: any) => {
    if (!value.trim() || items.includes(value)) return;
    if (ev.keyCode == 13 || ev.keyCode == 188) {
      addItem(value);
      setValue("");
    }
  };

  const handleOnFocus = () => {
    if (!ref.current) return;
    ref.current.style.border = "1px solid #000";
  };
  const handleOnBlur = () => {
    if (!ref.current) return;
    ref.current.style.border = "1px solid var(--border-color)";
  };

  return (
    <div className={styles["container"]}>
      <WTooltip title='You can add multiple values by putting "," between them.'>
        <div ref={ref} className={`${styles["chips-wrapper"]} ${error && styles["error"]}`}>
          {items.map((el, index) => (
            <WChip
              key={`chip-${index}`}
              label={el}
              variant="outlined"
              onDelete={() => deleteItem(el)}
            />
          ))}
          <input
            {...props}
            className={styles["input"]}
            placeholder="Feature"
            value={value}
            onChange={(e) => {
              e.target.value != "," && setValue(e.target.value);
            }}
            onKeyDown={handleKeyDown}
            onFocus={handleOnFocus}
            onBlur={handleOnBlur}
          ></input>
        </div>
      </WTooltip>
      {
        error && helperText ? <span className={styles['error-message']}>{helperText}</span> : ""
      }
    </div>
  );
};

export default Chips;
