import * as React from "react";
import styles from "./create-project.module.scss";
import {
  WButton,
  WCheckbox,
  WFormControlLabel,
  WStack,
  WTextField,
  WTextareaAutosize,
} from "../../components/wrappers/wrapper";
import { useFormik } from "formik";
import * as Yup from "yup";
import Chips from "../../components/chips/chips";
import { buttons } from "../../styles/theme.module";

interface iCreateProject {
  onSave: (data: any) => void;
}
export interface iCreateProjectValues {
  name: string;
  description: string;
  features: string[];
  autocontent: boolean;
}
export const CreateProject = ({ onSave }: iCreateProject) => {
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, "Min 3 character!")
      .required("Project name is required"),
    description: Yup.string()
      .min(5, "Min 5 character!")
      .required("Description is required"),
  });

  const formik = useFormik({
    validationSchema,
    validateOnChange: false,
    initialValues: {
      name: "",
      description: "",
      features: [],
      autocontent: false,
    },
    onSubmit: (values) => onSubmit(values),
  });

  const onSubmit = (data: iCreateProjectValues) => {
    onSave(data);
  };

  const handleDeleteChip = (value: string) => {
    const features = formik.values.features.filter((el) => el != value);
    formik.setFieldValue("features", features);
  };

  return (
    <div className={styles["container"]}>
      <p>
        The data from these fields will be used to auto generate <br />
        the content for your site.
      </p>
      <form onSubmit={formik.handleSubmit} className={styles["form"]}>
        <WStack spacing={2}>
          <WTextField
            defaultValue={""}
            error={!!formik.errors.name}
            helperText={formik.errors.name}
            placeholder="Project Name"
            name={"name"}
            autoComplete={"off"}
            onChange={formik.handleChange}
          />
          <div className={styles["texarea-wrapper"]}>
            <WTextareaAutosize
              className={`${styles["textarea"]} ${!!formik.errors.description && styles['error']}`}
              placeholder="Description"
              name={"description"}
              autoComplete={"off"}
              onChange={formik.handleChange}
              maxRows={5}
              minRows={5}
            />
            {!!formik.errors.description && formik.errors.description ? (
              <span className={styles["error-message"]}>
                {formik.errors.description}
              </span>
            ) : (
              ""
            )}
          </div>
          <Chips
            items={formik.values.features}
            addItem={(value) => formik.values.features.push(value)}
            deleteItem={(value) => handleDeleteChip(value)}
          />
          <WFormControlLabel
            name={"autocontent"}
            onChange={formik.handleChange}
            className={styles["autocontent-checkbox"]}
            control={<WCheckbox checked={formik.values.autocontent} />}
            label="Auto Content Generate"
          />
        </WStack>
      </form>
      <WButton
        variant="contained"
        className={`${buttons["button"]} ${buttons["success"]} ${styles["save"]}`}
        onClick={() => formik.handleSubmit()}
      >
        Create
      </WButton>
    </div>
  );
};
