import * as React from "react";
import styles from "./form-container.module.scss";
import FormCard from "../../components/form-card/form-card";

import { Forms } from "../../classes/bucket";
import { Database } from "../../classes/Database";
import { LoadingContext } from "../../contexts/loading";
import { FC, useContext, useEffect, useRef, useState } from "react";
import { AlertModal } from "../../components/alert-modal/alert-modal";
import { WCircularProgress, WModal } from "../../components/wrappers/wrapper";

interface iFormContainer {
  projectId: string;
  limit: number;
  activateScroll?: boolean;
  setUnreadedCount?: (count: number) => void;
}
const FormContainer: FC<iFormContainer> = ({
  limit,
  projectId,
  activateScroll,
  setUnreadedCount,
}) => {
  const listInnerRef = useRef();
  const loadingContext = useContext(LoadingContext);

  const token = localStorage.getItem("token");
  const database = new Database("", token);

  const [skip, setSkip] = useState<number>(0);
  const [forms, setForms] = useState<Forms[]>([]);
  const [selectedForm, setSelectedForm] = useState<Forms>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [breakFetch, setBreakFetch] = useState<boolean>(false);
  const [openFormModal, setOpenFormModal] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);

  useEffect(() => {
    getUnreadedFormsCount();
  }, []);

  useEffect(() => {
    getForms();
  }, [skip]);

  const onScroll = () => {
    if (!activateScroll) return;
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      let sT = Math.ceil(scrollTop);
      if (sT + clientHeight === scrollHeight) {
        if (!breakFetch) setSkip(skip + limit);
      }
    }
  };

  const handleShowContact = async (form: Forms) => {
    if (form.seen) return;
    loadingContext.setLoading(true);
    form.seen = true;
    await database
      .updateForm(form._id, form)
      .finally(() => loadingContext.setLoading(false));
    resetStates();
  };

  const getForms = () => {
    setIsLoading(true);
    database
      .getForms(projectId, skip, limit)
      .then((res) => {
        if (!res.length) {
          setBreakFetch(true);
          return;
        }
        let _forms = [...forms, ...res];
        setForms(_forms);
      })
      .finally(() => setIsLoading(false));
  };

  const getUnreadedFormsCount = async () => {
    const _forms = await database
      .getUnreadedForms(projectId)
      .catch(console.error);

    setUnreadedCount((_forms as Forms[]).length);
  };

  const resetStates = () => {
    setBreakFetch(false);
    setForms([]);
    setSkip(0);
    getUnreadedFormsCount();
  };

  return (
    <div className={styles["container"]}>
      <div
        className={styles["forms-container"]}
        onScroll={() => onScroll()}
        ref={listInnerRef}
      >
        {!forms.length && !isLoading && <span>Nothing to see here!</span>}
        <div className={styles["forms"]}>
          {forms.map((formItem, index: number) => {
            const formData = JSON.parse(formItem.data);
            return (
              <FormCard
                key={`form-${formItem._id}`}
                data={formData}
                date={formItem.created_at}
                seen={formItem.seen}
                onClick={() => {
                  setOpenFormModal(true);
                  setSelectedForm(formItem);
                }}
              />
            );
          })}
        </div>
        {isLoading && <WCircularProgress size="2.5rem" />}
      </div>
      <WModal open={openFormModal} onClose={() => setOpenFormModal(false)}>
        {selectedForm && (
          <FormCard
            data={JSON.parse(selectedForm.data)}
            date={selectedForm.created_at}
            seen={selectedForm.seen}
            showForm={true}
            onClose={() => {
              setOpenFormModal(false);
              setSelectedForm(null);
              handleShowContact(selectedForm);
            }}
            onDelete={() => setOpenDeleteModal(true)}
          />
        )}
      </WModal>

      <AlertModal
        onConfirm={() => {
          loadingContext.setLoading(true);
          database
            .removeForm(selectedForm._id)
            .then(() => {
              setOpenDeleteModal(false);
              setOpenFormModal(false);
              resetStates();
            })
            .finally(() => {
              loadingContext.setLoading(false);
            });
        }}
        title="Delete form"
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
      >
        Are you sure you want the delete the form?
      </AlertModal>
    </div>
  );
};

export default FormContainer;
