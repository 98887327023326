import * as React from "react";
import { useEffect, useState } from "react";
import styles from "./inputs.module.scss";
function InputBoolean({value,label,onChange}: any) {
  const [inputValue, setInputValue] = useState(value);

  useEffect(() => {
    setInputValue(value);
  }, [value])
  
  return <div className={styles["boolean"]}>
    <div className={styles["label"]}>{label}</div>
    <input className={styles["input"]} type="checkbox" checked={inputValue} onChange={(_) => {setInputValue(!_.target.checked);onChange(_.target.checked)}}></input>
  </div>;
}
export default InputBoolean;
