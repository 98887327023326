import * as React from "react";
import { useState, createContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Projects } from "../classes/bucket";
import { Database } from "../classes/Database";
import ProjectService from "../services/ProjectService";
import { Page } from "../classes/Page";
import { setProjectHook } from "../composer-tools/custom-hooks/project";

export interface IProjectContext {
  project: Projects;
  setProject: React.Dispatch<React.SetStateAction<Projects>>;
  projects: Projects[];
  setProjects: React.Dispatch<React.SetStateAction<Projects[]>>;
  getProjects: () => void;
  page: Page;
  setPage: React.Dispatch<React.SetStateAction<Page>>;
}

export const ProjectContext = createContext<IProjectContext | null>(null);

export const ProjectContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const token = localStorage.getItem("token");
  const database = new Database("", token);
  const [project, setProject] = useState<Projects>();
  const [projects, setProjects] = useState<Projects[]>();
  const [page, setPage] = useState<Page>();

  const handleProject = (project: Projects) => {
    const index = projects.findIndex((item) => item._id == project._id);
    if (index >= 0) {
      projects[index] = project;
      setProjects(projects);
    }
    setProject(project);
    setProjectHook(project);
    ProjectService.setProject(project);
  };

  const getProjects = async () => {
    database.getProjects().then((projects: any) => {
      setProjects(projects);
    });
  };

  useEffect(() => {
    if(!token) return;
    getProjects();
  }, []);

  return (
    <ProjectContext.Provider
      value={{
        project,
        setProject: handleProject,
        projects,
        setProjects,
        getProjects,
        page,
        setPage
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
};
