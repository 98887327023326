import * as React from "react";
import styles from "./component-elements.module.scss";
import { useEffect, useState } from "react";
import ComponentPopover from "../component-popover/component-popover";
import ComponentCSSPopover from "../component-css-popover/component-css-popover";

export function ComponentElements({
  component,
  onPropChanged,
  editingCSS,
  cssClasses,
  onCSSChanged,
  handleAddCssClass,
  page,
  isPreview,
  type,
}: any) {
  const [activeTab, setActiveTab] = useState("Content");
  const tabs = ["Content", "Design"];

  useEffect(() => {
    setActiveTab(type);
  }, [type]);

  return (
    <div className={styles["main"] + " " + (isPreview && styles["preview"])}>
      {component ? (
        <div className={styles["tabs"]}>
          {tabs.map((tab) => (
            <span
              className={activeTab == tab ? styles["active"] : ""}
              key={tab}
              onClick={() => setActiveTab(tab)}
            >
              {tab}
            </span>
          ))}
        </div>
      ) : (
        ""
      )}

      {activeTab == "Content" && (
        <div className={styles["content"]}>
          {component && (
            <ComponentPopover
              component={component}
              onPropChanged={onPropChanged}
            />
          )}
        </div>
      )}
      {activeTab == "Design" && (
        <div className={styles["design"]}>
          {component && editingCSS && (
            <ComponentCSSPopover
              component={editingCSS.component}
              projectCssClasses={cssClasses}
              onCSSChanged={(cssClassId, css) => onCSSChanged(cssClassId, css)}
              onClassAdded={(sectionName, className, value) =>
                handleAddCssClass(
                  editingCSS.component,
                  editingCSS.index,
                  sectionName,
                  className,
                  value
                )
              }
              onClassDisposed={(sectionName, value) => {
                page.updateComponentCssClasses(
                  editingCSS.component,
                  editingCSS.index,
                  sectionName,
                  value
                );
                page.savePage();
              }}
            ></ComponentCSSPopover>
          )}
        </div>
      )}
    </div>
  );
}
