import { User } from "../classes/bucket";
import { FunctionService } from "../classes/Function";
import { Database, iDatabase } from "../classes/Database";
import { Authentication } from "./Authentication";

export type IUser = User;

export class UserService {
  private authentication = new Authentication();
  private functionService = new FunctionService();
  private database: iDatabase = new Database("", this.authentication.getToken());

  constructor() {}

  getUserByIdentifier(identifier:string): Promise<IUser[]> {
    return this.database.getUserByIdentifier(identifier);
  }

  updateUser(id: string, data: IUser) {
    return this.database.updateUser(id, data);
  }

  async changePassword(currentPassword: string, newPassword: string) {
    try {
      const token = await this.functionService.changePassword(
        currentPassword,
        newPassword
      );

      this.authentication.setToken(token);
      return new Promise((resolve, reject) => {
        resolve(token);
      });
    } catch (err) {
      return new Promise((resolve, reject) => {
        reject(err);
      });
    }
  }

  async forgotPassword(email: string){
    return this.functionService.forgotPassword(email)
  }
}
