import * as React from "react";
import { FC, useEffect } from "react";
import styles from "./page-meta-tag.module.scss";
import {
  WAccordion,
  WAccordionDetails,
  WAccordionSummary,
  WButton,
  WIconButton,
  WTextField,
} from "../../components/wrappers/wrapper";
import Close from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useFormik } from "formik";
import { Page } from "../../classes/Page";
import { buttons } from "../../styles/theme.module";

export interface IMetaTagFormValues {
  page: string;
  slug: string;
  values: {
    title: string;
    description: string;
    keywords: string;
    custom: string[];
  };
}
interface IPageMetaTag {
  expanded?: boolean;
  defaultExpanded?: boolean;
  firstPage?: boolean;
  page: Page;
  onChange: (data: IMetaTagFormValues) => void;
}

export const PageMetaTag: FC<IPageMetaTag> = ({
  expanded = false,
  page,
  firstPage,
  onChange,
  defaultExpanded = false,
}) => {
  const formatInitialValues = () => {
    return {
      page: page?.id,
      slug: page?.slug,
      values: {
        title: page.meta_tags?.title || "",
        description: page.meta_tags?.description || "",
        keywords: page.meta_tags?.keywords || "",
        custom: page.meta_tags?.custom || [],
      },
    };
  };

  const formik = useFormik({
    validateOnChange: false,
    initialValues: formatInitialValues(),
    onSubmit: (values) => onChange(values),
  });

  useEffect(() => {
    onChange(formik.values);
  }, [formik.values]);

  const customFieldAction = (
    action: "add" | "remove",
    page: string,
    index: number
  ) => {
    const custom = [...formik.values.values.custom];
    if (action == "add") {
      custom.push("");
    } else {
      custom.splice(index, 1);
    }

    formik.values.values.custom = custom;
    formik.setValues({ ...formik.values });
  };

  return (
    <div className={styles["meta-container"]}>
      {!firstPage && (
        <div className={styles["tex-field-wrapper"]}>
          <WTextField
            placeholder="slug: e.g. index"
            name={`slug`}
            autoComplete={"off"}
            value={formik.values.slug}
            onChange={formik.handleChange}
          />
        </div>
      )}
      <div className={styles["tex-field-wrapper"]}>
        <WTextField
          placeholder="Frontend Composer"
          prefix={"<title>"}
          suffix={"</title>"}
          name={`values.title`}
          autoComplete={"off"}
          value={formik.values.values.title}
          onChange={formik.handleChange}
        />
      </div>
      <div className={styles["tex-field-wrapper"]}>
        <WTextField
          placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua"
          prefix={'<meta name="description" content="'}
          suffix={'">'}
          name={`values.description`}
          autoComplete={"off"}
          value={formik.values.values.description}
          onChange={formik.handleChange}
        />
      </div>
      <div className={styles["tex-field-wrapper"]}>
        <WTextField
          placeholder="HTML, CSS, JavaScript"
          prefix={'<meta name="keywords" content="'}
          suffix={'">'}
          name={`values.keywords`}
          autoComplete={"off"}
          value={formik.values.values.keywords}
          onChange={formik.handleChange}
        />
      </div>
      {formik.values.values.custom.map((el: string, customIndex: number) => {
        return (
          <div className={styles["tex-field-wrapper"]}>
            <WTextField
              key={`custom-${customIndex}`}
              name={`values.custom.${customIndex}`}
              autoComplete={"off"}
              value={el}
              onChange={formik.handleChange}
              suffix={
                <WIconButton
                  onClick={() =>
                    customFieldAction("remove", formik.values.page, customIndex)
                  }
                >
                  <Close />
                </WIconButton>
              }
            />
          </div>
        );
      })}

      <WButton
        variant="contained"
        className={`${buttons["button"]} ${buttons["primary"]} ${styles["add-button"]}`}
        onClick={() => customFieldAction("add", formik.values.page, 0)}
      >
        Add Custom Field
      </WButton>
    </div>
  );
};
