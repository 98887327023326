import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class NotificationService {
  errorNotification(message: string) {
    toast.error(message);
  }
  succesNotification(message: string) {
    toast.success(message);
  }
  warningNotification(message: string) {
    toast.warning(message);
  }
  infoNotification(message: string) {
    toast.info(message);
  }
}

const notificationService = new NotificationService();
export default notificationService;
