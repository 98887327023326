import { Authentication } from "../services/Authentication";
import { changePassword, forgotPassword } from "./functions/Authentication";
import { addPaymentMethod, deletePaymentMethod, getPaymentMethod, updatePaymentMethod } from "../classes/functions/Stripe";
import { addDomain, removeDomain, verifyDomain } from "./functions/Project";

import { publishProject, insertForm } from "./functions/Project";
import { contentGenerate } from "./functions/Auto_Content_Generator";
import { analyticsData, analyticsTableData } from "./functions/Analytics";

export interface iFunction {
  changePassword(current_password: string, new_password: string): Promise<any>;
  forgotPassword(email: string): Promise<any>;
  publishProject(id: string): Promise<any>;
  contentGenerate(completions: {completion:string, value:string}[], definition: string): Promise<any>;
}
export class FunctionService implements iFunction {
  private authentication = new Authentication();
  private token = this.authentication.getToken();

  constructor() { }

  changePassword(current_password: string, new_password: string) {
    return changePassword({
      data: {
        authorization: this.token,
        currentPassword: current_password,
        newPassword: new_password,
      },
    });
  }

  forgotPassword(email: string) {
    return forgotPassword({ data: { email } });
  }
  getPaymentMethod = (customer_id: string) => {
    return getPaymentMethod({ params: { customer: customer_id, } })
  }
  addPaymentMethod = (method_id: string) => {
    return addPaymentMethod({ data: { method_id, authorization: this.authentication.getToken() } })
  }
  updatePaymentMethod = (method_id: string, billing_details: {}) => {
    return updatePaymentMethod({ data: { method_id, authorization: this.authentication.getToken(), billing_details } })
  }
  deletePaymentMethod = (method_id: string) => {
    return deletePaymentMethod({ data: { method_id, authorization: this.authentication.getToken() } })
  }
  addDomain = (domains: string[], project_id: string) => {
    return addDomain({ data: { domains, project_id, authorization: this.authentication.getToken() } })
  }
  verifyDomain = (domain:string) => {
    return verifyDomain({ data: { domain, authorization: this.authentication.getToken() } })
  }
  removeDomain = (domain:string) => {
    return removeDomain({ data: { domain, authorization: this.authentication.getToken() } })
  }
  publishProject(id: string) {
    return publishProject({data: {
      authorization: this.token,
      id
    }})
  }

  insertForm(name: string, data: Object, project: string) {
    return insertForm({ data: { name, data, project } });
  }

  contentGenerate(completions: {completion:string, value:string}[], definition: string){
    return contentGenerate({data: {completions, definition}})
  }

  analyticsData(authorization: string, project_id: string, date_from: Date, date_until: Date){
    return analyticsData({data: {authorization, project_id, date_from, date_until}})
  }

  analyticsTableData(authorization: string, project_id: string, date_from: Date, date_until: Date){
    return analyticsTableData({data: {authorization, project_id, date_from, date_until}})
  }
}
