import * as React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionDetailsProps,
  AccordionProps,
  AccordionSummary,
  AccordionSummaryProps,
  Button,
  ButtonProps,
  CircularProgress,
  Box,
  Container,
  ContainerProps,
  IconButton,
  IconButtonProps,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ListItemButtonProps,
  ListItemIcon,
  ListItemIconProps,
  Modal,
  ModalProps,
  Stack,
  CircularProgressProps,
  StackProps,
  TextField,
  TextFieldProps,
  ListProps,
  ListItemProps,
  ListItemTextProps,
  ListItemText,
  TextareaAutosize,
  TextareaAutosizeProps,
  Tabs,
  TabsProps,
  Tab,
  TabProps,
  SliderProps,
  Slider,
  FormControlLabelProps,
  FormControlLabel,
  CheckboxProps,
  Checkbox,
  Select,
  SelectProps,
  MenuItem,
  MenuItemProps,
  Radio,
  RadioProps,
  InputLabel,
  InputLabelProps,
  FormControl,
  FormControlProps,
  DialogProps,
  Dialog,
  DialogContent,
  DialogContentProps,
  BoxProps,
  Tooltip,
  TooltipProps,
  Chip,
  ChipProps
} from "@mui/material";

import { TabContext, TabContextProps, TabPanel, TabPanelProps } from "@mui/lab";

import style from "./wrapper.module.scss";
import { LoadingButton } from "@mui/lab";
import { LoadingButtonProps } from "@mui/lab/LoadingButton";

type IContainer = {} & ContainerProps;
export const WContainer = (props: IContainer) => {
  return <Container {...props}> {props.children}</Container>;
};

type IMuiList = {} & ListProps;
export const WList = (props: IMuiList) => {
  return (
    <List
      sx={{
        p: 0,
        ".MuiListItemIcon-root": {
          minWidth: 36,
        },
        ".MuiListItemButton-root": {
          borderRadius: "6px",
          marginBottom: "4px",
        },
      }}
      {...props}
    >
      {props.children}
    </List>
  );
};
type IMuiListItemButton = {} & ListItemButtonProps;
export const WListItemButton = (props: IMuiListItemButton) => {
  return <ListItemButton {...props}>{props.children} </ListItemButton>;
};

type IMuiListItem = {} & ListItemProps;
export const WListItem = (props: IMuiListItem) => {
  return <ListItem {...props}>{props.children} </ListItem>;
};

type IMuiListItemIcon = {} & ListItemIconProps;
export const WListItemIcon = (props: IMuiListItemIcon) => {
  return <ListItemIcon {...props}>{props.children} </ListItemIcon>;
};

type IMuiListItemText = {} & ListItemTextProps;
export const WListItemText = (props: IMuiListItemText) => {
  return <ListItemText {...props}>{props.children} </ListItemText>;
};

type IMuiTextField = {
  suffix?: React.ReactNode;
  prefix?: React.ReactNode | any;
} & TextFieldProps;
export const WTextField = (props: IMuiTextField) => {
  return (
    <div className={style["text-field-wrapper"]}>
      {props.prefix && <div className={style["prefix"]}>{props.prefix}</div>}
      <TextField
        {...props}
        className={style["text-field"]}
        sx={{
          "& fieldset": { borderColor: 'var(--border-color)' },
          "&:focus-within fieldset, &:focus-visible fieldset": {
            border: "1px solid #000!important",
          },
        }}
      />
      {props.suffix && <div className={style["suffix"]}>{props.suffix}</div>}
    </div>
  );
};

type IMuiButton = {} & ButtonProps;
export const WButton = (props: IMuiButton) => {
  return (
    <Button
      className={style["button"]}
      variant={props.variant || "outlined"}
      {...props}
    >
      {props.children}
    </Button>
  );
};

type IMuiLoadingButton = {} & LoadingButtonProps;
export const WLoadingButton = (props: IMuiLoadingButton) => {
  return (
    <LoadingButton
      className={style["button"]}
      variant={props.variant || "outlined"}
      {...props}
    >
      {props.children}
    </LoadingButton>
  );
};

type IMuiModal = {
  width?: number;
  height?: number;
  padding?: string;
} & ModalProps;
export const WModal = (props: IMuiModal) => {
  return (
    <Modal {...props}>
      <div className={style["modal-container"]}>
        <Box
          className={style["content"]}
          sx={{ width: props.width, height: props.height }}
          padding={props.padding || 2}
        >
          {props.children}
        </Box>
      </div>
    </Modal>
  );
};

type IIconButton = {} & IconButtonProps;
export const WIconButton = (props: IIconButton) => {
  return <IconButton {...props}>{props.children}</IconButton>;
};

type IAccordion = {} & AccordionProps;
export const WAccordion = (props: IAccordion) => {
  return <Accordion {...props}>{props.children}</Accordion>;
};

type IAccordionSummary = {
  expandIcon?: React.ReactNode;
} & AccordionSummaryProps;
export const WAccordionSummary = (props: IAccordionSummary) => {
  return (
    <AccordionSummary {...props} expandIcon={props.expandIcon}>
      {props.children}
    </AccordionSummary>
  );
};

type IAccordionDetails = {} & AccordionDetailsProps;
export const WAccordionDetails = (props: IAccordionDetails) => {
  return <AccordionDetails {...props}>{props.children}</AccordionDetails>;
};

type IMuiStack = {} & StackProps;
export const WStack = (props: IMuiStack) => {
  return <Stack {...props}>{props.children} </Stack>;
};

type ITextareaAutosize = {
  maxRows?: number;
} & TextareaAutosizeProps;
export const WTextareaAutosize = (props: ITextareaAutosize) => {
  return <TextareaAutosize maxRows={props.maxRows} {...props} />;
};

type ICircularProgress = {} & CircularProgressProps;
export const WCircularProgress = (props: ICircularProgress) => {
  return <CircularProgress {...props} />;
};

type ITabContext = {} & TabContextProps;
export const WTabContext = (props: ITabContext) => {
  return <TabContext {...props} />;
};

type ITabs = { displayIndicator?: boolean } & TabsProps;
export const WTabs = (props: ITabs) => {
  return (
    <Tabs
      sx={{
        ".MuiTabs-indicator": {
          display: props.displayIndicator ? "" : "none",
        },
      }}
      {...props}
    />
  );
};

type ITab = {} & TabProps;
export const WTab = (props: ITab) => {
  return <Tab {...props} />;
};

type ITabPanel = {} & TabPanelProps;
export const WTabPanel = (props: ITabPanel) => {
  return <TabPanel {...props} />;
};

type ISlider = {} & SliderProps;
export const WSlider = (props: ISlider) => {
  return <Slider {...props} />;
};

type ICheckbox = {} & CheckboxProps;
export const WCheckbox = (props: ICheckbox) => {
  return <Checkbox {...props} />;
};

type IFormControlLabel = {} & FormControlLabelProps;
export const WFormControlLabel = (props: IFormControlLabel) => {
  return <FormControlLabel {...props} />;
};

type ISelect = {} & SelectProps;
export const WSelect = (props: ISelect) => {
  return <Select {...props} sx={{
    "& fieldset": { borderColor: 'var(--border-color)' },
    "&:focus-within fieldset, &:focus-visible fieldset": {
      border: "1px solid #000!important",
    },
  }} />;
};

type IMenuItem = {} & MenuItemProps;
export const WMenuItem = (props: IMenuItem) => {
  return <MenuItem {...props} />;
};

type IRadio = {} & RadioProps;
export const WRadio = (props: IRadio) => {
  return <Radio {...props} />;
};

type IInputLabel = {} & InputLabelProps;
export const WInputLabel = (props: IInputLabel) => {
  return <InputLabel {...props} />;
};

type IFormControl = {} & FormControlProps;
export const WFormControl = (props: IFormControl) => {
  return <FormControl {...props} />;
};
type IDialog = {} & DialogProps;
export const WDialog = (props: IDialog) => {
  return <Dialog {...props}>{props.children}</Dialog>;
};

type IDialogContent = {} & DialogContentProps;
export const WDialogContent = (props: IDialogContent) => {
  return <DialogContent {...props}>{props.children}</DialogContent>;
};

type IBox = {
  width?: number;
  height?: number;
  padding?: string;
  position?: string;
  top?: number;
  left?: number;
  bottom?: number;
  right?: number;
  zIndex?: number;
  background?: string;
  transform?: string;
} & BoxProps;
export const WBox = (props: IBox) => {
  return (
    <Box
      {...props}
      sx={{
        width: props.width,
        height: props.height,
        padding: props.padding,
        position: props.position,
        top: props.top,
        left: props.left,
        bottom: props.bottom,
        right: props.right,
        zIndex: props.zIndex,
        background: props.background,
        transform: props.transform,
      }}
    >
      {props.children}
    </Box>
  );
};

type ITooltip = {} & TooltipProps;
export const WTooltip = (props: ITooltip) => {
  return <Tooltip {...props} />;
};

type IChip = {} & ChipProps;
export const WChip = (props: IChip) => {
  return <Chip {...props} />;
};
