import * as Storage from "@spica-devkit/storage";
import { Authentication } from "../services/Authentication";

const authentication = new Authentication();

export class SpicaStorage {
  constructor() {
    const token = authentication.getToken();
    const initConfig = {
      publicUrl: process.env.REACT_APP_API_URL,
      identity: token,
    };
    Storage.initialize(initConfig);
  }

  upload(data: any) {
    return Storage.insert(data)
  }
}
