import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import axios from "axios";
import * as React from "react";
import { useEffect, useState } from "react";
import { loadFont } from "../../custom-hooks/load-font";
import styles from "./font-family-selection.module.scss";

function FontFamilySelection({ defaultValue, onChange }: any) {
  const [loadedFonts, setLoadedFonts] = useState(null);
  const [googleFonts, setGoogleFonts] = useState(null);

  const [value, setValue] = useState<string | null>(null);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    setLoadedFonts(
      Array.from(
        new Set([
          ...[defaultValue.family],
          ...["Roboto", "Open Sans", "Noto Sans JP", "Montserrat"],
        ])
      )
    );
    getGoogleFonts();
  }, []);

  useEffect(() => {
    if (loadedFonts) loadFont(loadedFonts);
  }, [loadedFonts]);

  useEffect(() => {
    if (value && !loadedFonts.includes(value)) {
      loadedFonts.unshift(value);
      setLoadedFonts([...loadedFonts]);
    }
  }, [value]);

  async function getGoogleFonts() {
    const response = await axios.get(
      "https://www.googleapis.com/webfonts/v1/webfonts?sort=popularity&key=AIzaSyDmBJKwStdAo4cjPjhh8_W5g-d2i9WsgZY"
    );
    setGoogleFonts(response.data.items.map((font: any) => font.family));
    return response.data;
  }

  return (
    <div className={styles["container"]}>
      <Autocomplete
        options={googleFonts}
        value={value}
        onChange={(event: any, newValue: string | null) => {
          setValue(newValue);
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={(params) => <TextField {...params} label="Google fonts" />}
      />
      <div className={styles["fonts"]}>
        {loadedFonts &&
          loadedFonts.map((font: any, index: number) => (
            <div
              key={`font-${index}`}
              className={
                styles["font"] +
                " " +
                (defaultValue.family === font ? styles["active"] : "")
              }
              style={{ fontFamily: font }}
              onClick={() => onChange({ family: font })}
            >
              <b>Font family: {font}</b> <br></br>
              <br></br>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </div>
          ))}
      </div>
    </div>
  );
}

export default FontFamilySelection;
