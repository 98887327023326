import { User } from "../classes/bucket";
import { FunctionService } from "../classes/Function";
import { Authentication } from "./Authentication";

export type IUser = User;

export class AnalyticsService {
  private authentication = new Authentication();
  private functionService = new FunctionService();

  constructor() {}

  token = this.authentication.getToken();

  analyticsData(project_id: string, date_from: Date, date_until: Date) {
    return this.functionService.analyticsData(
      this.token,
      project_id,
      date_from,
      date_until
    );
  }

  analyticsTableData(project_id: string, date_from: Date, date_until: Date) {
    return this.functionService.analyticsTableData(
      this.token,
      project_id,
      date_from,
      date_until
    );
  }
}
