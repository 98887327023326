import * as React from "react";
import { createApi } from "unsplash-js";
import styles from "./add-image-popover.module.scss";
import { FC, useEffect, useState, useRef } from "react";

import { SpicaStorage } from "../../classes/Storage";
import { customSeacrh, searchSimulator } from "./image-custom-search";

import Check from "@mui/icons-material/Check";
import DeleteForever from "@mui/icons-material/DeleteForever";
import LinearProgress from "@mui/material/LinearProgress";
import AddPhotoAlternate from "@mui/icons-material/AddPhotoAlternate";

import Link from "@mui/icons-material/Link";
import Search from "@mui/icons-material/Search";
import AttachFile from "@mui/icons-material/AttachFile";
import ImageIcon from "@mui/icons-material/Image";

interface iSearchBar {
  placeholder: string;
  icon: React.ReactNode;
  clicked?: (value: string) => void;
  onChange?: (value: string) => void;
}
const SearchBar: FC<iSearchBar> = ({
  placeholder,
  icon,
  onChange,
  clicked,
}) => {
  const [value, setValue] = useState<string>();

  return (
    <div>
      <input
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={placeholder}
        className={`${styles["input"]}`}
      />
      <div onClick={() => clicked(value)}>{icon}</div>
    </div>
  );
};

const section = {
  device: "device",
  link: "link",
  search: "search",
  unsplash_search: "unsplash_search",
};

interface iToolbarItem {
  key: string;
  title: string;
  icon: any;
}

const toolbarItems: iToolbarItem[] = [
  {
    key: section.device,
    title: "My Device",
    icon: <AttachFile />,
  },
  {
    key: section.link,
    title: "Link",
    icon: <Link />,
  },
  {
    key: section.search,
    title: "Web Search",
    icon: <Search />,
  },
  {
    key: section.unsplash_search,
    title: "Unsplash Search",
    icon: <ImageIcon />,
  },
];

interface iAddImagePopover {
  forceRender: number;
  onClosePopover: () => void;
  onChangeLink: (link: string) => void;
}
export const AddImagePopover: React.FC<iAddImagePopover> = ({
  forceRender,
  onChangeLink,
  onClosePopover,
}) => {
  const storageService = new SpicaStorage();
  const listInnerRef = useRef();
  const inputRef = useRef(null);
  const [photos, setPhotos] = useState([]);
  const [file, setFile] = useState<File>();
  const [link, setLink] = useState<string>("");
  const [query, setQuery] = useState<string>("");
  const [searchSkip, setSearchSkip] = useState<number>(0);
  const [webImages, setWebImages] = useState<string[]>([]);
  const [canSave, setCanSave] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [linkPreview, setLinkPreview] = useState<boolean>(false);
  const [activeSection, setActiveSection] = useState<string>(section.device);

  const unsplashListInnerRef = useRef();
  const [unsplashValue, setUnsplashValue] = useState([]);
  const [unsplashSearchSkip, setUnsplashSearchSkip] = useState<number>(10);
  const UNSPLASH_ACCESS_KEY = process.env.REACT_APP_UNSPLASH_ACCESS_KEY;

  useEffect(() => {
    if (query) {
      search(query);
    }
  }, [query]);

  useEffect(() => {
    if (activeSection == section.link && !linkPreview) {
      setCanSave(false);
      return;
    }

    if (link) {
      setCanSave(true);
      return;
    }

    setCanSave(false);
  }, [link, linkPreview]);

  useEffect(() => {
    setActiveSection(section.device);
    resetStates();
  }, [forceRender]);

  const resetStates = () => {
    setLink("");
    setQuery("");
    setFile(null);
    setWebImages([]);
    setSearchSkip(0);
    setIsLoading(false);
    setLinkPreview(false);
  };

  const onScroll = () => {
    if (listInnerRef.current && query) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      let sT = Math.ceil(scrollTop);
      if (sT + clientHeight === scrollHeight) search(query);
    }
  };

  const handleImageOnClick = (item: string) => {
    if (item == link) {
      setLink("");
      return;
    }
    setLink(item);
  };

  const search = async (q: string) => {
    setIsLoading(true);
    let tempWebImages: string[] = [];
    let skip = searchSkip;

    for (let i = 0; i < 3; i++) {
      const result: any = await searchSimulator(q, skip, 3);
      // const result = await customSeacrh(q, skip, 10);
      tempWebImages = [...tempWebImages, ...result];
      skip += 3;
    }

    setIsLoading(false);
    setSearchSkip(skip);
    setWebImages([...webImages, ...tempWebImages]);
  };

  const handleSave = async () => {
    if (isLoading) return;

    setIsLoading(true);
    let imageLink = link;
    if (link && file) {
      const imageRes = await storageService.upload(file);
      if (imageRes) imageLink = imageRes.url;
    }

    setIsLoading(false);
    onChangeLink(imageLink);
    onClosePopover();
  };

  const unsplash = createApi({
    accessKey: UNSPLASH_ACCESS_KEY,
  });

  const handleUpdateData = () => {
    const element = inputRef.current as any;
    if (element) {
      unsplash.search
        .getPhotos({
          query: element.value as any,
          page: 1,
          perPage: unsplashSearchSkip,
        })
        .then((json) => {
          setPhotos(json.response?.results as any);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });

      setUnsplashValue(element.value as any);
    }
  };

  const onScrollUnsplash = () => {
    if (unsplashListInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } =
        unsplashListInnerRef.current;
      let sT = Math.ceil(scrollTop);
      if (sT + clientHeight === scrollHeight) {
        setIsLoading(true);
        setUnsplashSearchSkip((prevCount) => prevCount + 10);
        handleUpdateData();
      }
    }
  };

  const deviceSection = () => {
    return (
      <div className={styles["deivce"]}>
        {link ? (
          <>
            {!isLoading && (
              <DeleteForever
                className={styles["remove-image-btn"]}
                onClick={() => setLink("")}
              />
            )}
            <img src={link} onError={() => setLink("")} />
          </>
        ) : (
          <label>
            <AddPhotoAlternate />
            <h1>Select Image to Upload</h1>
            <input
              className={styles["file-input"]}
              type="file"
              onChange={(_) => {
                setFile(_.target.files[0]);
                setLink(URL.createObjectURL(_.target.files[0]));
              }}
            />
          </label>
        )}
      </div>
    );
  };

  const linkSection = () => {
    return (
      <div className={styles["link"]}>
        <SearchBar
          key={"link"}
          icon={<Link />}
          placeholder={"Enter a Url"}
          onChange={(value) => setLink(value)}
          clicked={() => setLinkPreview(true)}
        />
        {linkPreview && (
          <img
            src={link}
            onChange={() => setLinkPreview(true)}
            onError={() => setLinkPreview(false)}
          />
        )}
      </div>
    );
  };

  const searchSection = () => {
    return (
      <div className={styles["search"]}>
        <SearchBar
          key={"search"}
          icon={<Search />}
          placeholder={"Search images..."}
          clicked={(value) => setQuery(value)}
        />
        {!!webImages.length && (
          <div
            className={styles["images"]}
            onScroll={() => onScroll()}
            ref={listInnerRef}
          >
            {webImages.map((el, index) => (
              <div
                key={index}
                className={`${el == link && styles["selected-image"]}`}
              >
                <img
                  src={el}
                  onClick={() => {
                    handleImageOnClick(el);
                  }}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };

  const unsplashSearch = () => {
    return (
      <div className={styles["search"]}>
        <div>
          <input
            ref={inputRef}
            placeholder="Search images..."
            className={styles["input"]}
          />
          <div
            onClick={() => {
              handleUpdateData();
              setUnsplashSearchSkip(10);
            }}
          >
            <Search />
          </div>
        </div>

        {!!unsplashValue.length && (
          <div
            className={styles["images"]}
            onScroll={() => onScrollUnsplash()}
            ref={unsplashListInnerRef}
          >
            {photos.map((photo: any, index: number) => (
              <div
                key={index}
                className={`${
                  photo.links.download == link && styles["selected-image"]
                }`}
              >
                <img
                  src={photo.links.download}
                  onClick={() => {
                    handleImageOnClick(photo.links.download);
                  }}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };

  const toolbarItemOnClick = (item: string) => {
    resetStates();
    setActiveSection(item);
  };

  const sectionComponent: any = {
    device: deviceSection(),
    link: linkSection(),
    search: searchSection(),
    unsplash_search: unsplashSearch(),
  };

  return (
    <div className={styles["container"]}>
      {isLoading && <LinearProgress className={styles["linear-progress"]} />}
      <div className={styles["toolbar"]}>
        <div>
          {toolbarItems.map((item, index) => (
            <div
              key={index}
              className={`${styles["toolbar-item"]} ${
                styles[activeSection == item.key && "active"]
              }`}
              onClick={() => toolbarItemOnClick(item.key)}
            >
              {item.icon}
            </div>
          ))}
        </div>
        {canSave && (
          <div
            className={`${styles["toolbar-item"]} ${
              styles["toolbar-save-item"]
            } ${isLoading && styles["disabled"]}`}
            onClick={handleSave}
          >
            <Check />
          </div>
        )}
      </div>
      <div className={styles["content"]}>{sectionComponent[activeSection]}</div>
    </div>
  );
};
