import * as React from "react";
import styles from "./seo.module.scss";
import notificationService from "../../../classes/NotificationService";

import { useFormik } from "formik";
import { editor } from "../editor/editor";
import { useEffect, useState } from "react";
import { Pages } from "../../../classes/bucket";
import { buttons } from "../../../styles/theme.module";
import { ProjectContext } from "../../../contexts/project";
import { helperService } from "../../../services/HelperService";
import { AddImagePopover } from "../../../components/add-image-popover/add-image-popover";
import OverlayPopup, {
  TypeOverlayProps,
} from "../../../prefabs/overlay-popup/overlay-popup";
import {
  IMetaTagFormValues,
  PageMetaTag,
} from "../../../components/page-meta-tag/page-meta-tag";
import {
  WButton,
  WStack,
  WTextField,
} from "../../../components/wrappers/wrapper";

export interface iMetaTagValues {
  author: string;
  og_image: string;
  forms: IMetaTagFormValues[];
}

export const ProjectSeo = () => {
  const projectContext = React.useContext(ProjectContext);
  const formik = useFormik({
    validateOnChange: false,
    initialValues: {
      author: projectContext.project.meta_tags?.author || "",
      og_image: projectContext.project.meta_tags?.og_image || "",
      forms: editor
        .clonePages(projectContext.project.pages as Pages[])
        .map((el) => {
          return {
            page: el?.id,
            slug: el?.slug,
            values: {
              title: el.meta_tags?.title || "",
              description: el.meta_tags?.description || "",
              keywords: el.meta_tags?.keywords || "",
              custom: el.meta_tags?.custom || [],
            },
          };
        }),
    },
    onSubmit: (values) => onSubmit(values),
  });

  const [count, setCount] = useState<number>(1);
  const [animation, setAnimation] = useState<boolean>(false);
  const [forceClose, setForceClose] = useState<boolean>(false);
  const [showPopover, setShowPopover] = useState<boolean>(false);
  const [overlayContent, setOverlayContent] = useState<TypeOverlayProps>(null);
  const [activePage, setActivePage] = useState<string>(
    formik.values.forms[0].page
  );

  const handleSaveMetaTags = (data: iMetaTagValues) => {
    const result = helperService.isSlugValidCheckByForm(data.forms);
    if (!result.valid) {
      notificationService.errorNotification(result.message);
      return;
    }
    const pages = editor.clonePages(projectContext.project.pages as Pages[]);
    const pagesArr: any = [];

    pages.forEach((page: any) => {
      const _page = data.forms.find((el) => el.page == page.id);
      page.meta_tags = _page.values;
      page.slug = _page.slug.split(" ").join("-").toLowerCase();
      page.savePage();
      pagesArr.push(page);
    });

    const _project = {
      ...projectContext.project,
      pages: pagesArr,
      meta_tags: {
        author: data.author,
        og_image: data.og_image,
      },
    };

    editor
      .saveProject({ ..._project })
      .then((_) => {
        notificationService.succesNotification("Saved successfully");
      })
      .catch((_) => {
        notificationService.errorNotification("Error! Please try again");
      });
  };

  const onSubmit = (fields: {
    author: string;
    og_image: string;
    forms: IMetaTagFormValues[];
  }) => {
    const normalizedData: IMetaTagFormValues[] = [];
    fields.forms.forEach((el) => {
      normalizedData.push({
        page: el.page,
        slug: el.slug,
        values: {
          title: el.values.title,
          description: el.values.description,
          keywords: el.values.keywords,
          custom: el.values.custom,
        },
      });
    });

    handleSaveMetaTags({
      author: fields.author,
      og_image: fields.og_image,
      forms: normalizedData,
    });
  };

  useEffect(() => {
    showPopover &&
      setOverlayContent({
        title: "Add Image",
        onClose: () => {
          setForceClose(false);
          setShowPopover(false);
          setCount(count + 1);
        },
        content: (
          <AddImagePopover
            forceRender={count}
            onChangeLink={(data: string) => {
              formik.setFieldValue("og_image", data);
            }}
            onClosePopover={handleForceClose}
          />
        ),
        overrideStyle: {
          position: "fixed",
          width: "650px",
          height: "500px",
        },
      });
  }, [showPopover]);

  const handleForceClose = () => {
    setShowPopover(false);
    setForceClose(true);
  };

  const getPageById = (id: string) => {
    return editor
      .clonePages(projectContext.project.pages as Pages[])
      .find((el) => el.id == id);
  };

  const handleChange = (data: IMetaTagFormValues) => {
    const index = formik.values.forms.findIndex((el) => el.page == data.page);
    formik.values.forms[index] = data;
  };

  const changePage = (id: string) => {
    setAnimation(true);
    setActivePage("");
    setTimeout(() => {
      setActivePage(id);
    }, 10);
    setTimeout(() => {
      setAnimation(false);
    }, 300);
  };

  return (
    <div className={styles["seo-container"]}>
      <div className={styles["top"]}>
        <div className={styles["title"]}>
          <div className={styles["heading-content"]}>
            <div className={styles["left"]}>
              <span>SEO</span>
              <span>Search Engine Optimization</span>
            </div>
          </div>
        </div>
      </div>

      <div className={styles["content"]}>
        <form onSubmit={formik.handleSubmit} className={styles["form"]}>
          <WStack className={styles["basic-stack-1"]}>
            <WStack className={styles["basic-stack"]} spacing={2}>
              <div className={styles["tex-field-wrapper"]}>
                <WTextField
                  placeholder="John Doe"
                  prefix={'<meta name="author" content="'}
                  suffix={'">'}
                  name={"author"}
                  autoComplete={"off"}
                  value={formik.values.author}
                  onChange={formik.handleChange}
                ></WTextField>
              </div>
              <div className={styles["tex-field-wrapper"]}>
                <WTextField
                  placeholder="https://picsum.photos/200"
                  prefix={'<meta property="og:image" content="'}
                  suffix={'">'}
                  name={"og_image"}
                  autoComplete={"off"}
                  value={formik.values.og_image}
                  onChange={formik.handleChange}
                  onClick={() => {
                    setShowPopover(true);
                    setCount(count + 1);
                  }}
                />
              </div>
            </WStack>

            <div className={styles["pages"]}>
              <div className={styles["header"]}>
                {formik.values.forms.map(
                  (formItem: IMetaTagFormValues, index: number) => {
                    return (
                      <span
                        key={`header-item-${index}`}
                        className={`
                          ${activePage == formItem.page ? styles["active"] : ""}
                          `}
                        onClick={() => changePage(formItem.page)}
                      >
                        {formItem.values.title}
                      </span>
                    );
                  }
                )}
              </div>
              <div
                className={`${styles["page-meta"]} ${
                  animation && styles["animation"]
                }`}
              >
                {activePage && (
                  <PageMetaTag
                    page={getPageById(activePage)}
                    onChange={(data) => handleChange(data)}
                  />
                )}
              </div>
            </div>
          </WStack>
          <WStack>
            <WButton
              variant="contained"
              className={`
                ${buttons["button"]}
                ${buttons["success"]}
                ${styles["save-button"]} 
              `}
              type={"submit"}
            >
              Save
            </WButton>
          </WStack>
        </form>

        <OverlayPopup
          title={overlayContent?.title}
          onClose={overlayContent?.onClose}
          overrideStyle={overlayContent?.overrideStyle}
          forceClose={forceClose}
        >
          {overlayContent?.content}
        </OverlayPopup>
      </div>
    </div>
  );
};
