const api = process.env.REACT_APP_CUSTOM_SEARCH_API;
const cx = process.env.REACT_APP_CUSTOM_SEARCH_CX;
const key = process.env.REACT_APP_CUSTOM_SEARCH_KEY;

export const customSeacrh = async (query: string, skip: number, limit: number) => {
  const itemsTemp: string[] = [];
  const result = await fetch(
    `${api}?cx=${cx}&q=${query}&key=${key}&num=${limit}&start=${skip}`,
    {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    }
  ).then((res) => res.json());

  result.items.forEach((el: any) => {
    if (el?.pagemap?.cse_thumbnail && el.pagemap.cse_thumbnail[0].src) {
      itemsTemp.push(el.pagemap.cse_thumbnail[0].src);
    }
  });

  return itemsTemp;
};

export const searchSimulator = async (query: string, skip: number, limit: number) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      const deepLinkArr = JSON.parse(JSON.stringify(testImages));
      const newArr = deepLinkArr.splice(skip, limit);
      resolve(newArr);
    }, 500);
  });
};

export const testImages = [
  "https://storage.googleapis.com/download/storage/v1/b/hq-composer-0b0f0/o/64511b1df72de2002caab85b?alt=media&timestamp=1683036969323",
  "https://master.spicaengine.com/api/storage/63c53c71bb2fc2002cd54431/view?timestamp=1673870449458",
  "https://master.spicaengine.com/api/storage/63c53c71bb2fc2002cd54432/view?timestamp=1673870449458",
  "https://master.spicaengine.com/api/storage/63c53c71bb2fc2002cd54433/view?timestamp=1673870449458",
  "https://master.spicaengine.com/api/storage/63c53c71bb2fc2002cd54434/view?timestamp=1673870449458",
  "https://master.spicaengine.com/api/storage/63c53c71bb2fc2002cd54435/view?timestamp=1673870449458",
  "https://master.spicaengine.com/api/storage/63c53c71bb2fc2002cd54436/view?timestamp=1673870449458",
  "https://master.spicaengine.com/api/storage/63c53c71bb2fc2002cd54437/view?timestamp=1673870449458",
  "https://master.spicaengine.com/api/storage/63c53c71bb2fc2002cd54438/view?timestamp=1673870449458",
  "https://master.spicaengine.com/api/storage/63c53c71bb2fc2002cd54439/view?timestamp=1673870449457",
  "https://master.spicaengine.com/api/storage/63c53c71bb2fc2002cd5443a/view?timestamp=1673870449457",
  "https://master.spicaengine.com/api/storage/63c53c71bb2fc2002cd5443b/view?timestamp=1673870449457",
  "https://master.spicaengine.com/api/storage/63c53c71bb2fc2002cd5443c/view?timestamp=1673870449457",
  "https://master.spicaengine.com/api/storage/63c53c71bb2fc2002cd5443d/view?timestamp=1673870449457",
  "https://master.spicaengine.com/api/storage/63c53c71bb2fc2002cd5443e/view?timestamp=1673870449457",
  "https://master.spicaengine.com/api/storage/63c53c71bb2fc2002cd5443f/view?timestamp=1673870449457",
  "https://master.spicaengine.com/api/storage/63c53c71bb2fc2002cd54440/view?timestamp=1673870449457",
  "https://master.spicaengine.com/api/storage/63c53c71bb2fc2002cd54441/view?timestamp=1673870449457",
  "https://master.spicaengine.com/api/storage/63c53c71bb2fc2002cd54442/view?timestamp=1673870449457",
  "https://master.spicaengine.com/api/storage/63c53c71bb2fc2002cd54443/view?timestamp=1673870449457",
  "https://master.spicaengine.com/api/storage/63c53c71bb2fc2002cd54444/view?timestamp=1673870449456",
  "https://master.spicaengine.com/api/storage/63c53c71bb2fc2002cd54445/view?timestamp=1673870449456",
  "https://master.spicaengine.com/api/storage/63c53c71bb2fc2002cd54446/view?timestamp=1673870449456",
  "https://master.spicaengine.com/api/storage/63c53c71bb2fc2002cd54447/view?timestamp=1673870449456",
  "https://master.spicaengine.com/api/storage/63c53c71bb2fc2002cd54448/view?timestamp=1673870449456",
  "https://master.spicaengine.com/api/storage/63c53c71bb2fc2002cd54449/view?timestamp=1673870449456",
  "https://master.spicaengine.com/api/storage/63c53c71bb2fc2002cd5444a/view?timestamp=1673870449456",
  "https://master.spicaengine.com/api/storage/63c53c71bb2fc2002cd5444b/view?timestamp=1673870449456",
  "https://master.spicaengine.com/api/storage/63c53c71bb2fc2002cd5444c/view?timestamp=1673870449455",
  "https://master.spicaengine.com/api/storage/63c53c71bb2fc2002cd5444d/view?timestamp=1673870449455",
  "https://master.spicaengine.com/api/storage/63c53c71bb2fc2002cd5444e/view?timestamp=1673870449455",
  "https://master.spicaengine.com/api/storage/63c53c71bb2fc2002cd5444f/view?timestamp=1673870449455",
  "https://master.spicaengine.com/api/storage/63c53c71bb2fc2002cd54450/view?timestamp=1673870449455",
  "https://master.spicaengine.com/api/storage/63c53c71bb2fc2002cd54451/view?timestamp=1673870449455",
  "https://master.spicaengine.com/api/storage/63c53c71bb2fc2002cd54452/view?timestamp=1673870449455",
  "https://master.spicaengine.com/api/storage/63c53c71bb2fc2002cd54453/view?timestamp=1673870449454",
  "https://master.spicaengine.com/api/storage/63c53c71bb2fc2002cd54454/view?timestamp=1673870449454",
  "https://master.spicaengine.com/api/storage/63c53c71bb2fc2002cd54455/view?timestamp=1673870449454",
  "https://master.spicaengine.com/api/storage/63c53c71bb2fc2002cd54456/view?timestamp=1673870449453",
  "https://master.spicaengine.com/api/storage/63c53c71bb2fc2002cd54457/view?timestamp=1673870449453",
  "https://master.spicaengine.com/api/storage/63c53c71bb2fc2002cd54458/view?timestamp=1673870449453",
];
