
import * as React from "react";
import styles from "./button-navigator.module.scss";

function ButtonNavigator({children, badge, active, onClick = () => {}}: any){
    return (
        <div className={styles["container"] + ((active) ? ` ${styles["active"]}`: "")} onClick={() => {onClick()}}>
            {badge ? <span className={styles["badge"]}>{badge}</span> : ""}
            {children}
        </div>
    )

}

export default ButtonNavigator;
