import * as React from "react";
import { useState, createContext, useEffect } from "react";
import PaymentService from "../services/PaymentService";
import { IUser, UserService } from "../services/User";

export interface IUserContext {
  user: IUser;
  setUser: React.Dispatch<React.SetStateAction<IUser>>;
  paymentMethod: any;
  setPaymentMethod: React.Dispatch<React.SetStateAction<any>>;
}

export const UserContext = createContext<IUserContext | null>(null);

export const UserContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [user, setUser] = useState<IUser>();
  const [paymentMethod, setPaymentMethod] = useState<any>();

  const getPaymentMethods = (stripe_customer_id: string) => {
    PaymentService.getPaymentMethod(stripe_customer_id)
      .then((method) => method && setPaymentMethod(method))
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    if (!user) return;
    getPaymentMethods(user.stripe_customer_id);
  }, [user]);

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        paymentMethod,
        setPaymentMethod,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
