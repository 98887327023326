import * as React from "react";
import { useState, useEffect } from "react";
import { EditComponent } from "../../components/edit-component/edit-component";
import {
  Content,
  Column,
  Header,
  Explanation,
} from "../../components/section-card/section-card";
import styles from "./profile.module.scss";
import { IUser, UserService } from "../../services/User";
import { PaymentMethod } from "../../prefabs/payment-method/payment-method";
import PaymentService from "../../services/PaymentService";
import { IUserContext, UserContext } from "../../contexts/user";
import { PaymentMethod as PM, PaymentMethodResult } from "@stripe/stripe-js";
import { helperService } from "../../services/HelperService";
import notificationService from "../../classes/NotificationService";
import { WButton, WTextField } from "../../components/wrappers/wrapper";
import Logout from "@mui/icons-material/Logout";
import { buttons } from "../../styles/theme.module";
import { useNavigate } from "react-router-dom";

function Profile() {
  const navigate = useNavigate();
  const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
  const userContext = React.useContext<IUserContext>(UserContext);
  const userService = new UserService();

  const [user, setUser] = useState<IUser>();
  const [editName, setEditName] = useState(false);
  const [editPassword, setEditPassword] = useState(false);
  const [canChangePassword, setCanChangePassword] = useState<boolean>(false);
  const [password, setPassword] = useState({
    currentPassword: "",
    newPassword: "",
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setUser(userContext.user);
  }, []);

  const onSaveCard = (method: PaymentMethodResult) => {
    setLoading(true);
    PaymentService.addPaymentMethod(method.paymentMethod.id)
      .then((addedMethod) => {
        if (!user.stripe_customer_id) {
          const updatedUser = {
            ...userContext.user,
            stripe_customer_id: method.paymentMethod.customer,
          };
          userContext.setUser(updatedUser);
        }

        userContext.setPaymentMethod(addedMethod);
      })
      .catch((error) => {
        const errorMessage = helperService.getErrorMessage(error);
        notificationService.errorNotification(errorMessage);
      })
      .finally(() => setLoading(false));
  };

  const handleDeleteMethod = () => {
    setLoading(true);
    PaymentService.deletePaymentMethod(userContext.paymentMethod.id)
      .then((_) => userContext.setPaymentMethod(null))
      .catch((error) => {
        const errorMessage = helperService.getErrorMessage(error);
        notificationService.errorNotification(errorMessage);
      })
      .finally(() => setLoading(false));
  };

  const onUpdateMethod = (details: PM.BillingDetails) => {
    setLoading(true);
    PaymentService.updatePaymentMethod(userContext.paymentMethod.id, details)
      .then((res) => {
        userContext.setPaymentMethod(res);
      })
      .catch((error) => {
        const errorMessage = helperService.getErrorMessage(error);
        notificationService.errorNotification(errorMessage);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (emailPattern.test(user?.identifier)) {
      setCanChangePassword(true);
    }
  }, [user]);

  const updateUser = (data: any) => {
    userService.updateUser(user._id, data);
  };

  const changePassword = () => {
    userService
      .changePassword(password.currentPassword, password.newPassword)
      .then((res) => {
        setEditPassword(false);
        notificationService.succesNotification("Password successfully changed");
      })
      .catch((err) => {
        const errorMessage = helperService.getErrorMessage(err);
        notificationService.errorNotification(errorMessage);
      });
  };

  return (
    <div className={styles["profile"]}>
      <div className={styles["top"]}>
        <div className={styles["title"]}>
          <div className={styles["heading-content"]}>
            <div className={styles["left"]}>
              <span>Profile</span>
            </div>
            <div className={styles["right"]}>
              <WButton
                variant="contained"
                className={`${buttons["button"]} ${buttons["danger"]}`}
                onClick={() => {
                  localStorage.clear();
                  navigate("/authentication");
                }}
              >
                <Logout />
                Logout
              </WButton>
            </div>
          </div>
        </div>
      </div>

      <div className={styles["content"]}>
        <Content>
          <Column>
            <Header>Identifier</Header>
            <Explanation>{user?.identifier}</Explanation>
          </Column>
        </Content>
        <Content>
          <Column>
            <Header>Name</Header>
            {editName ? (
              <WTextField
                className={styles["input"]}
                value={user?.name}
                onChange={(_) => {
                  setUser({ ...user, name: _.target.value });
                }}
              />
            ) : (
              <Explanation>{user?.name}</Explanation>
            )}
          </Column>
          <Column>
            <EditComponent
              disabledSave={user?.name.length < 2}
              onSave={() => {
                setEditName(false);
                updateUser({ name: user?.name });
              }}
              onEdit={() => {
                setEditName(true);
              }}
              onClose={() => {
                setEditName(false);
              }}
            />
          </Column>
        </Content>
        <Content>
          <Column>
            <Header>Payment Methods</Header>
            <PaymentMethod
              onUpdateMethod={onUpdateMethod}
              handleDeleteMethod={handleDeleteMethod}
              onSaveCard={onSaveCard}
              loading={loading}
            />
          </Column>
        </Content>
        {canChangePassword && (
          <Content>
            <Column>
              <Header>Password</Header>
              {editPassword && (
                <>
                  <input
                    name="name"
                    placeholder="Current Password"
                    className={styles["input"]}
                    type="password"
                    onChange={(_) => {
                      setPassword({
                        ...password,
                        currentPassword: _.target.value,
                      });
                    }}
                  />
                  <input
                    name="name"
                    placeholder="New Password"
                    className={styles["input"]}
                    type="password"
                    onChange={(_) => {
                      setPassword({
                        ...password,
                        newPassword: _.target.value,
                      });
                    }}
                  />
                </>
              )}
            </Column>
            <Column>
              <EditComponent
                disabledSave={
                  password.newPassword.length < 3 ||
                  password.currentPassword.length < 3
                }
                onSave={() => {
                  changePassword();
                  setEditPassword(false);
                }}
                onEdit={() => {
                  setEditPassword(true);
                }}
                onClose={() => {
                  setEditPassword(false);
                }}
              />
            </Column>
          </Content>
        )}
      </div>
    </div>
  );
}

export default Profile;
