import React, { useEffect, useState, useContext } from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Projects } from "./pages/projects/projects";
import { AuthenticationPage } from "./pages/authentication/authentication";
import Profile from "./pages/profile/profile";
import { ProjectOverview } from "./pages/project/overview/overview";
import { ProjectDomain } from "./pages/project/domain/domain";
import ProjectEditor from "./pages/project/editor/editor";
import { ProjectContextProvider } from "./contexts/project";
import { ToastContainer } from "react-toastify";
import { UserContextProvider } from "./contexts/user";
import { AuthGuard } from "./prefabs/auth-guard/auth-guard";
import { LoadingContextProvider } from "./contexts/loading";
import { LoadingContainer } from "./prefabs/loading/loading";
import Preview from "./pages/preview/preview";
import { App } from "./pages/app/app";
import UnderContruction from "./pages/under-contruction/under-contruction";
import { ProjectForm } from "./pages/project/form/form";
import { ProjectSeo } from "./pages/project/seo/seo";
import { ProjectWebhook } from "./pages/project/webhook/webhook";
import { ProjectAnalytics } from "./pages/project/analytics/analytics";

window.MonacoEnvironment = {
  getWorker(moduleId, label) {
    switch (label) {
      case "css":
        // @ts-ignore
        return new Worker(
          new URL(
            "monaco-editor/esm/vs/language/css/css.worker",
            import.meta.url
          )
        );
      case "html":
        // @ts-ignore
        return new Worker(
          new URL(
            "monaco-editor/esm/vs/language/html/html.worker",
            import.meta.url
          )
        );
      default:
        return new Worker(
          new URL(
            "monaco-editor/esm/vs/language/html/html.worker",
            import.meta.url
          )
        );
    }
  },
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>

    <LoadingContextProvider>
      <ProjectContextProvider>
        <UserContextProvider>
          <BrowserRouter>
            <Routes>
              <Route element={<AuthGuard />}>
                <Route path="/" element={<App />}>
                  <Route path="" element={<Projects />}></Route>
                  <Route path="/projects" element={<Projects />}></Route>
                  <Route path="/profile" element={<Profile />}></Route>
                  <Route path="/feedback" element={<UnderContruction />} />
                  <Route path="/help" element={<UnderContruction />} />
                  <Route path="/docs" element={<UnderContruction />} />

                  <Route path="/project/:projectId/editor" element={<ProjectEditor />} />
                  <Route path="/project/:projectId/overview" element={<ProjectOverview />} />
                  <Route path="/project/:projectId/domain" element={<ProjectDomain />} />
                  <Route path="/project/:projectId/seo" element={<ProjectSeo />} />
                  <Route path="/project/:projectId/analytic" element={<ProjectAnalytics />} />
                  <Route path="/project/:projectId/form" element={<ProjectForm />} />
                  <Route path="/project/:projectId/webhook" element={<ProjectWebhook />} />
                </Route>
                <Route path="/project/:projectId/:mod" element={<ProjectEditor />} />
                <Route path=":projectId/preview" element={<Preview />} />
              </Route>
              <Route
                path="authentication"
                element={<AuthenticationPage />}
              ></Route>
            </Routes>
          </BrowserRouter>
          <ToastContainer />
          <LoadingContainer />
        </UserContextProvider>
      </ProjectContextProvider>
    </LoadingContextProvider>

  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
