import * as React from "react";
import styles from "./component-popover.module.scss";
import projectService from "../../services/ProjectService";

import { debounce } from "lodash";
import { useEffect, useRef, useState } from "react";
import { ComponentFactory } from "../../classes/PageBuilder";
import { AutoContentService } from "../../services/AutoContent";
import { WCircularProgress } from "../../components/wrappers/wrapper";
import { iComponent } from "../../composer-tools/editor-components/EditorComponent";
import SettingInput, {
  TypeObjectAction,
} from "../../components/setting-input/setting-input";

const autoContentService = new AutoContentService();
function ComponentPopover({ component, onPropChanged }: any) {
  const preview = useRef(null);
  const previewContainer = useRef(null);

  const project = projectService.project;

  const [copiedTSX, setCopiedTSX] = useState();
  // const [forceRelaod, setForceReload] = useState(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [propChanges, setPropChanges] = useState<any>({});
  const [componentCopy, setComponentCopy] = useState<iComponent>();

  useEffect(() => {
    setPropChanges({});
    let componentFactory = new ComponentFactory();
    let componentCopy = componentFactory.create(component);
    (component as iComponent)
      .getProps()
      .forEach((prop) => componentCopy.setProp(prop.key, prop.value));
    Object.entries((component as iComponent).getCSSClasses()).forEach(
      ([key, value]) => componentCopy.setCSSClasses(key, value as any)
    );
    setComponentCopy(componentCopy);
    setCopiedTSX(componentCopy.render());
  }, [component/*, forceRelaod*/]);

  useEffect(() => {
    previewContainer.current.style.transform = `scale(${
      preview.current.offsetWidth / window.innerWidth
    } )`;
  }, [copiedTSX]);

  async function handlePropChange(
    key: string,
    value: any,
    action: TypeObjectAction
  ) {
    if (action === "addItem") {
      setLoading(true);
      value[value.length - 1] = (
        await autoContentService.componentItemContent(project, [value.at(-1)])
      )[0];
      setLoading(false);
    }
    propChanges[key] = value;
    componentCopy.setProp(key, value);
    setCopiedTSX(componentCopy.render());
    propChanged(propChanges);
  }

  const propChanged = debounce((propChanges: string) => {
    Object.entries(propChanges).forEach((change) =>
      onPropChanged(change[0], change[1])
    );
  }, 1000);

  // async function generateContent() {
  //   setLoading(true);
  //   await autoContentService.componentContent(project, componentCopy);

  //   componentCopy.getProps().map((prop: any) => {
  //     handlePropChange(prop.key, prop.value, "");
  //   });

  //   setPropChanges(propChanges);
  //   setForceReload(forceRelaod + 1);
  //   setLoading(false);
  // }

  return (
    <div className={styles["container"]}>
      <div className={styles["settings-container"]}>
        <div className={styles["settings"]}>
          {componentCopy &&
            componentCopy.getProps().map((prop: any, index) => {
              return (
                <SettingInput
                  key={`input-${index}`}
                  propKey={prop.key}
                  additionalParams={prop.additionalParams}
                  displayer={prop.displayer}
                  type={prop.type}
                  value={prop.value}
                  onChange={(value: any, action: TypeObjectAction) => {
                    handlePropChange(prop.key, value, action);
                  }}
                ></SettingInput>
              );
            })}
        </div>
      </div>
      {loading ? (
        <div className={styles["loading-preview"]}>
          <WCircularProgress />
        </div>
      ) : (
        <div
          ref={preview}
          className={styles["preview"]}
          key={`preview-${Math.random()}`}
        >
          <div ref={previewContainer}>
            <div className={styles["preview-component"]}>{copiedTSX}</div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ComponentPopover;
