import * as React from "react";
import { WButton, WModal, WStack } from "../wrappers/wrapper";

type IAlertModal = {
  onClose: () => void;
  onConfirm: () => void;
  open: boolean;
  children: React.ReactNode;
  title: string;
};
export const AlertModal = ({
  open,
  onClose,
  children,
  title,
  onConfirm,
}: IAlertModal) => {
  return (
    <WModal open={open} onClose={onClose}>
      <WStack>
        <WStack>
          <h3>
            {title} <hr />
          </h3>
        </WStack>
        <WStack pb={4} pt={1}>
          {children}
        </WStack>
        <WStack
          direction="row"
          alignItems="center"
          justifyContent="space-around"
        >
          <WButton onClick={onClose}> Cancel</WButton>
          <WButton variant="contained" onClick={onConfirm}>
            Confirm
          </WButton>
        </WStack>
      </WStack>
    </WModal>
  );
};
