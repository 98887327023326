import { iComponent } from "../composer-tools/editor-components/EditorComponent";
import { iDatabase } from "./Database";
import { 
  CommandAddModalComponent,
  CommandDeleteModalComponent,
  CommandUpdateModalCssClasses,
  Localization,
  LocalizationInvokerModal, 
} from "./Localization/Localization";
import { ModalBuilder, ModalBuilderObserver } from "./ModalBuilder";

export type TypeLocalization = {
  language?: string;
  builder?: ModalBuilder;
}
export class Modal {
  db: iDatabase;
  localizationInvoker: LocalizationInvokerModal = new LocalizationInvokerModal(this);
  locale: Localization = new Localization();
  constructor(db: iDatabase, name: string) {
    this.db = db;
    this.name = name;
  }

  private _id: string;
  public get id(): string {
    return this._id;
  }
  public set id(value: string) {
    this._id = value;
  }
  private _name: string;
  public get name(): string {
    return this._name;
  }
  public set name(value: string) {
    this._name = value;
  }
  private _localization: TypeLocalization[] = [];
  public get localization(): TypeLocalization[] {
    return this._localization;
  }
  public set localization(value: TypeLocalization[]) {
    this._localization = value;
  }

  currentBuilder(): ModalBuilder{
    return this.localization.filter(localization => localization.language == this.locale.currentLanguage)[0].builder;
  }

  subscribe(callBack: ModalBuilderObserver) {
    return this.currentBuilder().subscribe((component, action, pageJson) =>
      callBack(component, action, pageJson)
    );
  }

  getModal(): iComponent[] {
    return this.currentBuilder().getModal();
  }

  addModalComponent(component: iComponent): void {
    this.currentBuilder().addModal(component);
    this.localizationInvoker.addCommand(new CommandAddModalComponent(component));
  }

  updateModalComponent(
    component: iComponent,
    propKey: string,
    propValue: any
  ): void {
    this.currentBuilder().update(component, propKey, propValue);
  }

  updateModalCssClasses(component: iComponent, index: number, sectionName: string, value: {id: string, class: string}[]) {
    this.currentBuilder().updateCSSClasses(component, sectionName, value);
    this.localizationInvoker.addCommand(new CommandUpdateModalCssClasses(index, sectionName, value));
  }

  deleteModalComponent(index: number): void {
    this.currentBuilder().deleteModal(index);
    this.localizationInvoker.addCommand(new CommandDeleteModalComponent(index));
  }

  saveModal(): Promise<any> {
    this.localizationInvoker.execute();
    return this.db.updateModal(this);
  }
}
