import * as React from "react";
import SettingInput from "../setting-input";
import styles from "./inputs.module.scss";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';

export type TypeObjectAction = "addItem" | "removeItem" | "changeValue" | "";
function ObjectArray({ value, label, onChange }: any) {
    function handleChange(v: any, propIndex: number) {
        value[propIndex].value = v;
        onChange(value, "changeValue")
    }

    function addAnotherItem() {
        let copiedProp = JSON.parse(JSON.stringify(value[0]));
        copiedProp = cleanValues(copiedProp);
        value.push(copiedProp);
        onChange(value, "addItem");
    }

    function removeItem(e: any, index: number) {
        e.stopPropagation();
        if(value.length > 1){
            value.splice(index, 1);
            onChange(value, "removeItem");
        }
    }

    function cleanValues(propValue: any) {
        if (propValue.value instanceof Array) {
            propValue.value = propValue.value.map((v: any) => cleanValues(v));
        } else {
            propValue.value = "";
        }
        return propValue
    }

    return (
        <div className={styles["object-array"]}>
            <div className={styles["accordion-label"]}>{label}</div>
            {
                value.map((v: any, index: number) => (
                    <Accordion key={`accordion-${index}`} className={styles["accordion"]}>
                        <AccordionSummary className={styles["accordion-header"]} expandIcon={<ExpandMoreIcon />}>
                            <span>Item {index}</span>
                            <div className={styles["icons"]}>
                                <div onClick={(e) => removeItem(e, index)}>
                                    <DeleteIcon className={styles["delete-icon"]} ></DeleteIcon>
                                </div>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails className={styles["accordion-body"]}>
                            <SettingInput propKey={v.key} additionalParams={v.additionalParams} displayer={v.displayer} type={v.type} value={v.value} onChange={(_value: any) => { handleChange(_value, index) }}></SettingInput>
                        </AccordionDetails>
                    </Accordion>
                ))
            }
            <div className={styles["new"]} onClick={() => addAnotherItem()}>Add new</div>
        </div>
    )
}

export default ObjectArray;