import {
  TypeReactComponent,
  iComponent,
  CATEGORIES,
  TypeUsableComponentProps,
} from "../composer-tools/editor-components/EditorComponent";
import { Modals, Pages, Projects, CSS_Classes } from "./bucket";
import { Database, iDatabase } from "./Database";
import { Page } from "./Page";
import ComponentsRegistery from "../composer-tools/editor-components/ComponentRegistery";
import { FunctionService, iFunction } from "./Function";
import { TypeLanguage, Localization } from "./Localization/Localization";
import { PageBuilder } from "./PageBuilder";
import { Modal } from "./Modal";
import { ModalBuilder } from "./ModalBuilder";
import { CSSClassNameRegistry, iCSSClassNameRegistry } from "./CSS";

export type TypeColorTheme = {
  primary?: string;
  secondary?: string;
  tertiary?: string;
};
export type TypeFontFamily = { family?: string };

export type TypeMetaTags = {
  title?: string;
  description?: string;
  keywords?: string;
  custom?: string[];
};

export type TypeEnvironments = {
  content_width?: {
    width?: number;
    full_width?: boolean;
  };
  box_shadow?: {
    horizontal_length?: number;
    vertical_length?: number;
    blur_radius?: number;
    spread_radius?: number;
    opacity?: number;
    color?: string;
  };
  html_background?: string;
  border_radius?: number;
};

export class Editor {
  token = localStorage.getItem("token");

  private locale = new Localization();
  private registery = new ComponentsRegistery();
  private database: iDatabase = new Database("", this.token);
  private function: iFunction = new FunctionService();
  private pages: Page[] = [];
  private modals: Modal[] = [];
  private cssClasses: CSS_Classes[] = [];
  private _languages: string[] = ["en"];
  private _customCode: string = "";
  private _selectedModalIndex: number = 0;
  public cssRegistry: iCSSClassNameRegistry = new CSSClassNameRegistry(this.database);
  private theme: { colors: TypeColorTheme; fonts: TypeFontFamily } = {
    colors: { primary: "", secondary: "", tertiary: "" },
    fonts: { family: "" },
  };
  public get languages(): string[] {
    return this._languages;
  }
  public set languages(value: string[]) {
    this._languages = value;
  }
  public get customCode(): string {
    return this._customCode;
  }
  public set customCode(value: string) {
    this._customCode = value;
  }
  public get selectedModalIndex(): number {
    return this._selectedModalIndex;
  }
  public set selectedModalIndex(value: number) {
    this._selectedModalIndex = value;
  }


  constructor() {}

  getComponentRegistery() {
    return this.registery;
  }

  async getProjectFromDb(projectId: string): Promise<Projects> {
    return this.database.getProject(projectId);
  }

  getAvailableLanguages(): TypeLanguage[] {
    return this.locale.availableLanguages;
  }

  getSystemCssClasses(){
    return this.database.getSystemCssClasses();
  }

  clonePages(pages: Pages[]): Page[] {
    this.pages = pages.map((page: Pages) => {
      let pageInstance = new Page(
        this.database,
        page.name,
        page.slug,
        page.meta_tags,
        page.custom_script
      );

      pageInstance.id = page._id;

      pageInstance.localization = page.localization.map((localization) => {
        let pageBuilder = new PageBuilder();
        let componentsJson: TypeReactComponent[] = JSON.parse(localization.json);
        let registeredComponents = this.registery.getComponents();

        let components = componentsJson.map((component) => {
          let registeredComponent = Object.values(registeredComponents)
            .flat(1)
            .filter((availableComponent: iComponent) => {
              return availableComponent.getName() == component.type;
            });
          return registeredComponent[0];
        });

        components.forEach((component: iComponent, index) => {
          let usableComponent = pageBuilder.add(component);
          if (componentsJson[index].props)
            componentsJson[index].props.forEach((prop) => {
              pageBuilder.update(usableComponent, prop.key, prop.value);
            });
          if (componentsJson[index].cssClasses)
            Object.entries(componentsJson[index].cssClasses).forEach(
              ([cssKey, cssClasses]) => {
                let css = {}
                cssClasses?.forEach((value) => {
                  const cssClass = this.cssClasses.find(el => el._id == value.id)
                  if(cssClass?.css){
                    css = {...JSON.parse(cssClass.css), ...css}
                  }
                });
                pageBuilder.updateCSSClasses(usableComponent, cssKey, cssClasses);
              }
            );
        });
        return {
          language: localization.language,
          builder: pageBuilder,
        };
      });
      return pageInstance;
    });
    return this.pages;
  }

  cloneModals(modals: Modals[]): Modal[] {
    this.modals = modals.map((modal: Modals) => {
      let modalInstance = new Modal(
        this.database,
        modal.name,
      );

      modalInstance.id = modal._id;

      modalInstance.localization = modal.localization.map((localization) => {
        let modalBuilder = new ModalBuilder();
        let modalsJson: TypeReactComponent[] = JSON.parse(localization.modal_json);
        let registeredComponents = this.registery.getComponents();

        let modals = modalsJson.map((component) => {
          let registeredComponent = Object.values(registeredComponents)
            .flat(1)
            .filter((availableComponent: iComponent) => {
              return availableComponent.getName() == component.type;
            });
          return registeredComponent[0];
        });

        modals.forEach((component: iComponent, index) => {
          let usableComponent = modalBuilder.addModal(component);
          if (modalsJson[index].props)
            modalsJson[index].props.forEach((prop) => {
              modalBuilder.update(usableComponent, prop.key, prop.value);
            });
          if (modalsJson[index].cssClasses)
            Object.entries(modalsJson[index].cssClasses).forEach(
              ([cssKey, cssClasses]) => {
                let css = {}
                cssClasses?.forEach((value) => {
                  const cssClass = this.cssClasses.find(el => el._id == value.id)
                  if(cssClass?.css){
                    css = {...JSON.parse(cssClass.css), ...css}
                  }
                });
                modalBuilder.updateCSSClasses(usableComponent, cssKey, cssClasses);
              }
            );
        });
        return {
          language: localization.language,
          builder: modalBuilder,
        };
      });
      return modalInstance;
    });
    return this.modals;
  }

  async cloneCssClasses(css_classes: CSS_Classes[]){
    const systemClasses = await this.getSystemCssClasses();
    this.cssClasses = [...css_classes, ...systemClasses];
    this.applyStyleCSS();
    return this.cssClasses;
  }

  applyStyleCSS(){
    let style = "";
    this.cssClasses.forEach(el => {
      if(el.css){
        style += `.${el.class_name}${el.css}`
      }
    })
    style = style.replace(/"/g, "")
      .replace(/,/g, ";")
      .replace(/;/g, " !important; ")
      .replace(/}/g, " !important} ");
  
    document.getElementById("custom-style")?.remove();
    document.head.insertAdjacentHTML("beforeend", `<style id="custom-style">${style}</style>`)
  }

  applyCustomScript(customScript: string){
    document.getElementById("customScript")?.remove();

    if(!customScript) return;
    
    const elem = document.createElement('div');
    const script = document.createElement("script");
    elem.setAttribute("id", "customScript");
    elem.appendChild(script);
    script.innerHTML = customScript;
    document.body.appendChild(elem);
  }

  getPages(): Page[] {
    return this.pages;
  }

  getModals(): Modal[] {
    return this.modals;
  }

  getCssClasses(): CSS_Classes[]{
    return this.cssClasses;
  }

  getCssClass(id: string): CSS_Classes {
    return this.cssClasses.find(el => el._id == id)
  }

  getPage(index: number): Page {
    return this.pages[index];
  }

  getModal(index: number): Modal {
    return this.modals[index];
  }

  async addPage(
    user_id: string,
    project_id: string,
    name: string,
    slug: string,
    meta_tags: TypeMetaTags,
    custom_script: string
  ): Promise<Page[]> {
    let newPage: Page = new Page(
      this.database,
      name,
      slug,
      meta_tags,
      custom_script
    );
    let currentLength = this.pages.length;
    this.languages.forEach(language => newPage.localization.push({builder: new PageBuilder(), language}))
    this.pages.push(newPage);
    const document = await this.database.addPage(user_id, project_id, newPage);
    this.pages[currentLength].id = document._id;
    return this.pages;
  }

  async addModal(
    user_id: string,
    project_id: string,
    name: string,
  ): Promise<Modal[]> {
    let newModal: Modal = new Modal(
      this.database,
      name,
    );
    let currentLength = this.modals.length;
    this.languages.forEach(language => newModal.localization.push({builder: new ModalBuilder(), language}))
    this.modals.push(newModal);
    const document = await this.database.addModal(user_id, project_id, newModal);
    this.modals[currentLength].id = document._id;
    return this.modals;
  }

  deletePage(index: number): Page[] {
    let page = this.pages[index];
    this.pages.splice(index, 1);
    this.database.removePage(page).then((res) => console.log(res));
    return this.pages;
  }

  deleteModal(index: number): Modal[] {
    let modal = this.modals[index];
    this.modals.splice(index, 1);
    this.database.removeModal(modal).then();
    return this.modals;
  }

  async cleanModal(name: string){
    let modalBuilder = new ModalBuilder();
    for(const page of this.getPages()){
      await modalBuilder.cleanModal(page, name)
    }
  }

  changeThemeColors(theme: TypeColorTheme) {
    this.theme.colors = theme;
    if (theme) {
      if (theme.primary)
        document.documentElement.style.setProperty(
          "--composer-primary-color",
          theme.primary
        );
      if (theme.secondary)
        document.documentElement.style.setProperty(
          "--composer-secondary-color",
          theme.secondary
        );
      if (theme.tertiary)
        document.documentElement.style.setProperty(
          "--composer-tertiary-color",
          theme.tertiary
        );
    }
  }

  changeThemeEnvironments(environments: TypeEnvironments) {
    if (environments) {
      document.documentElement.style.setProperty(
        "--composer-border-radius",
        environments.border_radius + "px"
      );

      let contentWidth = environments.content_width?.full_width
        ? "100%"
        : environments.content_width?.width + "px";
      document.documentElement.style.setProperty(
        "--composer-content-width",
        contentWidth as string
      );

      document.documentElement.style.setProperty(
        "--composer-html-background",
        environments.html_background
      );
    }
  }

  changeThemeFonts(fontFamily: TypeFontFamily) {
    this.theme.fonts = { family: fontFamily.family };
    document.documentElement.style.setProperty(
      "--composer-font-family",
      this.theme.fonts.family
    );
  }

  saveProject(project: Projects): Promise<Projects> {
    return this.database.updateProject(project);
  }

  publishProject(projectId: string): Promise<void> {
    return this.function.publishProject(projectId);
  }

  getAvailableComponents(): { [key in CATEGORIES]: iComponent[] } {
    return this.registery.getComponents();
  }

  async addCssClass(class_name: string, user: string, project: string): Promise<CSS_Classes>{
    const newCssClass = await this.cssRegistry.addCssClass(class_name, user, project);
    this.cssClasses.push(newCssClass)
    return newCssClass;
  }

  async updateCssClass(cssClassId: string, css: string): Promise<CSS_Classes>{
    const updatedCssClass = await this.cssRegistry.updateCssClass(cssClassId, css);
    const index = this.cssClasses.findIndex(el => el._id == cssClassId);
    this.cssClasses[index] = updatedCssClass;
    this.applyStyleCSS();
    return updatedCssClass;
  }
}
