import * as React from "react";
import { useEffect, useState, useRef, useContext } from "react";
import styles from "./inputs.module.scss";
import {
  WButton,
  WCircularProgress,
  WTextareaAutosize,
  WTextField,
  WTooltip,
} from "../../wrappers/wrapper";
import RefreshIcon from "@mui/icons-material/Refresh";
import { AutoContentService } from "../../../services/AutoContent";
import { IProjectContext, ProjectContext } from "../../../contexts/project";
import { TextIncrease } from "@mui/icons-material";

function InputString({ value, completion, label, onChange }: any) {
  const projectContext = useContext<IProjectContext>(ProjectContext);
  const autoContentService = new AutoContentService();
  const [inputValue, setInputValue] = useState(value);
  const [isLoading, setIsLoading] = useState(false);
  const [customCompletion, setCustomCompletion] = useState<string>();
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const regenerateContent = async (compValue: string) => {
    setIsLoading(true);
    const newValue = await autoContentService.regenerateContent(
      projectContext.project,
      compValue
    );
    if (newValue) {
      setInputValue(newValue);
      onChange(newValue);
    }
    setIsLoading(false);
    setShowPopup(false);
    setCustomCompletion("");
  };

  useEffect(() => {
    showPopup && document.addEventListener("click", handleHostClick);

    return () => {
      showPopup && document.removeEventListener("click", handleHostClick);
    };
  }, [showPopup]);

  const handleHostClick = () => {
    setShowPopup(false);
    setCustomCompletion("");
  };

  const handleClickShowPopup = (event: any) => {
    event.stopPropagation();
    setShowPopup(true);
  };

  return (
    <div className={styles["string"]}>
      <div className={styles["label"]}>{label}</div>
      <WTextareaAutosize
        maxRows={15}
        className={styles["textarea"]}
        value={inputValue}
        onChange={(_) => {
          setInputValue(_.target.value);
          onChange(_.target.value);
        }}
      />
      <div className={styles["completion-wrapper"]}>
        {completion && (
          <>
            {isLoading ? (
              <WCircularProgress size={16} className={styles["icon"]} />
            ) : (
              <>
                <WTooltip
                  title="Regenerate content"
                  onClick={() => regenerateContent(`Give me an alternative to '${inputValue}'`)}
                >
                  <RefreshIcon className={styles["icon"]} />
                </WTooltip>
                <WTooltip title="Custom completion" onClick={handleClickShowPopup}>
                  <TextIncrease className={styles["icon"]} />
                </WTooltip>
                {showPopup && (
                  <div
                    className={styles["custom-completion"]}
                    onClick={(event) => event.stopPropagation()}
                  >
                    <WTextareaAutosize
                      minRows={3}
                      maxRows={6}
                      className={`${styles["textarea"]} ${styles["custom-completion-area"]}`}
                      autoComplete={"off"}
                      value={customCompletion}
                      placeholder="Completion..."
                      onChange={(event) => setCustomCompletion(event.target.value)}
                    />
                    <WButton
                      className={styles["button"]}
                      onClick={() => regenerateContent(customCompletion)}
                    >
                      <span>Set Completion</span>
                    </WButton>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}
export default InputString;
