import * as React from "react";
import styles from "./form-card.module.scss";

import { FC } from "react";
import { WButton } from "../wrappers/wrapper";
import { buttons } from "../../styles/theme.module";
import { helperService } from "../../services/HelperService";

interface iFormCard {
  data: any;
  date?: string | Date;
  seen?: boolean;
  showForm?: boolean;
  onClick?: () => void;
  onClose?: () => void;
  onDelete?: () => void;
}
const FormCard: FC<iFormCard> = ({
  data,
  date,
  seen,
  showForm = false,
  onClick,
  onClose,
  onDelete,
}) => {
  const formType = (type: string) => {
    switch (type) {
      case "short":
        return (
          <>
            <div className={styles["row"]}>
              <span>Name</span>
              <span>{data.name}</span>
            </div>
            <div className={styles["row"]}>
              <span>Message</span>
              <span>{data.message}</span>
            </div>
          </>
        );
      case "full":
        return (
          <>
            {Object.entries(data).map((item, index) => (
              <div key={`enty-${index}`} className={styles["row"]}>
                <span>{item[0]}</span>
                <span>{item[1] as any}</span>
              </div>
            ))}
          </>
        );
      default:
        break;
    }
  };
  return (
    <div
      className={`${styles["form"]} ${seen ? styles["seen"] : ""} ${
        showForm ? styles["show"] : ""
      }`}
      onClick={onClick}
    >
      <div className={styles["content"]}>
        <div className={styles["row"]}>
          <div>
            <span>Date</span>
            {!seen && <span>New Message!</span>}
          </div>
          <span>
            {helperService.getFormattedDate(new Date(date), "HH:MM DD/MM/YYYY")}
          </span>
        </div>
        {formType(showForm ? "full" : "short")}
      </div>

      {showForm && (
        <div className={styles["bottom"]}>
          <WButton
            variant="contained"
            className={`${buttons["button"]} ${buttons["danger"]}`}
            onClick={(event) => {
              event.stopPropagation();
              onDelete();
            }}
          >
            Delete
          </WButton>
          <WButton
            variant="contained"
            className={`${buttons["button"]} ${buttons["primary"]}`}
            onClick={(event) => {
              event.stopPropagation();
              onClose();
            }}
          >
            Close
          </WButton>
        </div>
      )}
    </div>
  );
};

export default FormCard;
