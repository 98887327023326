// @ts-nocheck
import * as Bucket from '@spica-devkit/bucket';
  /**
   * Call this method before interacting with buckets.
   * @param initOptions Initialize options to initialize the '@spica-devkit/bucket'.
   */
  export function initialize(
    ...initOptions: Parameters<typeof Bucket.initialize>
  ) {
    initOptions[0].publicUrl = 'https://composer-0b0f0.hq.spicaengine.com/api';
    Bucket.initialize(...initOptions);
  }

type Rest<T extends any[]> = ((...p: T) => void) extends ((p1: infer P1, ...rest: infer R) => void) ? R : never;
type getArgs = Rest<Parameters<typeof Bucket.data.get>>;
type getAllArgs = Rest<Parameters<typeof Bucket.data.getAll>>;
type realtimeGetArgs = Rest<Parameters<typeof Bucket.data.realtime.get>>;
type realtimeGetAllArgs = Rest<Parameters<typeof Bucket.data.realtime.getAll>>;
type id = { _id: string };

export interface Active_Users{
  _id?: string;
  ip?: string;
  platform?: string;
  browser_language?: string;
  project?: (Projects & id | string);
  created_at?: Date | string;
}
export namespace active_users {
  const BUCKET_ID = '646388b7fba070002b73ec01';
      export function get (...args: getArgs) {
        return Bucket.data.get<Active_Users & id>(BUCKET_ID, ...args);
      };
      export function getAll (...args: getAllArgs) {
        return Bucket.data.getAll<Active_Users & id>(BUCKET_ID, ...args);
      };
      export function insert (document: Omit<Active_Users, "_id">) {
        ['project'].forEach((field) => {
        if (typeof document[field] == 'object') {
          document[field] = Array.isArray(document[field])
            ? document[field].map((v) => v._id || v)
            : document[field]._id;
        }
      });
        return Bucket.data.insert(BUCKET_ID, document);
      };
      export function update (document: Active_Users & id) {
        ['project'].forEach((field) => {
        if (typeof document[field] == 'object') {
          document[field] = Array.isArray(document[field])
            ? document[field].map((v) => v._id || v)
            : document[field]._id;
        }
      });
        return Bucket.data.update(
          BUCKET_ID,
          document._id,
          document
        );
      };  
      export function patch (
        document: Partial<Active_Users> & id
      ) {
        ['project'].forEach((field) => {
        if (typeof document[field] == 'object') {
          document[field] = Array.isArray(document[field])
            ? document[field].map((v) => v._id || v)
            : document[field]._id;
        }
      });
        return Bucket.data.patch(BUCKET_ID, document._id, document);
      };  
      export function remove (documentId: string) {
        return Bucket.data.remove(BUCKET_ID, documentId);
      };
  export namespace realtime {
        export function get (...args: realtimeGetArgs) {
          return Bucket.data.realtime.get<Active_Users & id>(BUCKET_ID, ...args);
        };
        export function getAll (...args: realtimeGetAllArgs) {
          return Bucket.data.realtime.getAll<Active_Users & id>(BUCKET_ID, ...args);
        };
  }
}

export interface Analytics{
  _id?: string;
  title?: string;
  description?: string;
  google_analytics?: string;
  google_tag_manager_id?: string;
  facebook_pixel?: string;
}
export namespace analytics {
  const BUCKET_ID = '645b52caf72de2002cab2132';
      export function get (...args: getArgs) {
        return Bucket.data.get<Analytics & id>(BUCKET_ID, ...args);
      };
      export function getAll (...args: getAllArgs) {
        return Bucket.data.getAll<Analytics & id>(BUCKET_ID, ...args);
      };
      export function insert (document: Omit<Analytics, "_id">) {
        
        return Bucket.data.insert(BUCKET_ID, document);
      };
      export function update (document: Analytics & id) {
        
        return Bucket.data.update(
          BUCKET_ID,
          document._id,
          document
        );
      };  
      export function patch (
        document: Partial<Analytics> & id
      ) {
        
        return Bucket.data.patch(BUCKET_ID, document._id, document);
      };  
      export function remove (documentId: string) {
        return Bucket.data.remove(BUCKET_ID, documentId);
      };
  export namespace realtime {
        export function get (...args: realtimeGetArgs) {
          return Bucket.data.realtime.get<Analytics & id>(BUCKET_ID, ...args);
        };
        export function getAll (...args: realtimeGetAllArgs) {
          return Bucket.data.realtime.getAll<Analytics & id>(BUCKET_ID, ...args);
        };
  }
}

export interface User{
  _id?: string;
  name?: string;
  identifier?: string;
  payment_type?: ('stripe'|'paypal');
  stripe_customer_id?: string;
  email?: string;
}
export namespace user {
  const BUCKET_ID = '63ac7c8e9b0b86002b374178';
      export function get (...args: getArgs) {
        return Bucket.data.get<User & id>(BUCKET_ID, ...args);
      };
      export function getAll (...args: getAllArgs) {
        return Bucket.data.getAll<User & id>(BUCKET_ID, ...args);
      };
      export function insert (document: Omit<User, "_id">) {
        
        return Bucket.data.insert(BUCKET_ID, document);
      };
      export function update (document: User & id) {
        
        return Bucket.data.update(
          BUCKET_ID,
          document._id,
          document
        );
      };  
      export function patch (
        document: Partial<User> & id
      ) {
        
        return Bucket.data.patch(BUCKET_ID, document._id, document);
      };  
      export function remove (documentId: string) {
        return Bucket.data.remove(BUCKET_ID, documentId);
      };
  export namespace realtime {
        export function get (...args: realtimeGetArgs) {
          return Bucket.data.realtime.get<User & id>(BUCKET_ID, ...args);
        };
        export function getAll (...args: realtimeGetAllArgs) {
          return Bucket.data.realtime.getAll<User & id>(BUCKET_ID, ...args);
        };
  }
}

export interface Pages{
  _id?: string;
  name?: string;
  slug?: string;
  user?: (User & id | string);
  page_json?: string;
  meta_tags?: {
  title?: string;
  description?: string;
  keywords?: string;
  custom?: string[];};
  custom_script?: string;
  localization?: {
  language?: string;
  json?: string;}[];
}
export namespace pages {
  const BUCKET_ID = '63ac7cda9b0b86002b37417c';
      export function get (...args: getArgs) {
        return Bucket.data.get<Pages & id>(BUCKET_ID, ...args);
      };
      export function getAll (...args: getAllArgs) {
        return Bucket.data.getAll<Pages & id>(BUCKET_ID, ...args);
      };
      export function insert (document: Omit<Pages, "_id">) {
        ['user'].forEach((field) => {
        if (typeof document[field] == 'object') {
          document[field] = Array.isArray(document[field])
            ? document[field].map((v) => v._id || v)
            : document[field]._id;
        }
      });
        return Bucket.data.insert(BUCKET_ID, document);
      };
      export function update (document: Pages & id) {
        ['user'].forEach((field) => {
        if (typeof document[field] == 'object') {
          document[field] = Array.isArray(document[field])
            ? document[field].map((v) => v._id || v)
            : document[field]._id;
        }
      });
        return Bucket.data.update(
          BUCKET_ID,
          document._id,
          document
        );
      };  
      export function patch (
        document: Partial<Pages> & id
      ) {
        ['user'].forEach((field) => {
        if (typeof document[field] == 'object') {
          document[field] = Array.isArray(document[field])
            ? document[field].map((v) => v._id || v)
            : document[field]._id;
        }
      });
        return Bucket.data.patch(BUCKET_ID, document._id, document);
      };  
      export function remove (documentId: string) {
        return Bucket.data.remove(BUCKET_ID, documentId);
      };
  export namespace realtime {
        export function get (...args: realtimeGetArgs) {
          return Bucket.data.realtime.get<Pages & id>(BUCKET_ID, ...args);
        };
        export function getAll (...args: realtimeGetAllArgs) {
          return Bucket.data.realtime.getAll<Pages & id>(BUCKET_ID, ...args);
        };
  }
}

export interface Projects{
  _id?: string;
  name?: string;
  owner?: (User & id | string);
  pages?: (Pages & id | string)[];
  theme_config?: {
  colors?: {
  primary?: string;
  secondary?: string;
  tertiary?: string;};
  fonts?: {
  family?: string;};
  environments?: {
  html_background?: string;
  box_shadow?: {
  horizontal_length?: number;
  vertical_length?: number;
  blur_radius?: number;
  spread_radius?: number;
  opacity?: number;
  color?: string;};
  content_width?: {
  width?: number;
  full_width?: boolean;};
  border_radius?: number;};};
  custom_script?: string;
  meta_tags?: {
  author?: string;
  og_image?: string;};
  domains?: (Domain & id | string)[];
  favicon?: string;
  current_language?: string;
  languages?: string[];
  description?: string;
  industry?: string;
  features?: string[];
  modals?: (Modals & id | string)[];
  css_classes?: (CSS_Classes & id | string)[];
  port?: number;
  status?: ('active'|'terminated');
  autogeneration?: boolean;
  tracking_id?: {
  google_analytics?: string;
  google_tag_manager?: string;
  facebook_pixel?: string;};
}
export namespace projects {
  const BUCKET_ID = '63b2c3529b0b86002b37507e';
      export function get (...args: getArgs) {
        return Bucket.data.get<Projects & id>(BUCKET_ID, ...args);
      };
      export function getAll (...args: getAllArgs) {
        return Bucket.data.getAll<Projects & id>(BUCKET_ID, ...args);
      };
      export function insert (document: Omit<Projects, "_id">) {
        ['owner','pages','domains','modals','css_classes'].forEach((field) => {
        if (typeof document[field] == 'object') {
          document[field] = Array.isArray(document[field])
            ? document[field].map((v) => v._id || v)
            : document[field]._id;
        }
      });
        return Bucket.data.insert(BUCKET_ID, document);
      };
      export function update (document: Projects & id) {
        ['owner','pages','domains','modals','css_classes'].forEach((field) => {
        if (typeof document[field] == 'object') {
          document[field] = Array.isArray(document[field])
            ? document[field].map((v) => v._id || v)
            : document[field]._id;
        }
      });
        return Bucket.data.update(
          BUCKET_ID,
          document._id,
          document
        );
      };  
      export function patch (
        document: Partial<Projects> & id
      ) {
        ['owner','pages','domains','modals','css_classes'].forEach((field) => {
        if (typeof document[field] == 'object') {
          document[field] = Array.isArray(document[field])
            ? document[field].map((v) => v._id || v)
            : document[field]._id;
        }
      });
        return Bucket.data.patch(BUCKET_ID, document._id, document);
      };  
      export function remove (documentId: string) {
        return Bucket.data.remove(BUCKET_ID, documentId);
      };
  export namespace realtime {
        export function get (...args: realtimeGetArgs) {
          return Bucket.data.realtime.get<Projects & id>(BUCKET_ID, ...args);
        };
        export function getAll (...args: realtimeGetAllArgs) {
          return Bucket.data.realtime.getAll<Projects & id>(BUCKET_ID, ...args);
        };
  }
}

export interface Published_Projects{
  _id?: string;
  data?: string;
  project?: (Projects & id | string);
  domains?: (Domain & id | string)[];
  port?: number;
}
export namespace published_projects {
  const BUCKET_ID = '63d919c1e682de002c1feada';
      export function get (...args: getArgs) {
        return Bucket.data.get<Published_Projects & id>(BUCKET_ID, ...args);
      };
      export function getAll (...args: getAllArgs) {
        return Bucket.data.getAll<Published_Projects & id>(BUCKET_ID, ...args);
      };
      export function insert (document: Omit<Published_Projects, "_id">) {
        ['project','domains'].forEach((field) => {
        if (typeof document[field] == 'object') {
          document[field] = Array.isArray(document[field])
            ? document[field].map((v) => v._id || v)
            : document[field]._id;
        }
      });
        return Bucket.data.insert(BUCKET_ID, document);
      };
      export function update (document: Published_Projects & id) {
        ['project','domains'].forEach((field) => {
        if (typeof document[field] == 'object') {
          document[field] = Array.isArray(document[field])
            ? document[field].map((v) => v._id || v)
            : document[field]._id;
        }
      });
        return Bucket.data.update(
          BUCKET_ID,
          document._id,
          document
        );
      };  
      export function patch (
        document: Partial<Published_Projects> & id
      ) {
        ['project','domains'].forEach((field) => {
        if (typeof document[field] == 'object') {
          document[field] = Array.isArray(document[field])
            ? document[field].map((v) => v._id || v)
            : document[field]._id;
        }
      });
        return Bucket.data.patch(BUCKET_ID, document._id, document);
      };  
      export function remove (documentId: string) {
        return Bucket.data.remove(BUCKET_ID, documentId);
      };
  export namespace realtime {
        export function get (...args: realtimeGetArgs) {
          return Bucket.data.realtime.get<Published_Projects & id>(BUCKET_ID, ...args);
        };
        export function getAll (...args: realtimeGetAllArgs) {
          return Bucket.data.realtime.getAll<Published_Projects & id>(BUCKET_ID, ...args);
        };
  }
}

export interface Mailer___Templates{
  _id?: string;
  title?: string;
  description?: string;
  subject?: string;
  template?: string;
  content?: string;
}
export namespace mailer___templates {
  const BUCKET_ID = '63cfb883e682de002c1fa689';
      export function get (...args: getArgs) {
        return Bucket.data.get<Mailer___Templates & id>(BUCKET_ID, ...args);
      };
      export function getAll (...args: getAllArgs) {
        return Bucket.data.getAll<Mailer___Templates & id>(BUCKET_ID, ...args);
      };
      export function insert (document: Omit<Mailer___Templates, "_id">) {
        
        return Bucket.data.insert(BUCKET_ID, document);
      };
      export function update (document: Mailer___Templates & id) {
        
        return Bucket.data.update(
          BUCKET_ID,
          document._id,
          document
        );
      };  
      export function patch (
        document: Partial<Mailer___Templates> & id
      ) {
        
        return Bucket.data.patch(BUCKET_ID, document._id, document);
      };  
      export function remove (documentId: string) {
        return Bucket.data.remove(BUCKET_ID, documentId);
      };
  export namespace realtime {
        export function get (...args: realtimeGetArgs) {
          return Bucket.data.realtime.get<Mailer___Templates & id>(BUCKET_ID, ...args);
        };
        export function getAll (...args: realtimeGetAllArgs) {
          return Bucket.data.realtime.getAll<Mailer___Templates & id>(BUCKET_ID, ...args);
        };
  }
}

export interface Mailer___Requests{
  _id?: string;
  title?: string;
  emails?: string[];
  template?: string;
  variables?: string;
  created_at?: Date | string;
}
export namespace mailer___requests {
  const BUCKET_ID = '63cfb8c4e682de002c1fa691';
      export function get (...args: getArgs) {
        return Bucket.data.get<Mailer___Requests & id>(BUCKET_ID, ...args);
      };
      export function getAll (...args: getAllArgs) {
        return Bucket.data.getAll<Mailer___Requests & id>(BUCKET_ID, ...args);
      };
      export function insert (document: Omit<Mailer___Requests, "_id">) {
        
        return Bucket.data.insert(BUCKET_ID, document);
      };
      export function update (document: Mailer___Requests & id) {
        
        return Bucket.data.update(
          BUCKET_ID,
          document._id,
          document
        );
      };  
      export function patch (
        document: Partial<Mailer___Requests> & id
      ) {
        
        return Bucket.data.patch(BUCKET_ID, document._id, document);
      };  
      export function remove (documentId: string) {
        return Bucket.data.remove(BUCKET_ID, documentId);
      };
  export namespace realtime {
        export function get (...args: realtimeGetArgs) {
          return Bucket.data.realtime.get<Mailer___Requests & id>(BUCKET_ID, ...args);
        };
        export function getAll (...args: realtimeGetAllArgs) {
          return Bucket.data.realtime.getAll<Mailer___Requests & id>(BUCKET_ID, ...args);
        };
  }
}

export interface Plan{
  _id?: string;
  name?: string;
  slug?: string;
  price?: number;
  stripe_plan?: string;
}
export namespace plan {
  const BUCKET_ID = '63d920e7e682de002c1fec3c';
      export function get (...args: getArgs) {
        return Bucket.data.get<Plan & id>(BUCKET_ID, ...args);
      };
      export function getAll (...args: getAllArgs) {
        return Bucket.data.getAll<Plan & id>(BUCKET_ID, ...args);
      };
      export function insert (document: Omit<Plan, "_id">) {
        
        return Bucket.data.insert(BUCKET_ID, document);
      };
      export function update (document: Plan & id) {
        
        return Bucket.data.update(
          BUCKET_ID,
          document._id,
          document
        );
      };  
      export function patch (
        document: Partial<Plan> & id
      ) {
        
        return Bucket.data.patch(BUCKET_ID, document._id, document);
      };  
      export function remove (documentId: string) {
        return Bucket.data.remove(BUCKET_ID, documentId);
      };
  export namespace realtime {
        export function get (...args: realtimeGetArgs) {
          return Bucket.data.realtime.get<Plan & id>(BUCKET_ID, ...args);
        };
        export function getAll (...args: realtimeGetAllArgs) {
          return Bucket.data.realtime.getAll<Plan & id>(BUCKET_ID, ...args);
        };
  }
}

export interface Password_Recovery_Log{
  _id?: string;
  email?: string;
  uuid?: string;
  created_at?: Date | string;
  used?: boolean;
}
export namespace password_recovery_log {
  const BUCKET_ID = '63cfbdbde682de002c1fa8e5';
      export function get (...args: getArgs) {
        return Bucket.data.get<Password_Recovery_Log & id>(BUCKET_ID, ...args);
      };
      export function getAll (...args: getAllArgs) {
        return Bucket.data.getAll<Password_Recovery_Log & id>(BUCKET_ID, ...args);
      };
      export function insert (document: Omit<Password_Recovery_Log, "_id">) {
        
        return Bucket.data.insert(BUCKET_ID, document);
      };
      export function update (document: Password_Recovery_Log & id) {
        
        return Bucket.data.update(
          BUCKET_ID,
          document._id,
          document
        );
      };  
      export function patch (
        document: Partial<Password_Recovery_Log> & id
      ) {
        
        return Bucket.data.patch(BUCKET_ID, document._id, document);
      };  
      export function remove (documentId: string) {
        return Bucket.data.remove(BUCKET_ID, documentId);
      };
  export namespace realtime {
        export function get (...args: realtimeGetArgs) {
          return Bucket.data.realtime.get<Password_Recovery_Log & id>(BUCKET_ID, ...args);
        };
        export function getAll (...args: realtimeGetAllArgs) {
          return Bucket.data.realtime.getAll<Password_Recovery_Log & id>(BUCKET_ID, ...args);
        };
  }
}

export interface Subscription{
  _id?: string;
  project?: (Projects & id | string);
  subscription_id?: string;
  status?: ('active'|'deleted'|'denied');
  created_at?: Date | string;
  updated_at?: Date | string;
}
export namespace subscription {
  const BUCKET_ID = '63d93269e682de002c1fef2c';
      export function get (...args: getArgs) {
        return Bucket.data.get<Subscription & id>(BUCKET_ID, ...args);
      };
      export function getAll (...args: getAllArgs) {
        return Bucket.data.getAll<Subscription & id>(BUCKET_ID, ...args);
      };
      export function insert (document: Omit<Subscription, "_id">) {
        ['project'].forEach((field) => {
        if (typeof document[field] == 'object') {
          document[field] = Array.isArray(document[field])
            ? document[field].map((v) => v._id || v)
            : document[field]._id;
        }
      });
        return Bucket.data.insert(BUCKET_ID, document);
      };
      export function update (document: Subscription & id) {
        ['project'].forEach((field) => {
        if (typeof document[field] == 'object') {
          document[field] = Array.isArray(document[field])
            ? document[field].map((v) => v._id || v)
            : document[field]._id;
        }
      });
        return Bucket.data.update(
          BUCKET_ID,
          document._id,
          document
        );
      };  
      export function patch (
        document: Partial<Subscription> & id
      ) {
        ['project'].forEach((field) => {
        if (typeof document[field] == 'object') {
          document[field] = Array.isArray(document[field])
            ? document[field].map((v) => v._id || v)
            : document[field]._id;
        }
      });
        return Bucket.data.patch(BUCKET_ID, document._id, document);
      };  
      export function remove (documentId: string) {
        return Bucket.data.remove(BUCKET_ID, documentId);
      };
  export namespace realtime {
        export function get (...args: realtimeGetArgs) {
          return Bucket.data.realtime.get<Subscription & id>(BUCKET_ID, ...args);
        };
        export function getAll (...args: realtimeGetAllArgs) {
          return Bucket.data.realtime.getAll<Subscription & id>(BUCKET_ID, ...args);
        };
  }
}

export interface Domain{
  _id?: string;
  used: string;
  redirect?: (Domain & id | string);
  verified?: boolean;
  config?: string;
  created_at?: Date | string;
  updated_at?: Date | string;
}
export namespace domain {
  const BUCKET_ID = '63da5360e682de002c200023';
      export function get (...args: getArgs) {
        return Bucket.data.get<Domain & id>(BUCKET_ID, ...args);
      };
      export function getAll (...args: getAllArgs) {
        return Bucket.data.getAll<Domain & id>(BUCKET_ID, ...args);
      };
      export function insert (document: Omit<Domain, "_id">) {
        ['redirect'].forEach((field) => {
        if (typeof document[field] == 'object') {
          document[field] = Array.isArray(document[field])
            ? document[field].map((v) => v._id || v)
            : document[field]._id;
        }
      });
        return Bucket.data.insert(BUCKET_ID, document);
      };
      export function update (document: Domain & id) {
        ['redirect'].forEach((field) => {
        if (typeof document[field] == 'object') {
          document[field] = Array.isArray(document[field])
            ? document[field].map((v) => v._id || v)
            : document[field]._id;
        }
      });
        return Bucket.data.update(
          BUCKET_ID,
          document._id,
          document
        );
      };  
      export function patch (
        document: Partial<Domain> & id
      ) {
        ['redirect'].forEach((field) => {
        if (typeof document[field] == 'object') {
          document[field] = Array.isArray(document[field])
            ? document[field].map((v) => v._id || v)
            : document[field]._id;
        }
      });
        return Bucket.data.patch(BUCKET_ID, document._id, document);
      };  
      export function remove (documentId: string) {
        return Bucket.data.remove(BUCKET_ID, documentId);
      };
  export namespace realtime {
        export function get (...args: realtimeGetArgs) {
          return Bucket.data.realtime.get<Domain & id>(BUCKET_ID, ...args);
        };
        export function getAll (...args: realtimeGetAllArgs) {
          return Bucket.data.realtime.getAll<Domain & id>(BUCKET_ID, ...args);
        };
  }
}

export interface Forms{
  _id?: string;
  created_at?: Date | string;
  name?: string;
  project?: (Projects & id | string);
  data?: string;
  seen?: boolean;
}
export namespace forms {
  const BUCKET_ID = '63f33c80e682de002c2083f0';
      export function get (...args: getArgs) {
        return Bucket.data.get<Forms & id>(BUCKET_ID, ...args);
      };
      export function getAll (...args: getAllArgs) {
        return Bucket.data.getAll<Forms & id>(BUCKET_ID, ...args);
      };
      export function insert (document: Omit<Forms, "_id">) {
        ['project'].forEach((field) => {
        if (typeof document[field] == 'object') {
          document[field] = Array.isArray(document[field])
            ? document[field].map((v) => v._id || v)
            : document[field]._id;
        }
      });
        return Bucket.data.insert(BUCKET_ID, document);
      };
      export function update (document: Forms & id) {
        ['project'].forEach((field) => {
        if (typeof document[field] == 'object') {
          document[field] = Array.isArray(document[field])
            ? document[field].map((v) => v._id || v)
            : document[field]._id;
        }
      });
        return Bucket.data.update(
          BUCKET_ID,
          document._id,
          document
        );
      };  
      export function patch (
        document: Partial<Forms> & id
      ) {
        ['project'].forEach((field) => {
        if (typeof document[field] == 'object') {
          document[field] = Array.isArray(document[field])
            ? document[field].map((v) => v._id || v)
            : document[field]._id;
        }
      });
        return Bucket.data.patch(BUCKET_ID, document._id, document);
      };  
      export function remove (documentId: string) {
        return Bucket.data.remove(BUCKET_ID, documentId);
      };
  export namespace realtime {
        export function get (...args: realtimeGetArgs) {
          return Bucket.data.realtime.get<Forms & id>(BUCKET_ID, ...args);
        };
        export function getAll (...args: realtimeGetAllArgs) {
          return Bucket.data.realtime.getAll<Forms & id>(BUCKET_ID, ...args);
        };
  }
}

export interface Modals{
  _id?: string;
  name?: string;
  user?: (User & id | string);
  localization?: {
  language?: string;
  modal_json?: string;}[];
}
export namespace modals {
  const BUCKET_ID = '64106f318eff18002ca2081a';
      export function get (...args: getArgs) {
        return Bucket.data.get<Modals & id>(BUCKET_ID, ...args);
      };
      export function getAll (...args: getAllArgs) {
        return Bucket.data.getAll<Modals & id>(BUCKET_ID, ...args);
      };
      export function insert (document: Omit<Modals, "_id">) {
        ['user'].forEach((field) => {
        if (typeof document[field] == 'object') {
          document[field] = Array.isArray(document[field])
            ? document[field].map((v) => v._id || v)
            : document[field]._id;
        }
      });
        return Bucket.data.insert(BUCKET_ID, document);
      };
      export function update (document: Modals & id) {
        ['user'].forEach((field) => {
        if (typeof document[field] == 'object') {
          document[field] = Array.isArray(document[field])
            ? document[field].map((v) => v._id || v)
            : document[field]._id;
        }
      });
        return Bucket.data.update(
          BUCKET_ID,
          document._id,
          document
        );
      };  
      export function patch (
        document: Partial<Modals> & id
      ) {
        ['user'].forEach((field) => {
        if (typeof document[field] == 'object') {
          document[field] = Array.isArray(document[field])
            ? document[field].map((v) => v._id || v)
            : document[field]._id;
        }
      });
        return Bucket.data.patch(BUCKET_ID, document._id, document);
      };  
      export function remove (documentId: string) {
        return Bucket.data.remove(BUCKET_ID, documentId);
      };
  export namespace realtime {
        export function get (...args: realtimeGetArgs) {
          return Bucket.data.realtime.get<Modals & id>(BUCKET_ID, ...args);
        };
        export function getAll (...args: realtimeGetAllArgs) {
          return Bucket.data.realtime.getAll<Modals & id>(BUCKET_ID, ...args);
        };
  }
}

export interface CSS_Classes{
  _id?: string;
  class_name?: string;
  css?: string;
  user?: (User & id | string);
  project?: (Projects & id | string);
  system?: boolean;
}
export namespace css_classes {
  const BUCKET_ID = '6411b5028eff18002ca213bd';
      export function get (...args: getArgs) {
        return Bucket.data.get<CSS_Classes & id>(BUCKET_ID, ...args);
      };
      export function getAll (...args: getAllArgs) {
        return Bucket.data.getAll<CSS_Classes & id>(BUCKET_ID, ...args);
      };
      export function insert (document: Omit<CSS_Classes, "_id">) {
        ['user','project'].forEach((field) => {
        if (typeof document[field] == 'object') {
          document[field] = Array.isArray(document[field])
            ? document[field].map((v) => v._id || v)
            : document[field]._id;
        }
      });
        return Bucket.data.insert(BUCKET_ID, document);
      };
      export function update (document: CSS_Classes & id) {
        ['user','project'].forEach((field) => {
        if (typeof document[field] == 'object') {
          document[field] = Array.isArray(document[field])
            ? document[field].map((v) => v._id || v)
            : document[field]._id;
        }
      });
        return Bucket.data.update(
          BUCKET_ID,
          document._id,
          document
        );
      };  
      export function patch (
        document: Partial<CSS_Classes> & id
      ) {
        ['user','project'].forEach((field) => {
        if (typeof document[field] == 'object') {
          document[field] = Array.isArray(document[field])
            ? document[field].map((v) => v._id || v)
            : document[field]._id;
        }
      });
        return Bucket.data.patch(BUCKET_ID, document._id, document);
      };  
      export function remove (documentId: string) {
        return Bucket.data.remove(BUCKET_ID, documentId);
      };
  export namespace realtime {
        export function get (...args: realtimeGetArgs) {
          return Bucket.data.realtime.get<CSS_Classes & id>(BUCKET_ID, ...args);
        };
        export function getAll (...args: realtimeGetAllArgs) {
          return Bucket.data.realtime.getAll<CSS_Classes & id>(BUCKET_ID, ...args);
        };
  }
}

export interface AICompletions{
  _id?: string;
  key?: string;
  completion?: string;
  category?: ('BaseForm'|'BaseNavigator'|'Testimonials'|'BaseCard'|'BaseList'|'BaseHeader'|'BasePricingTable'|'BaseFooter'|'Team'|'BaseContent'|'BaseBoxes'|'BaseDownload')[];
  jgv?: string;
}
export namespace aicompletions {
  const BUCKET_ID = '641d9df98eff18002ca2893a';
      export function get (...args: getArgs) {
        return Bucket.data.get<AICompletions & id>(BUCKET_ID, ...args);
      };
      export function getAll (...args: getAllArgs) {
        return Bucket.data.getAll<AICompletions & id>(BUCKET_ID, ...args);
      };
      export function insert (document: Omit<AICompletions, "_id">) {
        
        return Bucket.data.insert(BUCKET_ID, document);
      };
      export function update (document: AICompletions & id) {
        
        return Bucket.data.update(
          BUCKET_ID,
          document._id,
          document
        );
      };  
      export function patch (
        document: Partial<AICompletions> & id
      ) {
        
        return Bucket.data.patch(BUCKET_ID, document._id, document);
      };  
      export function remove (documentId: string) {
        return Bucket.data.remove(BUCKET_ID, documentId);
      };
  export namespace realtime {
        export function get (...args: realtimeGetArgs) {
          return Bucket.data.realtime.get<AICompletions & id>(BUCKET_ID, ...args);
        };
        export function getAll (...args: realtimeGetAllArgs) {
          return Bucket.data.realtime.getAll<AICompletions & id>(BUCKET_ID, ...args);
        };
  }
}

export interface Webhooks{
  _id?: string;
  url?: string;
  project?: (Projects & id | string);
  is_active?: boolean;
  action?: ('form_submit');
  target?: ('zapier');
}
export namespace webhooks {
  const BUCKET_ID = '6464de2efba070002b740c01';
      export function get (...args: getArgs) {
        return Bucket.data.get<Webhooks & id>(BUCKET_ID, ...args);
      };
      export function getAll (...args: getAllArgs) {
        return Bucket.data.getAll<Webhooks & id>(BUCKET_ID, ...args);
      };
      export function insert (document: Omit<Webhooks, "_id">) {
        ['project'].forEach((field) => {
        if (typeof document[field] == 'object') {
          document[field] = Array.isArray(document[field])
            ? document[field].map((v) => v._id || v)
            : document[field]._id;
        }
      });
        return Bucket.data.insert(BUCKET_ID, document);
      };
      export function update (document: Webhooks & id) {
        ['project'].forEach((field) => {
        if (typeof document[field] == 'object') {
          document[field] = Array.isArray(document[field])
            ? document[field].map((v) => v._id || v)
            : document[field]._id;
        }
      });
        return Bucket.data.update(
          BUCKET_ID,
          document._id,
          document
        );
      };  
      export function patch (
        document: Partial<Webhooks> & id
      ) {
        ['project'].forEach((field) => {
        if (typeof document[field] == 'object') {
          document[field] = Array.isArray(document[field])
            ? document[field].map((v) => v._id || v)
            : document[field]._id;
        }
      });
        return Bucket.data.patch(BUCKET_ID, document._id, document);
      };  
      export function remove (documentId: string) {
        return Bucket.data.remove(BUCKET_ID, documentId);
      };
  export namespace realtime {
        export function get (...args: realtimeGetArgs) {
          return Bucket.data.realtime.get<Webhooks & id>(BUCKET_ID, ...args);
        };
        export function getAll (...args: realtimeGetAllArgs) {
          return Bucket.data.realtime.getAll<Webhooks & id>(BUCKET_ID, ...args);
        };
  }
}