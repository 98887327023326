import * as React from "react";
import styles from "./modal-picker.module.scss";
import Icon from "@mui/material/Icon";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { useEffect, useState } from "react";
import { editor, eventEmitter } from "../../pages/project/editor/editor";
import { iComponent } from "../../composer-tools/editor-components/EditorComponent";
import {
  WIconButton,
  WTextField,
} from "../../components/wrappers/wrapper";

export interface iModalPicker {
  addState: boolean;
  components?: iComponent[];
  setAddState: (value: boolean) => void;
  pickedComponent?: (index?: number) => void;
  pickedNewComponent?: (component?: iComponent) => void;
  enablActions?: boolean;
}
function ModalPicker({
  addState,
  setAddState,
  components,
  pickedComponent,
  pickedNewComponent,
  enablActions = false,
}: iModalPicker) {
  const [search, setSearch] = useState("");
  const [rerender, setRerender] = useState(0);
  const [modalAddState, setModalAddState] = useState<boolean>(addState);
  const [showAvailableComponents, setShowAvailableComponents] =
    useState<boolean>(false);
  const [displayedComponents, setDisplayedComponents] = useState(
    editor.getAvailableComponents().modal
  );

  useEffect(() => {
    setModalAddState(addState);
  }, [addState]);

  useEffect(() => {
    if (!search.length) {
      setDisplayedComponents(editor.getAvailableComponents().modal);
    }
    const filteredComponents = editor
      .getAvailableComponents()
      .modal.filter((modal) =>
        modal.getName().toLowerCase().includes(search.toLowerCase())
      );

    setDisplayedComponents(filteredComponents);
  }, [search]);

  const changeModalState = (value: boolean) => {
    setModalAddState(value);
    setAddState(value);
  };

  return (
    <div
      className={`${styles["container"]} ${
        showAvailableComponents && styles["show-available-components"]
      }`}
      onClick={() => setShowAvailableComponents(false)}
    >
      <div className={styles["heading"]}>
        <div>
          {modalAddState && (
            <ArrowBackIcon
              onClick={() => changeModalState(false)}
              className={styles["icon"]}
            />
          )}
          <span>{modalAddState ? 'Add Modal' : 'Modals'}</span>
        </div>
        {!modalAddState && (
          <AddIcon
            onClick={() => changeModalState(true)}
            className={styles["icon"]}
          />
        )}
      </div>

      <div className={styles["modals"]}>
        {!modalAddState &&
          components.map((component: iComponent, index) => (
            <div
              key={index}
              className={styles["modal"]}
              onClick={() => {
                pickedComponent && pickedComponent(index);
              }}
            >
              <div className={styles["preview"]}>
                <div>{component.render()}</div>
              </div>
              <span>{component.getName()}</span>
              <div className={styles["buttons"]}>
                <WIconButton
                  onClick={() =>
                    eventEmitter.emit("modalComponent", {
                      action: "edit",
                      index,
                    })
                  }
                >
                  <EditIcon />
                </WIconButton>
                <WIconButton
                  color="error"
                  onClick={() => {
                    eventEmitter.emit("modalComponent", {
                      action: "delete",
                      index,
                    });
                    setRerender(rerender + 1);
                  }}
                >
                  <DeleteIcon />
                </WIconButton>
              </div>
            </div>
          ))}

        {modalAddState && (
          <div className={styles["add-view"]}>
            <WTextField
              prefix={(<Icon>search</Icon>) as any}
              size="small"
              placeholder="Search a modal"
              className={styles["search-input"]}
              onChange={(e) => setSearch(e.target.value)}
            ></WTextField>
            {displayedComponents.map((component: iComponent, index) => (
              <div
                onClick={() => pickedNewComponent(component)}
                key={index}
                className={`${styles["modal"]} ${styles["can-picked"]}`}
              >
                <div className={styles["preview"]}>
                  <div>{component.render()}</div>
                </div>
                <span>{component.getName()}</span>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default ModalPicker;
