import * as React from "react";
import InputBoolean from "./inputs/boolean";
import InputImage from "./inputs/image";
import InputNumber from "./inputs/number";
import InputObject from "./inputs/object";
import ObjectArray, { TypeObjectAction as _TypeObjectAction} from "./inputs/object-array";
import InputPage from "./inputs/page";
import InputSelect from "./inputs/select";
import InputString from "./inputs/string";
import styles from "./setting-input.module.scss";

export type TypeObjectAction = _TypeObjectAction;
const inputs: any = {
    "string": (settings: any) => <InputString completion={settings.completion} value={settings.value} label={settings.displayer} onChange={(value: string) => settings.onChange(value)}></InputString>,
    "boolean": (settings: any) => <InputBoolean value={settings.value} label={settings.displayer} onChange={(value: string) => settings.onChange(value)}></InputBoolean>,
    "number": (settings: any) => <InputNumber value={settings.value} label={settings.displayer} onChange={(value: string) => settings.onChange(value)}></InputNumber>,
    "page": (settings: any) => <InputPage value={settings.value} label={settings.displayer} onChange={(value: string) => settings.onChange(value)}></InputPage>,
    "object": (settings: any) => <InputObject value={settings.value} label={settings.displayer} onChange={(value: string) => settings.onChange(value)}></InputObject>,
    "array": (settings: any) => <ObjectArray value={settings.value} label={settings.displayer} onChange={(value: string, action: string) => settings.onChange(value, action)}></ObjectArray>,
    "image": (settings: any) => <InputImage value={settings.value} label={settings.displayer} onChange={(value: string) => settings.onChange(value)}></InputImage>,
    "select": (settings: any) => <InputSelect additionalParams={settings.additionalParams} value={settings.value} label={settings.displayer} onChange={(value: string) => settings.onChange(value)}></InputSelect>,
}
function SettingInput({ propKey, displayer, type, additionalParams, value, completion, onChange }: any) {
    return <div className={styles["container"]}>
        {
            inputs[type]({ additionalParams, value, completion, propKey, displayer, onChange })
        }
    </div>

}
export default SettingInput