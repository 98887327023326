import * as React from "react";
import {
  Elements,
  CardElement,
  AddressElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import {
  loadStripe,
  PaymentMethod as PM,
  PaymentMethodResult,
  Stripe,
  StripeCardElement,
  StripeElements,
} from "@stripe/stripe-js";
import {
  WButton,
  WLoadingButton,
  WStack,
} from "../../components/wrappers/wrapper";
import style from "./payment-method.module.scss";
import { useEffect, useReducer, useState } from "react";
import {
  Content,
  SectionCard,
  Title,
} from "../../components/section-card/section-card";
import {
  CancelOutlined,
  DeleteOutline,
  EditOutlined,
  SaveOutlined,
} from "@mui/icons-material";
import notificationService from "../../classes/NotificationService";
import { UserContext } from "../../contexts/user";
import { buttons } from "../../styles/theme.module";

const stripePromise = loadStripe("pk_test_2OX3ckgUgbG7IlIln1c2lfRw");

type CardState = {
  card: any;
  adress: any;
  valid: boolean;
};

type IPaymentMethod = {
  onSaveCard: (res: PaymentMethodResult) => void;
  onUpdateMethod: (res: PM.BillingDetails) => void;
  handleDeleteMethod: () => void;
  loading: boolean;
};
export const PaymentMethod = ({
  onSaveCard,
  onUpdateMethod,
  handleDeleteMethod,
  loading,
}: IPaymentMethod) => {
  const userContext = React.useContext(UserContext);
  const [showAddPayment, setShowAddPayment] = useState<boolean>(false);

  useEffect(() => {
    if (Object(userContext.paymentMethod).keys) setShowAddPayment(false);
  }, [userContext.paymentMethod]);

  return (
    <WStack mt={3}>
      <SectionCard>
        {!showAddPayment && Object(userContext.paymentMethod).keys ? (
          <WStack
            className={style["card"]}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <WStack direction="row" alignItems="center" spacing={3}>
              <div className={style["brand"]}>
                {userContext.paymentMethod.card?.brand}
              </div>
              <WStack>
                <h4 className={style["username"]}>
                  {userContext.paymentMethod.billing_details?.name}
                </h4>
                <WStack direction="row" spacing={3}>
                  <div>
                    **** **** **** {userContext.paymentMethod.card?.last4}
                  </div>
                  <div>
                    {userContext.paymentMethod.card?.exp_month} /
                    {userContext.paymentMethod.card?.exp_year}
                  </div>
                </WStack>
              </WStack>
            </WStack>
            <WStack spacing={3} direction="row" alignItems="center">
              <WButton
                className={`${buttons["button"]} ${buttons["primary"]}`}
                onClick={() => setShowAddPayment(true)}
                disabled={loading}
              >
                <EditOutlined /> Edit
              </WButton>
              <WLoadingButton
                className={`${buttons["button"]} ${buttons["danger"]}`}
                loading={loading}
                onClick={() => handleDeleteMethod()}
              >
                <DeleteOutline /> Delete
              </WLoadingButton>
            </WStack>
          </WStack>
        ) : showAddPayment ? (
          <Elements stripe={stripePromise}>
            <Title>
              {Object(userContext.paymentMethod).keys
                ? "Update payment method"
                : "Add payment method"}
            </Title>
            <AddMethod
              currentMethod={userContext?.paymentMethod?.billing_details}
              handleClickCancel={() => setShowAddPayment(false)}
              handleClickSubmit={onSaveCard}
              handleUpdateMethod={onUpdateMethod}
              loading={loading}
            ></AddMethod>
          </Elements>
        ) : (
          <WButton className={`${buttons["button"]} ${buttons["primary"]}`} onClick={() => setShowAddPayment(true)}>
            Add payment method
          </WButton>
        )}
      </SectionCard>
    </WStack>
  );
};

// *************** ADD PAYMENT METHOD

type IAddMethod = {
  handleClickCancel: () => void;
  handleClickSubmit: (res: PaymentMethodResult) => void;
  handleUpdateMethod: (res: PM.BillingDetails) => void;
  currentMethod: PM.BillingDetails;
  loading: boolean;
};

export const AddMethod = ({
  handleClickCancel,
  handleClickSubmit,
  handleUpdateMethod,
  currentMethod,
  loading,
}: IAddMethod) => {
  const stripe: Stripe = useStripe();
  const elements: StripeElements = useElements();
  const [cardInfo, setCardInfo] = useState<PM.BillingDetails>();

  const [isValid, setIsValid] = useReducer(
    (state: CardState, newState: Partial<CardState>) => {
      newState.valid =
        (currentMethod ? true : newState.card) && newState.adress;
      return {
        ...state,
        ...newState,
      };
    },
    {
      card: false,
      adress: false,
      valid: false,
    }
  );

  const handleCardChange = (event: any) => {
    const complete: boolean = event.complete;
    setIsValid({ ...isValid, card: complete });
  };

  const handleAdressChange = (event: any) => {
    const complete: boolean = event.complete;
    if (complete) setCardInfo(event.value);
    setIsValid({ ...isValid, adress: complete });
  };

  const handleSubmit = async (stripe: Stripe, elements: StripeElements) => {
    const cardElement: StripeCardElement = elements.getElement(CardElement);
    const result = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
      billing_details: {
        name: cardInfo.name,
        phone: cardInfo.phone,
        address: cardInfo.address,
      },
    });
    if (result.error) {
      notificationService.errorNotification(result.error.message);
      return;
    }
    handleClickSubmit(result);
  };

  const handleUpdateCard = () => {
    handleUpdateMethod({
      name: cardInfo.name,
      phone: cardInfo.phone,
      address: cardInfo.address,
      email: currentMethod.email,
    });
  };

  return (
    <Content>
      <form
        onSubmit={($event) => {
          $event.preventDefault();
        }}
      >
        {!currentMethod ? (
          <>
            <span className={style["card-elem-label"]}> Card Number</span>
            <CardElement
              className={style["card-elem"]}
              onChange={handleCardChange}
            />
          </>
        ) : (
          ""
        )}
        <AddressElement
          onChange={handleAdressChange}
          options={{
            mode: "shipping",
            defaultValues: currentMethod || {},
            fields: {
              phone: "always",
            },
          }}
        />
        <div className={style["actions"]}>
          <WButton
            className={`${buttons["button"]} ${buttons["danger"]}`}
            onClick={handleClickCancel}>
            <CancelOutlined />
            Cancel
          </WButton>
          <WLoadingButton
            className={`${buttons["button"]} ${buttons["success"]}`}
            loading={loading}
            onClick={() =>
              currentMethod
                ? handleUpdateCard()
                : handleSubmit(stripe, elements)
            }
            disabled={!isValid.valid}
          >
            <SaveOutlined />
            Save
          </WLoadingButton>
        </div>
      </form>
    </Content>
  );
};
