import * as React from "react";
import { Length } from "./length";
import styles from "./input.module.scss";
import { useEffect, useState } from "react";

export const MarginPadding = ({ value, onChange }: any) => {
  const initialValue = {
    top: "auto",
    right: "auto",
    bottom: "auto",
    left: "auto",
  };
  const [margin, setMargin] = useState({ ...initialValue });
  const [padding, setPadding] = useState({ ...initialValue });
  const [showOverlay, setShowOverlay] = useState(false);
  const [activeValue, setActiveValue] = useState<string>();
  const [selectedProperty, setSelectedProperty] =
    useState<{ property: string; side: string }>();

  useEffect(() => {
    if (value.margin) {
      const temp: any = {};
      const values = value.margin.split(" ");
      Object.entries(margin).map((el, index) => {
        temp[el[0]] = values[index];
      });
      setMargin({ ...temp });
    }
    if (value.padding) {
      const temp: any = {};
      const values = value.padding.split(" ");
      Object.entries(padding).map((el, index) => {
        temp[el[0]] = values[index];
      });
      setPadding({ ...temp });
    }
  }, [value]);

  useEffect(() => {
    showOverlay && document.addEventListener("click", handleHostClick);

    return () => {
      showOverlay && document.removeEventListener("click", handleHostClick);
    };
  }, [showOverlay]);

  const handleHostClick = () => {
    setShowOverlay(false);
  };

  const handleButtonClick = (event: any, value: any, property: any) => {
    if (value == "auto") value = undefined;
    event.stopPropagation();
    setSelectedProperty({ ...property });
    setShowOverlay(true);
    setActiveValue(value);
  };

  const handleChangeValue = (data: any) => {
    let temp = { ...margin, [selectedProperty.side]: data.value || 'auto' };
    if (selectedProperty.property == "padding") {
      temp = { ...padding, [selectedProperty.side]: data.value || 'auto' };
      setPadding({ ...temp });
    } else {
      setMargin({ ...temp });
    }

    onChange({
      property: selectedProperty.property,
      value: `${temp.top} ${temp.right} ${temp.bottom} ${temp.left}`,
    });
  };

  return (
    <div className={styles["margin-padding-container"]}>
      {Object.entries(margin).map((el, index) => (
        <span
          key={`margin-${index}`}
          onClick={(event) =>
            handleButtonClick(event, el[1], { property: "margin", side: el[0] })
          }
          className={`${styles["margin"]} ${styles[el[0]]}`}
        >
          {el[1]}
        </span>
      ))}
      <div className={styles["inner-container"]}>
        {Object.entries(padding).map((el, index) => (
          <span
            key={`padding-${index}`}
            onClick={(event) =>
              handleButtonClick(event, el[1], { property: "padding", side: el[0] })
            }
            className={`${styles["padding"]} ${styles[el[0]]}`}
          >
            {el[1]}
          </span>
        ))}
      </div>
      {showOverlay && (
        <div className={styles["overlay"]}>
          <div
            onClick={(event: any) => {
              event.stopPropagation();
            }}
          >
            <Length
              property="Value"
              value={activeValue}
              onChange={(data: any) => handleChangeValue(data)}
            />
          </div>
        </div>
      )}
    </div>
  );
};
