import { FunctionService } from "../classes/Function"

class PaymentService {
  private functionService = new FunctionService();
  constructor() { }

  getPaymentMethod = (customer_id: string) => {
    return this.functionService.getPaymentMethod(customer_id)
  }
  addPaymentMethod = (method_id: string) => {
    return this.functionService.addPaymentMethod(method_id)
  }
  updatePaymentMethod = (method_id: string,billing_details:{}) => {
    return this.functionService.updatePaymentMethod(method_id,billing_details)
  }
  deletePaymentMethod = (method_id: string) => {
    return this.functionService.deletePaymentMethod(method_id)
  }
  addDomain = (domains: string[], project_id: string) => {
    return this.functionService.addDomain(domains,project_id)
  }
  verifyDomain = ( domain: string) => {
    return this.functionService.verifyDomain(domain)
  }
  removeDomain = ( domain: string) => {
    return this.functionService.removeDomain(domain)
  }
}
export default new PaymentService()
