import * as React from "react";
import styles from "./analytics.module.scss";
import notificationService from "../../../classes/NotificationService";
import AnalyticLineChart from "../../../components/analytic-line-chart/analytic-line-chart";
import DatePicker from "react-datepicker";

import { editor } from "../editor/editor";
import { Projects } from "../../../classes/bucket";
import { useContext, useEffect, useState } from "react";
import { ProjectContext } from "../../../contexts/project";
import { HelperService } from "../../../services/HelperService";
import { AnalyticsService } from "../../../services/AnalyticsService";
import { AnalyticTrack } from "../../../prefabs/analytic-track/analytic-track";

export const ProjectAnalytics = () => {
  const analyticsService = new AnalyticsService();
  const helperService = new HelperService();

  const projectContext = useContext(ProjectContext);

  const [userAnalytics, setUserAnalytics] = useState<any>();
  const [leadAnalytics, setLeadAnalytics] = useState<any>();
  const [pageViewAnalytics, setPageViewAnalytics] = useState<any>();
  const [referralAnalytics, setReferralAnalytics] = useState<any>();
  const [analyticsTableData, setAnalyticsTableData] = useState<any>([]);

  const defaultDate = new Date().setDate(new Date().getDate() - 7);
  const [userDate, setUserDate] = useState([new Date(defaultDate), new Date()]);
  const [viewDate, setViewDate] = useState([new Date(defaultDate), new Date()]);
  const [leadsDate, setLeadsDate] = useState([
    new Date(defaultDate),
    new Date(),
  ]);
  const [referralDate, setReffererDate] = useState([
    new Date(defaultDate),
    new Date(),
  ]);
  const [tableDate, setTableDate] = useState([
    new Date(defaultDate),
    new Date(),
  ]);
  const [tableStartDate, tableEndDate] = tableDate;

  useEffect(() => {
    getAnalyticsData([new Date(defaultDate), new Date()]).then((res) => {
      if (!res || !Object.keys(res).length) return;
      const data = prepareChartData(res);
      setUserChart(data.labels, data.user);
      setLeadChart(data.labels, data.lead);
      setViewChart(data.labels, data.view);
      setReffereChart(data.labels, res);
    });
    getAnalyticsTableData([new Date(), new Date()]).then((res) => {
      if (res) setAnalyticsTableData(res);
    });
  }, []);

  const getAnalyticsData = async (date: Date[]) => {
    return analyticsService
      .analyticsData(projectContext.project._id, date[0], date[1])
      .catch(console.error);
  };

  const getAnalyticsTableData = async (date: Date[]) => {
    return analyticsService
      .analyticsTableData(projectContext.project._id, date[0], date[1])
      .catch(console.error);
  };

  const chartOptions = {
    borderColor: "rgb(14, 156, 255)",
    backgroundColor: "rgb(14, 156, 255)",
    fill: {
      target: "origin",
      above: "rgba(14, 156, 255, 0.2)",
    },
  };

  const prepareChartData = (res: any) => {
    const entries = Object.entries(res);
    const labels: string[] = [];
    const userData: number[] = [];
    const viewData: number[] = [];
    const leadData: number[] = [];

    entries.forEach((el: any) => {
      labels.push(el[0]);
      userData.push(el[1].user as number);
      viewData.push(el[1].view as number);
      leadData.push(el[1].lead as number);
    });

    return {
      labels,
      user: userData,
      lead: leadData,
      view: viewData,
    };
  };

  const setUserChart = (labels: string[], userData: any) => {
    setUserAnalytics({
      labels,
      datasets: [
        {
          label: "Users",
          data: userData,
          ...chartOptions,
        },
      ],
    });
  };

  const setLeadChart = (labels: string[], leadData: any) => {
    setLeadAnalytics({
      labels,
      datasets: [
        {
          label: "Lead",
          data: leadData,
          ...chartOptions,
        },
      ],
    });
  };

  const setViewChart = (labels: string[], viewData: any) => {
    setPageViewAnalytics({
      labels,
      datasets: [
        {
          label: "Page View",
          data: viewData,
          ...chartOptions,
        },
      ],
    });
  };

  const setReffereChart = (labels: string[], input: any) => {
    const referrers = Object.keys(input[Object.keys(input)[0]]["referrer"]);

    const output = referrers.map((referrer) => {
      const data = Object.keys(input).map(
        (day) => input[day]["referrer"][referrer]
      );
      const randomColor = helperService.getRandomRGBColor();
      return {
        label: referrer,
        data,
        borderColor: randomColor,
        backgroundColor: randomColor,
        fill: {
          target: "origin",
          above: randomColor.replace("rgb", "rgba").replace(")", ", 0.2)"),
        },
      };
    });

    setReferralAnalytics({
      labels,
      datasets: output,
    });
  };

  const dateFormat = (date: Date) => {
    return date.toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    });
  };

  const saveProject = (_project: Projects) => {
    editor
      .saveProject({ ..._project })
      .then((_) => {
        notificationService.succesNotification("Saved successfully");
      })
      .catch((_) => {
        notificationService.errorNotification("Error! Please try again");
      });
  };

  return (
    <div className={styles["seo-container"]}>
      <div className={styles["top"]}>
        <div className={styles["title"]}>
          <div className={styles["heading-content"]}>
            <div className={styles["left"]}>
              <img src="https://picsum.photos/536/354" />
              <AnalyticTrack onSave={(_project) => saveProject(_project)} />
            </div>
          </div>
        </div>
      </div>

      <div className={styles["content"]}>
        {userAnalytics && (
          <AnalyticLineChart
            label="Active Users"
            data={userAnalytics}
            date={[new Date(userDate[0]), new Date(userDate[1])]}
            onDateChanged={(data: any) => {
              setUserDate(data);
              if (data[1]) {
                getAnalyticsData([new Date(data[0]), new Date(data[1])]).then(
                  (res) => {
                    if (!res) return;
                    const data = prepareChartData(res);
                    setUserChart(data.labels, data.user);
                  }
                );
              }
            }}
          />
        )}
        <div className={styles["chart-row"]}>
          {leadAnalytics && (
            <AnalyticLineChart
              label="Leads"
              data={leadAnalytics}
              date={[new Date(leadsDate[0]), new Date(leadsDate[1])]}
              onDateChanged={(data: any) => {
                setLeadsDate(data);
                if (data[1]) {
                  getAnalyticsData([new Date(data[0]), new Date(data[1])]).then(
                    (res) => {
                      if (!res) return;
                      const data = prepareChartData(res);
                      setLeadChart(data.labels, data.lead);
                    }
                  );
                }
              }}
            />
          )}
          {pageViewAnalytics && (
            <AnalyticLineChart
              label="Page Views"
              data={pageViewAnalytics}
              date={[new Date(viewDate[0]), new Date(viewDate[1])]}
              onDateChanged={(data: any) => {
                setViewDate(data);
                if (data[1]) {
                  getAnalyticsData([new Date(data[0]), new Date(data[1])]).then(
                    (res) => {
                      if (!res) return;
                      const data = prepareChartData(res);
                      setViewChart(data.labels, data.view);
                    }
                  );
                }
              }}
            />
          )}
        </div>
        {referralAnalytics && (
          <AnalyticLineChart
            label="Referrals"
            data={referralAnalytics}
            isStacked={true}
            date={[new Date(referralDate[0]), new Date(referralDate[1])]}
            onDateChanged={(data: any) => {
              setReffererDate(data);
              if (data[1]) {
                getAnalyticsData([new Date(data[0]), new Date(data[1])]).then(
                  (res) => {
                    if (!res) return;
                    const data = prepareChartData(res);
                    setReffereChart(data.labels, res);
                  }
                );
              }
            }}
          />
        )}

        {analyticsTableData.length ? (
          <div className={styles["table"]}>
            <div className={styles["datepicker"]}>
            <DatePicker
              selectsRange={true}
              startDate={tableStartDate}
              endDate={tableEndDate}
              onChange={(update) => {
                setTableDate(update);
                if (update[1]) {
                  getAnalyticsTableData([
                    new Date(update[0]),
                    new Date(update[1]),
                  ]).then((res) => {
                    if (res) setAnalyticsTableData(res);
                  });
                }
              }}
              isClearable={true}
              dateFormat="MMMM dd, yyyy"
            />
            </div>
            <div className={`${styles["row"]} ${styles["header"]}`}>
              <div>Date</div>
              <div>PC / Mobile</div>
              <div>Page Views</div>
              <div>Sessions</div>
              <div>Leads</div>
            </div>
            {analyticsTableData.map((item: any, index: number) => {
              return (
                <div key={`item-${index}`} className={`${styles["row"]}`}>
                  <div>{dateFormat(new Date(item.created_at))}</div>
                  <div>-</div>
                  <div>{item.view}</div>
                  <div>{item.session}</div>
                  <div>{item.lead}</div>
                </div>
              );
            })}
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
