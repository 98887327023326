import axios from "axios";
// This statement has been deleted.
;
// This statement has been deleted.
;
// This statement has been deleted.
;
// This statement has been deleted.
;
// This statement has been deleted.
;
/**
 * @typedef AnalyticsData
 * @type {Object}
 * @property {string} authorization - Authorization token. Only identity tokens are allowed!
 * @property {string} project_id - Projetc id
 * @property {Date} date_from - Date from
 * @property {Date} date_until - Date until
 */
/**
 * @typedef RequestAnalyticsData
 * @type {Object}
 * @property {AnalyticsData} data - body of the request
 */
/**
 * @param {RequestAnalyticsData & Object<string,any>} config
 * @returns {Promise<any>}
 */
export function analyticsData(config) {
    config = { ...config, method: "post", url: "https://composer-0b0f0.hq.spicaengine.com/api/fn-execute/analyticsData" };
    axiosWriteValidator(config);
    axiosReadValidator(config);
    return axios.request(config).then(r => r.data);
}
/**
 * @typedef AnalyticsTableData
 * @type {Object}
 * @property {string} authorization - Authorization token. Only identity tokens are allowed!
 * @property {string} project_id - Projetc id
 * @property {Date} date_from - Date from
 * @property {Date} date_until - Date until
 */
/**
 * @typedef RequestAnalyticsTableData
 * @type {Object}
 * @property {AnalyticsTableData} data - body of the request
 */
/**
 * @param {RequestAnalyticsTableData & Object<string,any>} config
 * @returns {Promise<any>}
 */
export function analyticsTableData(config) {
    config = { ...config, method: "post", url: "https://composer-0b0f0.hq.spicaengine.com/api/fn-execute/analyticsTableData" };
    axiosWriteValidator(config);
    axiosReadValidator(config);
    return axios.request(config).then(r => r.data);
}
// This statement has been deleted.
;
// This statement has been deleted.
;
// This statement has been deleted.
;
// This statement has been deleted.
;
// This statement has been deleted.
;
function axiosWriteValidator(config) {
    if (["post", "put", "patch"].includes(config.method) && !config.data) {
        console.warn("Sending empty request body for post, put, patch requests is unusual. If it's not intented, please use config.data or update your spica function.");
    }
}
function axiosReadValidator(config) {
    if (["get", "delete", "trace", "options", "head"].includes(config.method) && config.data) {
        console.warn("Sending request body for get, delete, trace, options, head requests is unusual. If it's not intented, please remove config.data or update your spica function.");
    }
}
