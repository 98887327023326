import * as React from "react";
import { useEffect, useState } from "react";
import { WTextField } from "../../../components/wrappers/wrapper";
import { InputWrapper } from "./input-wrapper";
import styles from "./input.module.scss";
import { Suffix } from "./suffix";

export const Time = ({ property, value, onChange }: any) => {
  const [inputValue, setInputValue] = useState<any>();

  useEffect(() => {
    if (value) {
      value = value.replace("s", "");
      setInputValue(value);
    }
  }, [value]);

  useEffect(() => {
    onChange({ property, value: inputValue ? `${inputValue}s` : "" });
  }, [inputValue]);

  return (
    <InputWrapper property={property}>
      <WTextField
        fullWidth
        value={inputValue}
        size={"small"}
        onChange={(_: any) => {
          setInputValue(_.target.value);
        }}
        className={styles["text-filed"]}
        sx={{
          "&.MuiFormControl-root": {
            ".MuiOutlinedInput-root": {
              padding: 0,
            },
          },
        }}
        suffix={<Suffix>s</Suffix>}
      ></WTextField>
    </InputWrapper>
  );
};
