import * as React from "react";
import styles from "./overlay-popup.module.scss";
import { popups } from "../../styles/theme.module";
import { CloseButton } from "../../components/close-button/close-button";
import { useEffect } from "react";
import { useState } from "react";

export type TypeOverlayProps = {
  title: string;
  onClose: () => void;
  content: any;
  overrideStyle?: React.CSSProperties;
};

function OverlayPopup({
  forceClose,
  children,
  title,
  onClose,
  overrideStyle,
  disableClose
}: any) {
  const [active, setActive] = useState(false);

  useEffect(() => {
    if (forceClose) handleClose();
  }, [forceClose]);

  useEffect(() => {
    if (children) setActive(true);
  }, [children]);

  function handleClose() {
    setActive(false);
    onClose();
  }

  return (
    <div
      className={
        styles["container"] +
        " " +
        (active ? styles["active"] : styles["passive"])
      }
    >
      <div
        className={styles["popup"] + " " + popups["popup"]}
        style={overrideStyle || {}}
      >
        <div className={popups["heading"]}>
          <div className={popups["title"]}>{title}</div>
          <div className={popups["close"]}>
            <CloseButton disabled={disableClose} handler={handleClose} />
          </div>
        </div>
        <div className={popups["content"]}>{children}</div>
      </div>
    </div>
  );
}

export default OverlayPopup;
