import * as React from "react";
import styles from "./inputs.module.scss";
import { useEffect, useState } from "react";

import OverlayPopup, {
  TypeOverlayProps,
} from "../../../prefabs/overlay-popup/overlay-popup";
import { AddImagePopover } from "../../add-image-popover/add-image-popover";
import { buttons } from "../../../styles/theme.module";
import DeleteIcon from "@mui/icons-material/Delete";
import { WButton } from "../../wrappers/wrapper";

function InputImage({ value, label, onChange }: any) {
  const [count, setCount] = useState<number>(1);
  const [inputValue, setInputValue] = useState(value);
  const [forceClose, setForceClose] = useState<boolean>(false);
  const [showPopover, setShowPopover] = useState<boolean>(false);
  const [overlayContent, setOverlayContent] = useState<TypeOverlayProps>(null);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  useEffect(() => {
    showPopover &&
      setOverlayContent({
        title: "Add Image",
        onClose: () => {
          setForceClose(false);
          setShowPopover(false);
          setCount(count + 1);
        },
        content: (
          <AddImagePopover
            forceRender={count}
            onChangeLink={(data: string) => onChange(data)}
            onClosePopover={handleForceClose}
          />
        ),
        overrideStyle: {
          position: "fixed",
          width: "650px",
          height: "500px",
        },
      });
  }, [showPopover]);

  const handleForceClose = () => {
    setShowPopover(false);
    setForceClose(true);
  };

  return (
    <div className={styles["image"]}>
      <div className={styles["label"]}>{label}</div>
      {inputValue ? (
        <div className={styles["link-value-container"]}>
          <span>{inputValue}</span>
          <div>
            <DeleteIcon
              onClick={() => {
                onChange("");
              }}
            />
          </div>
        </div>
      ) : (
        <WButton
          className={`${buttons["button"]} ${buttons["primary"]} ${styles["button"]}`}
          onClick={() => {
            setShowPopover(true);
            setCount(count + 1);
          }}
        >
          Add image
        </WButton>
      )}
      <OverlayPopup
        title={overlayContent?.title}
        onClose={overlayContent?.onClose}
        overrideStyle={overlayContent?.overrideStyle}
        forceClose={forceClose}
      >
        {overlayContent?.content}
      </OverlayPopup>
    </div>
  );
}
export default InputImage;
