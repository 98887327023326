import { CATEGORIES, iComponent } from "../composer-tools/editor-components/EditorComponent";

export type TypeBuilderActions = "add" | "update" | "updateCSSClasses" | "delete" | "move" | "clone";
export type PageBuilderObserver = (component: iComponent, action: TypeBuilderActions, pageJson: iComponent[]) => void
export class PageBuilder {
    page: iComponent[] = [];
    subscriptions: PageBuilderSubscription[] = [];
    factory = new ComponentFactory();

    constructor(page?: iComponent[]) {
        if(page) this.page = page;
    }

    getPage(): iComponent[] {
        return this.page;
    }

    add(component: iComponent): iComponent {
        let shadowComponent = this.factory.create(component);
        if (shadowComponent.getCategory() == CATEGORIES.NAVIGATOR) {
            this.page.unshift(shadowComponent);
        } else {
            this.page.push(shadowComponent);
        }
        this.emit(shadowComponent, "add")
        return shadowComponent;
    }

    update(component: iComponent, propKey: string, propValue: any) {
        component.setProp(propKey, propValue);
        this.emit(component, "update")
    }

    updateCSSClasses(component: iComponent, sectionName: string, value: {id: string, class: string}[]){
        component.setCSSClasses(sectionName, value);
        this.emit(component, "updateCSSClasses")
    }

    delete(index: number) {
        let component: iComponent = this.page[index];
        this.page.splice(index, 1);
        this.emit(component, "delete");
    }

    move(index: number, to: -1 | 1) {
        let component: iComponent = this.page[index];
        this.page.splice(index, 1);
        this.page.splice((index + to), 0, component);
        this.emit(component, "move");
    }

    clone(component: iComponent){
        let clonedComponent = this.factory.clone(component);
        this.page.push(clonedComponent);
        this.emit(clonedComponent, "clone");
    }

    subscribe(callback: PageBuilderObserver) {
        let subscriptionIndex = this.subscriptions.length;
        let subscription = new PageBuilderSubscription(callback, subscriptionIndex, this);
        this.subscriptions.push(subscription);
        return subscription;
    }

    unsubscribe(subscriptionIndex: number) {
        this.subscriptions = this.subscriptions.splice(subscriptionIndex, 1);
    }

    private emit(component: iComponent, action: TypeBuilderActions) {
        this.subscriptions.forEach((subscription: PageBuilderSubscription) => subscription.callback(component, action, this.page))
    }
}

class PageBuilderSubscription {
    cb: PageBuilderObserver;
    builder: PageBuilder;
    subscriptionIndex: number;

    constructor(cb: PageBuilderObserver, index: number, builder: PageBuilder) {
        this.cb = cb;
        this.builder = builder;
        this.subscriptionIndex = index;
    }

    callback: PageBuilderObserver = (component, action, pageJson) => {
        this.cb(component, action, pageJson);
    }

    unsubscribe() {
        this.builder.unsubscribe(this.subscriptionIndex);
    }
}

export class ComponentFactory {

    create(component: iComponent): iComponent {
        let newInstance = new (component.constructor as { new(): iComponent })();

        return newInstance;
    }

    clone(component: iComponent): iComponent {
        let createdCopy: iComponent = this.create(component);
        component.getProps().map(prop => {
            createdCopy.setProp(prop.key, prop.value)
        })
        Object.entries(component.getCSSClasses()).map(([key,value]) => {
            createdCopy.setCSSClasses(key, value as any)
        })
        return createdCopy;
    }
}