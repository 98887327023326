import * as React from "react";
import { useEffect, useState } from "react";
import {
  WAccordion,
  WAccordionDetails,
  WAccordionSummary,
} from "../../components/wrappers/wrapper";
import styles from "./css-gui.module.scss";
import {
  CSS_PROPERTIES,
  getDefaultProps,
  getPropItems,
  getPropType,
  propertyInputTypes,
  PROPERTY_CATEGORY,
} from "./css-properties";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { MarginPadding } from "./components/margin-padding";
import * as all_css_properties from "./all-css-properties.json";
import { Autocomplete, TextField } from "@mui/material";
import { Close } from "@mui/icons-material";
import { Borders } from "./components/borders";
import { debounce } from "lodash";

const defaultProps = getDefaultProps();
export const CSSGUI = ({ values, changeCss }: any) => {
  const [css, setCss] = useState<any>(values);
  const [customProps, setCustomProps] = useState<any>({});
  const [expanded, setExpanded] = useState<string | false>("panel-0");
  const [autocompliteOptions, setAutocompliteOptions] = useState<string[]>([]);

  useEffect(() => {
    const cleanProperties: any = {}
    Object.entries(all_css_properties).filter((el) => {
      if (!el[1].syntax?.includes("[prop:")) {
        cleanProperties[el[0]] = el[1]
      }
    });

    let availableProps = Object.keys(cleanProperties).filter((el) => !defaultProps.includes(el));
    setAutocompliteOptions(availableProps);
  }, []);

  useEffect(() => {
    setCss({ ...values });
  }, [values]);

  useEffect(() => {
    changeCss(css);

    const customPropsTemp: any = {};
    Object.entries(css).forEach((el) => {
      if (!defaultProps.includes(el[0])) {
        customPropsTemp[el[0]] = {
          type: getPropType(el[0]),
          items: getPropItems(el[0]),
        };
      }
    });

    setCustomProps({ ...customPropsTemp });
  }, [css]);

  const onChangeHandle = debounce((data: any) => {
    setCss({ ...css, [data.property]: data.value });
  }, 800);

  useEffect(() => {}, []);

  const handleChangeAccordion =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const handleOnChangeAutocomplete = (value: string) => {
    if (!value) return;

    setCustomProps({
      ...customProps,
      [value]: {
        type: getPropType(value),
        items: getPropItems(value),
      },
    });
  };

  const handleRemoveProp = (prop: string) => {
    delete customProps[prop];
    delete css[prop];
    setCustomProps({ ...customProps });
    setCss({ ...css });
  };

  const accordionDetails = (category: any) => {
    switch (category[0]) {
      case PROPERTY_CATEGORY.MARGIN_PADDING:
        return (
          <MarginPadding
            value={{ margin: css["margin"], padding: css["padding"] }}
            onChange={onChangeHandle}
          />
        );
      case PROPERTY_CATEGORY.CUSTOM:
        return (
          <>
            <Autocomplete
              size={"small"}
              disablePortal
              id="combo-box-demo"
              options={autocompliteOptions}
              renderInput={(params) => <TextField {...params} label="Property" />}
              onChange={(event: any, value: any) => handleOnChangeAutocomplete(value)}
            />
            {Object.entries(customProps).map((prop: any, index) => (
              <div className={styles["custom-prop-wrapper"]} key={`custom-${index}`}>
                {propertyInputTypes[prop[1].type]({
                  property: prop[0],
                  value: css[prop[0]],
                  items: prop[1].items,
                  onChange: onChangeHandle,
                })}
                <Close
                  className={styles["close"]}
                  onClick={() => handleRemoveProp(prop[0])}
                />
              </div>
            ))}
          </>
        );
      case PROPERTY_CATEGORY.BORDERS:
        return (
          <Borders
            value={{ 
              border: css["border"], 
              "border-left": css["border-left"],
              "border-top": css["border-top"],
              "border-right": css["border-right"],
              "border-bottom": css["border-bottom"]
            }}
            onChange={onChangeHandle}
          />
        )
      default:
        return Object.entries(category[1]).map((prop: any, index) => (
          <div key={`prop-${index}`}>
            {propertyInputTypes[prop[1].type]({
              property: prop[0],
              value: css[prop[0]],
              items: prop[1].items,
              onChange: onChangeHandle,
            })}
          </div>
        ));
    }
  };

  return (
    <div className={styles["css-gui"]}>
      {Object.entries(CSS_PROPERTIES).map((category, index) => {
        return (
          <WAccordion
            key={`accordion-${index}`}
            expanded={expanded === `panel-${index}`}
            onChange={handleChangeAccordion(`panel-${index}`)}
            className={styles["accordion"]}
            disableGutters
            elevation={0}
            square
          >
            <WAccordionSummary
              className={styles["accordion-summary"]}
              expandIcon={<ExpandMoreIcon />}
            >
              {category[0]}
            </WAccordionSummary>
            <WAccordionDetails className={styles["accordion-details"]}>
              {accordionDetails(category)}
            </WAccordionDetails>
          </WAccordion>
        );
      })}
    </div>
  );
};
