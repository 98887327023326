import * as React from "react";
import styles from "./overview.module.scss";
import EditIcon from "@mui/icons-material/Edit";
import notificationService from "../../../classes/NotificationService";
import FormContainer from "../../../prefabs/form-container/form-container";

import { Domain } from "../../../classes/bucket";
import { Projects } from "../../../classes/bucket";
import { Database } from "../../../classes/Database";
import { buttons } from "../../../styles/theme.module";
import { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { helperService } from "../../../services/HelperService";
import { Column } from "../../../components/section-card/section-card";
import { AlertModal } from "../../../components/alert-modal/alert-modal";
import { WButton, WTextField } from "../../../components/wrappers/wrapper";
import { ProjectContext, IProjectContext } from "../../../contexts/project";
import { EditComponent } from "../../../components/edit-component/edit-component";
import { AddImagePopover } from "../../../components/add-image-popover/add-image-popover";
import OverlayPopup, {
  TypeOverlayProps,
} from "../../../prefabs/overlay-popup/overlay-popup";

export const ProjectOverview = () => {
  const params = useParams();
  const navigate = useNavigate();

  const visitorCount = 312.312;
  const projectPlaceholderImage = "https://picsum.photos/536/354";

  const token = localStorage.getItem("token");
  const database = new Database("", token);

  const projectContext = useContext<IProjectContext>(ProjectContext);

  const [count, setCount] = useState<number>(1);
  const [favicon, setFavicon] = useState<string>();
  const [forceClose, setForceClose] = useState<boolean>(false);
  const [showPopover, setShowPopover] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [overlayContent, setOverlayContent] = useState<TypeOverlayProps>(null);
  const [name, setName] = useState<{ name: string; editable: boolean }>({
    name: projectContext.project.name,
    editable: false,
  });

  useEffect(() => {
    setFavicon(projectContext.project?.favicon);
  }, [projectContext]);

  const saveFavicon = (url: string) => {
    setFavicon(url);
    updateGeneralSettings({ favicon: url });
  };

  const handleForceClose = () => {
    setShowPopover(false);
    setForceClose(true);
  };

  useEffect(() => {
    showPopover &&
      setOverlayContent({
        title: "Add Image",
        onClose: () => {
          setForceClose(false);
          setShowPopover(false);
          setCount(count + 1);
        },
        content: (
          <AddImagePopover
            forceRender={count}
            onChangeLink={(data: string) => {
              saveFavicon(data);
            }}
            onClosePopover={handleForceClose}
          />
        ),
        overrideStyle: {
          position: "fixed",
          width: "650px",
          height: "500px",
        },
      });
  }, [showPopover]);

  const updateGeneralSettings = (data: Projects) => {
    database
      .patchProject({ ...data, _id: projectContext.project._id })
      .then(() =>
        projectContext.setProject({ ...projectContext.project, ...data })
      );
  };

  useEffect(() => {
    database
      .getProject(params.projectId)
      .then((res) => projectContext.setProject(res));
  }, [params]);

  const handleOnDeleteProject = () => {
    database
      .deleteProject(projectContext.project._id)
      .then(() => {
        const index = projectContext.projects.findIndex(
          (item) => item._id == projectContext.project._id
        );
        if (index >= 0) {
          const contextProjects = projectContext.projects;
          contextProjects.splice(index, 1);
          projectContext.setProjects(contextProjects);
        }
        navigate("/");
      })
      .catch((err) => {
        const errorMessage = helperService.getErrorMessage(err);
        notificationService.errorNotification(errorMessage);
      });
  };

  return (
    <div className={styles["project-detail"]}>
      <div className={styles["top"]}>
        <div className={styles["title"]}>
          <div className={styles["heading-content"]}>
            <div className={styles["left"]}>
              {name.editable ? (
                <WTextField
                  size="small"
                  placeholder="Project Name"
                  autoComplete={"off"}
                  value={name.name}
                  onChange={(e) => setName({ ...name, name: e.target.value })}
                  className={styles["name-input"]}
                />
              ) : (
                <span>{projectContext.project.name}</span>
              )}
              <Column>
                <EditComponent
                  customStyle={{
                    editBtn: {
                      color: "var(--dark-color)",
                      fontSize: "1.8rem",
                      fontWeight: 700,
                    },
                  }}
                  disabledSave={name.name.length < 2}
                  onSave={() => {
                    setName({ ...name, editable: false });
                    updateGeneralSettings({ name: name.name });
                  }}
                  onEdit={() => {
                    setName({ ...name, editable: true });
                  }}
                  onClose={() => {
                    setName({ ...name, editable: false });
                  }}
                />
              </Column>
            </div>
            <div className={styles["right"]}>
              <WButton
                variant="contained"
                className={`${buttons["button"]} ${buttons["primary"]}`}
              >
                View Analytics
              </WButton>
              <WButton
                variant="contained"
                className={`${buttons["button"]} ${buttons["primary"]}`}
                onClick={() =>
                  navigate(`/project/${projectContext.project._id}/seo`)
                }
              >
                SEO
              </WButton>
              <WButton
                variant="contained"
                className={`${buttons["button"]} ${buttons["danger"]}`}
                onClick={() => setOpenDeleteModal(true)}
              >
                Delete
              </WButton>
            </div>
          </div>

          <div className={styles["project-settings"]}>
            <img src={projectPlaceholderImage} alt="" />

            <div className={styles["list"]}>
              <div className={styles["list-item"] + " " + styles["fav-icon"]}>
                <span>Favicon: </span>
                <img src={favicon} alt="" />
                <EditIcon
                  onClick={() => setShowPopover(true)}
                  className={styles["pointer"]}
                />
              </div>
              <div className={styles["list-item"] + " " + styles["domain"]}>
                <span>
                  {(projectContext.project.domains?.length &&
                    (projectContext.project.domains[0] as Domain)?.used) ||
                    "---"}
                </span>{" "}
                <p>
                  {projectContext.project.domains?.length - 1 > 0 &&
                    `( ${projectContext.project.domains?.length - 1} more )`}
                </p>
                <EditIcon
                  onClick={() => {
                    navigate(`/project/${projectContext.project._id}/domain`);
                  }}
                  className={styles["pointer"]}
                />
              </div>
              <div className={styles["list-item"]}>
                <span>{visitorCount} visitors (last 24 hours)</span>
              </div>
              <div className={styles["list-item"]}>
                <span>Last visitor: 6 mins ago</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={styles["forms-container"]}>
        <div className={styles["heading"]}>
          <span>Contact Forms</span>
          <span
            onClick={() =>
              navigate(`/project/${projectContext.project._id}/form`)
            }
          >
            View All Forms
          </span>
        </div>

        <FormContainer
          projectId={params.projectId}
          setUnreadedCount={(count) => {}}
          activateScroll={false}
          limit={6}
        />
      </div>

      <AlertModal
        onConfirm={handleOnDeleteProject}
        title="Delete project"
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
      >
        Are you sure you want the delete the project?
      </AlertModal>
      <OverlayPopup
        title={overlayContent?.title}
        onClose={overlayContent?.onClose}
        overrideStyle={overlayContent?.overrideStyle}
        forceClose={forceClose}
      >
        {overlayContent?.content}
      </OverlayPopup>
    </div>
  );
};
