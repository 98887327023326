import * as React from "react";
import styles from "./domain.module.scss";

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Domain } from "../../../classes/bucket";
import { AlertModal } from "../../../components/alert-modal/alert-modal";
import {
  WButton,
  WList,
  WListItemButton,
  WListItemText,
  WLoadingButton,
  WModal,
  WStack,
  WTextField,
} from "../../../components/wrappers/wrapper";
import { ProjectContext } from "../../../contexts/project";
import { UserContext } from "../../../contexts/user";

import { buttons } from "../../../styles/theme.module";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import PaymentService from "../../../services/PaymentService";
import notificationService from "../../../classes/NotificationService";
import { helperService } from "../../../services/HelperService";
import Icon from "@mui/material/Icon";

type IProjectSettingsDomain = {
  domainActionResult: { action: string; data: any };
};
export const ProjectDomain = ({
  domainActionResult,
}: IProjectSettingsDomain) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openAlertModal, setOpenAlertModal] = useState<boolean>(false);
  const [openRemoveDomainModal, setOpenRemoveDomainModal] =
    useState<string>("");
  const [domain, setDomain] = useState<string>("");
  const [addLoading, setAddLoading] = useState<boolean>(false);
  const [verifyLoadings, setVerifyLoadings] = useState<{
    [key: string]: boolean;
  }>({});
  const [removeLoadings, setRemoveLoadings] = useState<{
    [key: string]: boolean;
  }>({});
  const [selectedOption, setSelectedOption] = useState<number>(0);
  const [showNewDomainInput, setShowNewDomainInput] = useState<boolean>(false);

  const userContext = React.useContext(UserContext);
  const projectContext = React.useContext(ProjectContext);

  const navigate = useNavigate();

  const handleAddDomain = () => {
    setAddLoading(true);
    if (!userContext.paymentMethod) {
      setOpenAlertModal(true);
      setAddLoading(false);
      return;
    }

    const domains =
      selectedOption == 0
        ? ["www." + domain, domain]
        : selectedOption == 1
          ? [domain, "www." + domain]
          : [domain];

    onDomainAction("add", domains);
  };

  const onDomainAction = (action: string, data: any) => {
    switch (action) {
      case "add":
        PaymentService.addDomain(data, projectContext.project._id)
          .then((res) => {
            projectContext.setProject(res);
          })
          .catch((err) => {
            const errorMessage = helperService.getErrorMessage(err);
            notificationService.errorNotification(errorMessage);
          });
        // .finally(() => setDomainActionResult({ action, data }));
        break;
      case "verify":
        PaymentService.verifyDomain(data)
          .then(() => {
            (
              projectContext.project.domains.find(
                (domain) => (domain as Domain)._id == data
              ) as Domain
            ).verified = true;
            projectContext.setProject(projectContext.project);
          })
          .catch((err) => {
            const errorMessage = helperService.getErrorMessage(err);
            notificationService.errorNotification(errorMessage);
          });
        // .finally(() => setDomainActionResult({ action, data }));
        break;

      case "remove":
        PaymentService.removeDomain(data)
          .then(() => {
            projectContext.setProject({
              ...projectContext.project,
              domains: projectContext.project.domains.filter(
                (item) => (item as Domain)._id != data
              ),
            });
          })
          .catch((err) => {
            const errorMessage = helperService.getErrorMessage(err);
            notificationService.errorNotification(errorMessage);
          });
        // .finally(() => setDomainActionResult({ action, data }));
        break;
    }
  };

  useEffect(() => {
    if (!domainActionResult) return;

    switch (domainActionResult.action) {
      case "verify":
        setVerifyLoadings({
          ...verifyLoadings,
          [domainActionResult.data]: false,
        });
        break;
      case "add":
        setAddLoading(false);
        break;
      case "remove":
        setRemoveLoadings({
          ...removeLoadings,
          [domainActionResult.data]: false,
        });
        break;
    }
  }, [domainActionResult]);

  const handleGoPayment = () => {
    navigate("/profile");
  };
  const handleRemoveDomain = () => {
    onDomainAction("remove", openRemoveDomainModal);
    setRemoveLoadings({
      ...removeLoadings,
      [openRemoveDomainModal]: true,
    });
    setOpenRemoveDomainModal(null);
  };

  const sortedDomains = () =>
    projectContext.project?.domains?.sort(
      (a, b) =>
        new Date((b as Domain).created_at).getTime() -
        new Date((a as Domain).created_at).getTime()
    );

  const handleDomainAction = (action: "add" | "save") => {
    switch (action) {
      case "add":
        setShowNewDomainInput(true);
        break;
      case "save":
        setShowNewDomainInput(false);
        if (!domain.length) return;
        setOpenModal(true);
        break;
      default:
        break;
    }
  };
  return (
    <div className={styles["domain-container"]}>
      <div className={styles["top"]}>
        <div className={styles["title"]}>
          <div className={styles["heading-content"]}>
            <div className={styles["left"]}>
              <span>Connected Domains</span>
              <span>
                {projectContext.project?.domains?.length} Connected domains
              </span>
            </div>
            <div className={styles["right"]}>
              <WTextField
                autoComplete={"off"}
                placeholder="mywebsite.com"
                size="small"
                prefix={(<Icon>domain_add</Icon>) as any}
                className={`${styles["new-domain-input"]} ${showNewDomainInput ? styles["show"] : ""
                  }`}
                onChange={($event) => setDomain($event.target.value)}
              />
              <div className={styles["button-wrapper"]}>
                <WButton
                  variant="contained"
                  className={`${styles["domain-action-button"]} ${buttons["button"]} ${buttons["primary"]}`}
                  onClick={() => {
                    handleDomainAction(showNewDomainInput ? "save" : "add");
                  }}
                >
                  {showNewDomainInput ? "Save" : "Add New Domain"}
                </WButton>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={styles["domains"]}>
        <div className={`${styles["row"]} ${styles["header"]}`}>
          <div>Domain</div>
          <div>Scopes</div>
          <div>Refirect To</div>
          <div>Status</div>
          <div></div>
        </div>
        {sortedDomains()?.map((item: any, index) => {
          const domain = item as Domain;
          const redirect = domain.redirect
            ? (
              projectContext.project?.domains.find(
                (dm) => (dm as Domain)._id == domain.redirect
              ) as Domain
            ).used
            : "No redirect";
          return (
            <div key={`domain-${index}`} className={`${styles["row"]}`}>
              <div>{item.used}</div>
              <div className={`${styles["scopes"]}`}>
                <span>www</span>
                <span>http</span>
                <span>https</span>
              </div>
              <div>{redirect} </div>
              <div className={styles["status"]}>
                <div
                  className={
                    styles[domain.verified ? "active-box" : "passive-box"]
                  }
                ></div>
                {domain.verified ? "Active" : "Non verified"}
              </div>
              <div className={styles["actions"]}>
                {!domain.verified && (
                  <WLoadingButton
                    className={`${buttons["button"]} ${buttons["primary"]}`}
                    onClick={() => {
                      onDomainAction("verify", domain._id);
                      setVerifyLoadings({
                        ...verifyLoadings,
                        [domain._id]: true,
                      });
                    }}
                    loading={!!verifyLoadings[domain._id]}
                  >
                    Verify
                  </WLoadingButton>
                )}
                <EditIcon />
                <DeleteIcon
                  onClick={() => setOpenRemoveDomainModal(domain._id)}
                />
              </div>
            </div>
          );
        })}
      </div>

      <WModal width={500} open={openModal} onClose={() => setOpenModal(false)}>
        <div>
          <h3>Add Custom Domain</h3>
          <hr />
          <WList>
            <WListItemButton
              selected={selectedOption == 0}
              onClick={() => setSelectedOption(0)}
            >
              <WListItemText
                primary={
                  <WStack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <span>
                      Use <b>www.{domain}</b> and redirect <b>{domain}</b> to it
                    </span>

                    <span>Recommended</span>
                  </WStack>
                }
              />
            </WListItemButton>
            <WListItemButton
              selected={selectedOption == 1}
              onClick={() => setSelectedOption(1)}
            >
              <WListItemText
                primary={
                  <span>
                    Use <b>{domain}</b> and redirect <b>www.{domain}</b> to it
                  </span>
                }
              />
            </WListItemButton>
            <WListItemButton
              selected={selectedOption == 2}
              onClick={() => setSelectedOption(2)}
            >
              <WListItemText
                primary={
                  <span>
                    Add <b>{domain}</b>
                  </span>
                }
              />
            </WListItemButton>
          </WList>
          <WStack pt={2} direction="row" justifyContent="space-between">
            <WButton
              className={`
                ${buttons["button"]}
                ${buttons["primary"]}
              `}
              onClick={() => setOpenModal(false)}
            >
              Cancel
            </WButton>
            <WButton
              variant="contained"
              className={`
                ${buttons["button"]}
                ${buttons["success"]}
              `}
              onClick={() => {
                setOpenModal(false);
                handleAddDomain();
              }}
            >
              Add
            </WButton>
          </WStack>
        </div>
      </WModal>
      <AlertModal
        open={openAlertModal}
        onClose={() => setOpenAlertModal(false)}
        title="Need payment method"
        onConfirm={handleGoPayment}
      >
        <WStack pb={1}> You should add a payment method first.</WStack>
        <WStack>
          Confirm button will navigate you to your profile for add a payment
          method.
        </WStack>
      </AlertModal>

      <AlertModal
        open={!!openRemoveDomainModal}
        onClose={() => setOpenRemoveDomainModal(null)}
        title="Domain deletion"
        onConfirm={handleRemoveDomain}
      >
        <WStack pb={1}> Are you sure you want to remove the domain?</WStack>
        <WStack>This may have other consequences for you.</WStack>
      </AlertModal>
    </div>
  );
};
