import * as React from "react";
import styles from "./webhook.module.scss";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Webhooks } from "../../../classes/bucket";
import { Database } from "../../../classes/Database";
import { buttons } from "../../../styles/theme.module";
import { AlertModal } from "../../../components/alert-modal/alert-modal";
import {
  WButton,
  WCheckbox,
  WCircularProgress,
  WFormControlLabel,
  WMenuItem,
  WModal,
  WSelect,
  WStack,
  WTextField,
} from "../../../components/wrappers/wrapper";

export const ProjectWebhook = () => {
  const params = useParams();
  const token = localStorage.getItem("token");
  const database = new Database("", token);

  const Target = ["Zapier"];
  const Action = ["Form Submit"];

  const [skip, setSkip] = useState<number>(0);
  const [deleteId, setDeleteId] = useState<string>();
  const [webhooks, setWebhooks] = useState<Webhooks[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [activeWebhook, setActiveWebhook] = useState<number>(0);
  const [openRemoveModal, setOpenRemoveModal] = useState<boolean>(false);

  const [inputUrl, setInputUrl] = useState<string>();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [inputAction, setInputAction] = useState<string>();
  const [inputEditId, setInputEditId] = useState<string>();
  const [inputTarget, setInputTarget] = useState<string>();
  const [isAddWebhook, setAddWebhook] = useState<boolean>(false);
  const [inputStatus, setInputStatus] = useState<boolean>(false);

  const getWebhooks = () => {
    setIsLoading(true);
    database
      .getWebhooks(params.projectId)
      .then((res: any) => {
        setWebhooks(res);
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    setActiveWebhook(0);
    getWebhooks();
  }, [skip]);

  useEffect(() => {
    for (let index = 0; index < webhooks.length; index++) {
      const element = webhooks[index];
      if (element.is_active) setActiveWebhook((prevCount) => prevCount + 1);
    }
  }, [webhooks.length]);

  const resetStates = () => {
    setSkip(skip + 1);
    setInputStatus(false);
  };

  function getOnlyDomain(url: any) {
    if (url.indexOf("http://") === 0 || url.indexOf("https://") === 0) {
      url = url.split("://")[1];
    }

    if (url.indexOf("www.") === 0) {
      url = url.slice(4);
    }

    url = url.split("/")[0];

    url = url.split(":")[0];

    return url;
  }

  const handleRemoveWebhook = () => {
    database.removeWebhooks(deleteId).then(() => {
      resetStates();
    });
    setOpenRemoveModal(false);
  };

  const handleAction = (action: "add" | "update") => {
    switch (action) {
      case "add":
        database
          .addWebhooks(
            inputUrl,
            params.projectId,
            inputStatus,
            inputAction,
            inputTarget
          )
          .finally(() => {
            setOpenModal(false);
            resetStates();
          });
        break;
      case "update":
        database
          .updateWebhooks(
            inputEditId,
            inputUrl,
            params.projectId,
            inputStatus,
            inputAction,
            inputTarget
          )
          .finally(() => {
            setOpenModal(false);
            resetStates();
          });
        break;
    }
  };

  return (
    <div className={styles["webhook-container"]}>
      <div className={styles["top"]}>
        <div className={styles["title"]}>
          <div className={styles["heading-content"]}>
            <div className={styles["left"]}>
              <span>Webhooks</span>
              <span>
                {webhooks.length} Webhooks ({activeWebhook} active)
              </span>
            </div>
            <div className={styles["right"]}>
              <WButton
                variant="contained"
                className={`${buttons["button"]} ${buttons["primary"]}`}
                onClick={() => {
                  setOpenModal(true);
                  setInputStatus(false);
                  setInputUrl("");
                  setInputTarget("");
                  setInputAction("");
                  setAddWebhook(true);
                }}
              >
                Add New Webhook
              </WButton>
            </div>
          </div>
        </div>
      </div>

      <div className={styles["content"]}>
        {isLoading ? (
          <WCircularProgress size="2.5rem" />
        ) : webhooks.length ? (
          <div className={styles["webhooks"]}>
            <div className={`${styles["row"]} ${styles["header"]}`}>
              <div>URL</div>
              <div>Target</div>
              <div>Action</div>
              <div>Status</div>
              <div></div>
            </div>
            {webhooks.map((data, index: number) => {
              let getDomain = getOnlyDomain(data.url);
              return (
                <div key={`webhooks-${index}`} className={styles["row"]}>
                  <div key={index}>{getDomain}</div>
                  <div className={styles["capitalize"]}>{data.target}</div>
                  <div>
                    {data.action == "form_submit" ? "Form Submission" : "none"}
                  </div>
                  <div className={styles["status"]}>
                    <div
                      className={
                        styles[data.is_active ? "active-box" : "passive-box"]
                      }
                    ></div>
                    {data.is_active ? "Active" : "Disabled"}
                  </div>
                  <div className={styles["actions"]}>
                    <EditIcon
                      onClick={() => {
                        setOpenModal(true);
                        setInputStatus(data.is_active);
                        setInputUrl(data.url);
                        setInputEditId(data._id);
                        setInputTarget("zapier");
                        setInputAction("form_submit");
                        setAddWebhook(false);
                      }}
                    />
                    <DeleteIcon
                      onClick={() => {
                        setOpenRemoveModal(true);
                        setDeleteId(data._id);
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <span>Nothing to see here!</span>
        )}
      </div>

      <WModal width={500} open={openModal} onClose={() => setOpenModal(false)}>
        <WStack spacing={2}>
          <WTextField
            fullWidth
            placeholder="Webhook Url"
            name={"url"}
            autoComplete={"off"}
            onChange={(e) => {
              setInputUrl(e.currentTarget.value);
            }}
            value={inputUrl}
          />
          <WSelect
            defaultValue={isAddWebhook ? "" : "Zapier"}
            renderValue={inputTarget ? undefined : () => "Target"}
            fullWidth
            displayEmpty
          >
            {Target.map((target) => (
              <WMenuItem
                key={target}
                value={target}
                onClick={() => {
                  if (target == "Zapier") setInputTarget("zapier");
                }}
              >
                {target}
              </WMenuItem>
            ))}
          </WSelect>

          <WSelect
            defaultValue={isAddWebhook ? "" : "Form Submit"}
            renderValue={inputTarget ? undefined : () => "Action"}
            fullWidth
            displayEmpty
          >
            {Action.map((action) => (
              <WMenuItem
                key={action}
                value={action}
                onClick={() => {
                  if (action == "Form Submit") setInputAction("form_submit");
                }}
              >
                {action}
              </WMenuItem>
            ))}
          </WSelect>
          <WFormControlLabel
            name={"status"}
            className={styles["autocontent-checkbox"]}
            control={
              <WCheckbox
                checked={inputStatus}
                onClick={(e) => {
                  setInputStatus(!inputStatus);
                }}
              />
            }
            label="Active"
          />
          <WButton
            variant="contained"
            className={`${buttons["button"]} ${buttons["primary"]}`}
            onClick={() => handleAction(isAddWebhook ? "add" : "update")}
            disabled={inputUrl?.length < 3 || !inputTarget || !inputAction}
          >
            {isAddWebhook ? "Add New Webhook" : "Save"}
          </WButton>
        </WStack>
      </WModal>

      <AlertModal
        open={!!openRemoveModal}
        onClose={() => setOpenRemoveModal(false)}
        title="Webhooks deletion"
        onConfirm={handleRemoveWebhook}
      >
        <WStack pb={1}> Are you sure you want to delete?</WStack>
      </AlertModal>
    </div>
  );
};
