import * as React from "react";
import styles from "./navigator.module.scss";
import { useContext, useEffect, useState } from "react";

import { Page } from "../../classes/Page";
import { Modal } from "../../classes/Modal";
import { PageBuilder } from "../../classes/PageBuilder";
import { CSS_Classes, Projects } from "../../classes/bucket";
import { helperService } from "../../services/HelperService";
import { IUserContext, UserContext } from "../../contexts/user";
import { WBox, WDialog } from "../../components/wrappers/wrapper";
import { TypeOverlayProps } from "../overlay-popup/overlay-popup";
import { LocalizationPrefab } from "../localization/localization";
import { Localization } from "../../classes/Localization/Localization";
import { ILoadingContext, LoadingContext } from "../../contexts/loading";
import { IProjectContext, ProjectContext } from "../../contexts/project";
import { editor, eventEmitter } from "../../pages/project/editor/editor";
import { CreatePagePopover } from "../create-page-popover/create-page-popover";
import { IMetaTagFormValues } from "../../components/page-meta-tag/page-meta-tag";
import { iComponent } from "../../composer-tools/editor-components/EditorComponent";
import { LeftToolbarButton } from "../../components/left-toolbar-button/left-toolbar-button";
import {
  TypeColorTheme,
  TypeEnvironments,
  TypeFontFamily,
} from "../../classes/Editor";

import Theme from "../theme/theme";
import ModalPicker from "../modal-picker/modal-picker";
import NavigatorPages from "../navigator-pages/navigator-pages";
import notificationService from "../../classes/NotificationService";
import CustomCodeEditor from "../custom-code-editor/custom-code-editor";
import ComponentCSSPopover from "../component-css-popover/component-css-popover";
import FontFamilySelection from "../font-family-selection/font-family-selection";

interface iNavigator {
  page: Page;
  pages: Page[];
  modals: Modal[];
  project: Projects;
  isPreview: boolean;
  activePageIndex: number;
  cssClasses: CSS_Classes[];
  onReload: () => void;
  onSetPages: (pages: Page[]) => void;
  onSetModals: (modals: Modal[]) => void;
  onPageChange: (value: boolean) => void;
  onSetProject: (project: Projects) => void;
  setActivePageIndex: (index: number) => void;
  onForceClosePopover: (value: boolean) => void;
  onSetEditingElement: (element: iComponent, index?: number) => void;
  onSetDisableClosePopover: (value: boolean) => void;
  onSetOverlayContent: (value: TypeOverlayProps) => void;
}
export function Navigator({
  page,
  pages,
  modals,
  project,
  isPreview,
  cssClasses,
  activePageIndex,
  onReload,
  onSetPages,
  onSetModals,
  onSetProject,
  onPageChange,
  setActivePageIndex,
  onForceClosePopover,
  onSetOverlayContent,
  onSetEditingElement,
  onSetDisableClosePopover,
}: iNavigator) {
  const locale = new Localization();
  const userContext = useContext<IUserContext>(UserContext);
  const loadingContext = useContext<ILoadingContext>(LoadingContext);
  const projectContext = useContext<IProjectContext>(ProjectContext);

  const [addState, setAddState] = useState<boolean>(false);
  const [customCode, setCustomCode] = useState<string>(null);
  const [fontFamily, setFontFamily] = useState<boolean>(false);
  const [creatingPage, setCreatingPage] = useState<boolean>(false);
  const [localization, setLocalization] = useState<boolean>(false);
  const [editingTheme, setEditingTheme] = useState<boolean>(false);
  const [modalsPopover, setModalsPopover] = useState<boolean>(false);
  const [editingCustomCode, setEditingCustomCode] = useState<boolean>(false);
  const [editingCSS, setEditingCSS] = useState<{
    index: number;
    component: iComponent;
  }>(null);
  const [themeModalVisibility, setThemeModalVisibility] =
    useState<boolean>(false);
  const [customJsCodeModalVisibility, setCustomJsCodeModalVisibility] =
    useState<boolean>(false);
  const [modalsModalVisibility, setModalsModalVisibility] =
    useState<boolean>(false);

  const [position, setPosition] = useState({ top: 0, left: 0 });

  const handleClick = (event: any) => {
    setPosition({
      top: event.currentTarget.offsetTop,
      left: event.currentTarget.offsetLeft + event.currentTarget.offsetWidth + 5,
    });
  };
  useEffect(() => {
    if (project && !project.pages.length) {
      setCreatingPage(true);
      onSetDisableClosePopover(true);
    }
  }, [project]);

  // --------> START: OVERLAY POPUPS
  useEffect(() => {
    creatingPage &&
      onSetOverlayContent({
        title: "Create Page",
        onClose: () => {
          setCreatingPage(false);
          onForceClosePopover(false);
        },
        content: (
          <CreatePagePopover
            firstPage={!!!pages?.length}
            onSave={(meta) => {
              addPage(meta);
            }}
          />
        ),
        overrideStyle: {
          width: "100%",
          maxWidth: "600px",
          height: "487px",
        },
      });

    editingCSS &&
      onSetOverlayContent({
        title: "Edit CSS",
        onClose: () => {
          setEditingCSS(null);
        },
        content: (
          <ComponentCSSPopover
            component={editingCSS.component}
            projectCssClasses={cssClasses}
            onCSSChanged={(cssClassId, css) => onCSSChanged(cssClassId, css)}
            onClassAdded={(sectionName, className, value) =>
              handleAddCssClass(
                editingCSS.component,
                editingCSS.index,
                sectionName,
                className,
                value
              )
            }
            onClassDisposed={(sectionName, value) => {
              page.updateComponentCssClasses(
                editingCSS.component,
                editingCSS.index,
                sectionName,
                value
              );
              page.savePage();
            }}
          ></ComponentCSSPopover>
        ),
        overrideStyle: {
          position: "fixed",
          width: "320px",
          height: "calc(100vh - 20px)",
          left: "10px",
          top: "10px",
        },
      });

    fontFamily &&
      onSetOverlayContent({
        title: "Font Family",
        onClose: () => {
          setFontFamily(false);
          onPageChange(true);
        },
        content: (
          <FontFamilySelection
            defaultValue={project.theme_config.fonts}
            onChange={(fontFamily: TypeFontFamily) => {
              project.theme_config.fonts = fontFamily;
              onSetProject({ ...project });
              editor.changeThemeFonts(fontFamily);
            }}
          ></FontFamilySelection>
        ),
      });

    modalsPopover &&
      onSetOverlayContent({
        title: "Modals",
        onClose: () => {
          setModalsPopover(false);
          onForceClosePopover(false);
        },
        content: (
          <ModalPicker
            addState={addState}
            setAddState={(value) => setAddState(value)}
            enablActions={true}
            components={Array.from(
              editor.getModals(),
              (el) => el.getModal()[0]
            )}
            pickedNewComponent={(component) => addModal(component)}
          ></ModalPicker>
        ),
        overrideStyle: {
          position: "fixed",
          width: "760px",
          height: "570px",
        },
      });

    localization &&
      onSetOverlayContent({
        title: "Localization",
        onClose: () => {
          setLocalization(false);
          onForceClosePopover(false);
        },
        content: (
          <LocalizationPrefab
            project={project}
            onSave={saveLocalization}
          ></LocalizationPrefab>
        ),
        overrideStyle: {
          width: "400px",
          height: "350px",
        },
      });
  }, [
    modals,
    editingCSS,
    fontFamily,
    localization,
    editingTheme,
    creatingPage,
    modalsPopover,
    editingCustomCode,
  ]);
  // ---------> END: OVERLAY POPUPS
  useEffect(() => {
    const modalSubscription = eventEmitter.subscribe(
      "modalComponent",
      (data: { action: "edit" | "delete"; index: number }) => {
        modalComponentActions(data.action, data.index);
      }
    );
    return () => {
      modalSubscription.unsubscribe();
    };
  }, [modals]);

  useEffect(() => {
    if (!customCode) return;
    editor.customCode = customCode;
    page.custom_script = customCode;
  }, [customCode]);

  function modalComponentActions(action: string, index: number) {
    editor.selectedModalIndex = index;
    switch (action) {
      case "edit":
        onForceClosePopover(true);
        onSetEditingElement(null);
        loadingContext.setLoading(true);
        setTimeout(() => {
          loadingContext.setLoading(false);
          const modal = editor
            .getModal(editor.selectedModalIndex)
            .getModal()[0];
          onSetEditingElement(modal, index);
        }, 500);
        break;
      case "delete":
        editor.cleanModal(modals[editor.selectedModalIndex].name);
        deleteModal(editor.selectedModalIndex);
        break;
      default:
        break;
    }
  }

  async function deleteModal(index: number) {
    const newModalsArray = editor.deleteModal(index);

    const deepCopyArray = newModalsArray.slice();
    onSetModals(deepCopyArray);
    setActivePageIndex(
      deepCopyArray.length == 1 ? -1 : deepCopyArray.length - 1
    );
  }

  async function onCSSChanged(cssClassId: string, css: string) {
    editor.updateCssClass(cssClassId, css);
    editor.applyStyleCSS();
  }

  async function deletePage(index: number) {
    if (pages.length == 1) return;
    const newPagesArray = editor.deletePage(index);

    const deepCopyArray = newPagesArray.slice();
    onSetPages(deepCopyArray);
    setActivePageIndex(
      deepCopyArray.length == 1 ? -1 : deepCopyArray.length - 1
    );
  }

  async function addModal(component: iComponent) {
    loadingContext.setLoading(true);
    const newModalsArray = await editor.addModal(
      userContext.user._id,
      project?._id,
      `modal-${Math.random()}`
    );
    project.modals = newModalsArray.map((modal) => modal.id);

    const newModal = newModalsArray[newModalsArray.length - 1];
    newModal.addModalComponent(component);
    newModal.saveModal();

    const deepCopyArray = newModalsArray.slice();
    onSetModals(deepCopyArray);
    onSetProject(project);
    onSetDisableClosePopover(false);
    loadingContext.setLoading(false);
    setAddState(false);
  }

  async function addPage(meta: IMetaTagFormValues) {
    const result = helperService.isSlugValidCheckByPage(meta.slug, pages);
    if (!result.valid) {
      notificationService.errorNotification(result.message);
      return;
    }

    setCreatingPage(false);
    onForceClosePopover(true);
    loadingContext.setLoading(true);
    const newPagesArray = await editor.addPage(
      userContext.user._id,
      project?._id,
      `name${Math.random()}`,
      meta.slug.split(" ").join("-").toLowerCase(),
      {
        ...meta.values,
      },
      ""
    );
    project.pages = newPagesArray.map((page) => page.id);
    const deepCopyArray = newPagesArray.slice();
    onSetPages(deepCopyArray);
    onSetProject(project);
    setActivePageIndex(newPagesArray.length - 1);
    onSetDisableClosePopover(false);
    loadingContext.setLoading(false);
  }

  async function saveLocalization(data: any) {
    const _page = page.getPage();
    for (const page of pages) {
      data.languages.forEach((lang: string) => {
        let localItem = page.localization.find(
          (el: any) => el.language == lang
        );
        if (!localItem) {
          page.localization.push({
            language: lang,
            builder: new PageBuilder(_page),
          });
          page.savePage();
        }
      });
      page.localization.forEach((local: any) => {
        let langItem = data.languages.find(
          (lang: string) => lang == local.language
        );
        if (!langItem) {
          page.localization = page.localization.filter(
            (el: any) => el.language != local.language
          );
          page.savePage();
        }
      });
    }

    onSetEditingElement(null);
    onForceClosePopover(true);

    locale.currentLanguage = data.currentLanguage;
    const project = {
      ...projectContext.project,
      current_language: data.currentLanguage,
      languages: data.languages,
    };
    projectContext.setProject(project);

    await editor.saveProject(project);
    onReload();
  }

  async function handleAddCssClass(
    component: iComponent,
    index: number,
    sectionName: string,
    className: string,
    value: { id: string; class: string }[]
  ) {
    if (className) {
      const cssClass = await editor.addCssClass(
        className,
        userContext.user._id,
        project._id
      );
      value.push({ id: cssClass._id, class: className });
      const newCssClassesArr = project.css_classes
        ? [...project.css_classes, cssClass._id]
        : [cssClass._id];
      project.css_classes = newCssClassesArr;
      onSetProject(project);
      editor.saveProject(project);
      onReload();
    }

    page.updateComponentCssClasses(component, index, sectionName, value);
    page.savePage();
  }

  return (
    <div
      className={`${styles["navigator"]} ${isPreview ? styles["hide"] : ""}`}
    >
      <div className={styles["sections"]}>
        <NavigatorPages
          pages={pages || []}
          activePageIndex={activePageIndex}
          onPageChanged={(index: number) => {
            setActivePageIndex(index);
          }}
          onAddPage={() => {
            setCreatingPage(true);
          }}
          onDeletePage={(index: number) => {
            deletePage(index);
          }}
        ></NavigatorPages>
      </div>
      <div className={styles["settings"]}>
        <LeftToolbarButton
          icon="settings"
          onClick={(e: any) => {
            setThemeModalVisibility(true);
            handleClick(e);
          }}
        ></LeftToolbarButton>
        <LeftToolbarButton
          icon="code"
          onClick={(e: any) => {
            setCustomJsCodeModalVisibility(true);
            setCustomCode(page.custom_script);
            handleClick(e);
          }}
        ></LeftToolbarButton>
        <LeftToolbarButton
          icon="layers"
          onClick={(e: any) => {
            handleClick(e);
            setModalsModalVisibility(true);
          }}
        ></LeftToolbarButton>

        <LeftToolbarButton
          onClick={() => setLocalization(true)}
          badge={project?.current_language}
          icon="language"
        />
      </div>
      <WDialog
        hideBackdrop
        open={themeModalVisibility}
        onClose={() => {
          setThemeModalVisibility(false);
          onPageChange(true);
        }}
      >
        {project && (
          <WBox
            position="fixed"
            top={position.top - 95}
            left={position.left}
            zIndex={2}
            background="var(--background-color)"
            width={400}
            height={200}
            maxHeight={"100%"}
            borderRadius={1}
            overflow={"auto"}
            boxShadow="var(--popup-shadow)"
          >
            <Theme
              environments={project.theme_config.environments}
              defaultTheme={project.theme_config.colors}
              project={project}
              onSetProject={onSetProject}
              editor={editor}
              onChanged={(
                theme: TypeColorTheme,
                environments: TypeEnvironments
              ) => {
                project.theme_config.colors = theme;
                project.theme_config.environments = environments;
                onSetProject({ ...project });
                editor.changeThemeColors(theme);
                editor.changeThemeEnvironments(environments);
              }}
              onPageChange={(value: boolean) => onPageChange(value)}
            ></Theme>
          </WBox>
        )}
      </WDialog>
      <WDialog
        hideBackdrop
        disableAutoFocus={true}
        open={customJsCodeModalVisibility}
        onClose={() => {
          setCustomJsCodeModalVisibility(false);
          onPageChange(true);
        }}
      >
        {project && (
          <WBox
            position="fixed"
            top={position.top - 195}
            left={position.left}
            zIndex={2}
            background="var(--background-color)"
            width={400}
            height={300}
            maxHeight={"100%"}
            borderRadius={1}
            overflow={"auto"}
            boxShadow="var(--popup-shadow)"
          >
            <CustomCodeEditor
              codeString={customCode}
              onChanged={(codeString: string) => {
                setCustomCode(codeString);
              }}
            ></CustomCodeEditor>
          </WBox>
        )}
      </WDialog>
      <WDialog
        hideBackdrop
        disableAutoFocus={true}
        open={modalsModalVisibility}
        onClose={() => {
          setModalsModalVisibility(false);
          onPageChange(true);
        }}
      >
        {project && (
          <WBox
            position="fixed"
            top={position.top - 195}
            left={position.left}
            zIndex={2}
            background="var(--background-color)"
            width={400}
            height={300}
            maxHeight={"100%"}
            borderRadius={1}
            overflow={"auto"}
            boxShadow="var(--popup-shadow)"
          >
            <ModalPicker
              addState={addState}
              setAddState={(value) => setAddState(value)}
              enablActions={true}
              components={Array.from(
                editor.getModals(),
                (el) => el.getModal()[0]
              )}
              pickedNewComponent={(component) => addModal(component)}
            ></ModalPicker>
          </WBox>
        )}
      </WDialog>
    </div>
  );
}
