import { iComponent } from "../../composer-tools/editor-components/EditorComponent";
import { Modal } from "../Modal";
import { Page } from "../Page";
import { ComponentFactory } from "../PageBuilder";
import { LANGUAGES } from "./languages";

export type TypeLanguage = {
  code: string;
  name: string;
  nativeName: string;
};

export class Localization {
  private _currentLanguage: string;
  private _availableLanguages: TypeLanguage[];

  public get availableLanguages(): TypeLanguage[] {
    return this._availableLanguages;
  }
  public set availableLanguages(value: TypeLanguage[]) {
    this._availableLanguages = value;
  }
  public get currentLanguage(): string {
    return this._currentLanguage;
  }
  public set currentLanguage(value: string) {
    localStorage.setItem("language", value);
    this._currentLanguage = value;
  }

  constructor() {
    this.currentLanguage = localStorage.getItem("language") || "en";
    this.availableLanguages = LANGUAGES;
  }
}

export class LocalizationInvoker {
  page: Page;
  constructor(page: Page) {
    this.page = page;
  }
  commands: LocalizationCommand[] = [];
  addCommand(command: LocalizationCommand) {
    this.commands.push(command);
  }

  execute() {
    this.commands.forEach((command) => {
      command.apply(this.page);
    });
    this.commands = [];
  }
}

export class LocalizationInvokerModal {
  modal: Modal;
  constructor(modal: Modal) {
    this.modal = modal;
  }
  commands: LocalizationCommandModal[] = [];
  addCommand(command: LocalizationCommandModal) {
    this.commands.push(command);
  }

  execute() {
    this.commands.forEach((command) => {
      command.apply(this.modal);
    });
    this.commands = [];
  }
}

abstract class LocalizationCommand {
  componentFactory: ComponentFactory = new ComponentFactory();
  locale: Localization = new Localization();
  abstract apply(page: Page): void;
}

abstract class LocalizationCommandModal {
  componentFactory: ComponentFactory = new ComponentFactory();
  locale: Localization = new Localization();
  abstract apply(modal: Modal): void;
}

export class CommandAddComponent extends LocalizationCommand {
  component: iComponent;
  constructor(component: iComponent) {
    super();
    this.component = component;
  }
  apply(page: Page) {
    page.localization.forEach((locale) => {
      if(locale.language != this.locale.currentLanguage){
        locale.builder.add(this.component)
      }
    });
  }
}

export class CommandDeleteComponent extends LocalizationCommand {
  index: number;
  constructor(index: number) {
    super();
    this.index = index;
  }
  apply(page: Page) {
    page.localization.forEach((locale) => {
      if(locale.language != this.locale.currentLanguage){
        locale.builder.delete(this.index)
      }
    });
  }
}

export class CommandChangeOrder extends LocalizationCommand {
  index: number;
  to: -1 | 1;
  constructor(index: number, to: -1 | 1) {
    super();
    this.index = index;
    this.to = to;
  }
  apply(page: Page) {
    page.localization.forEach((locale) => {
      if(locale.language != this.locale.currentLanguage){
        locale.builder.move(this.index, this.to)
      }
    });
  }
}

export class CommandCloneComponent extends LocalizationCommand {
  component: iComponent;
  constructor(component: iComponent) {
    super();
    this.component = component;
  }
  apply(page: Page) {
    page.localization.forEach((locale) => {
      if(locale.language != this.locale.currentLanguage){
        locale.builder.clone(this.component)
      }
    });
  }
}

export class CommandUpdateComponentCssClasses extends LocalizationCommand {
  index: number;
  className: string; 
  value: {id: string, class: string}[];
  constructor(index: number, className: string, value: {id: string, class: string}[]) {
    super();
    this.index = index;
    this.className = className;
    this.value = value;
  }
  apply(page: Page) {
    page.localization.forEach((locale) => {
      if(locale.language != this.locale.currentLanguage){
        locale.builder.updateCSSClasses(locale.builder.page[this.index], this.className, this.value)
      }
    });
  }
}

export class CommandAddModalComponent extends LocalizationCommandModal {
  component: iComponent;
  constructor(component: iComponent) {
    super();
    this.component = component;
  }
  apply(modal: Modal) {
    modal.localization.forEach((locale) => {
      if(locale.language != this.locale.currentLanguage){
        locale.builder.addModal(this.component)
      }
    });
  }
}

export class CommandDeleteModalComponent extends LocalizationCommandModal {
  index: number;
  constructor(index: number) {
    super();
    this.index = index;
  }
  apply(modal: Modal) {
    modal.localization.forEach((locale) => {
      if(locale.language != this.locale.currentLanguage){
        locale.builder.deleteModal(this.index)
      }
    });
  }
}

export class CommandUpdateModalCssClasses extends LocalizationCommandModal {
  index: number;
  className: string; 
  value: {id: string, class: string}[];
  constructor(index: number, className: string, value: {id: string, class: string}[]) {
    super();
    this.index = index;
    this.className = className;
    this.value = value;
  }
  apply(modal: Modal) {
    modal.localization.forEach((locale) => {
      if(locale.language != this.locale.currentLanguage){
        locale.builder.updateCSSClasses(locale.builder.modal[this.index], this.className, this.value)
      }
    });
  }
}