import * as React from "react";
import { useState, FC } from "react";
import styles from "./edit-component.module.scss";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { SxProps } from "@mui/material";

interface iEditComponent {
  customStyle?: {
    editBtn?: SxProps;
    checkBtn?: SxProps;
    closeBtn?: SxProps;
  };
  disabledSave?: boolean;
  disabledEdit?: boolean;
  onSave?: () => void;
  onEdit?: () => void;
  onClose?: () => void;
}

export const EditComponent: FC<iEditComponent> = ({
  customStyle = {},
  disabledSave = false,
  disabledEdit = false,
  onSave,
  onEdit,
  onClose,
}) => {
  const [edit, setEdit] = useState(false);

  const handleClickCheck = () => {
    setEdit(false);
    onSave();
  };
  const handleClickEdit = () => {
    setEdit(true);
    onEdit();
  };
  const handleClickClose = () => {
    setEdit(false);
    onClose();
  };

  return (
    <div className={styles["edit-button"]}>
      {edit ? (
        <>
          <IconButton
            sx={customStyle.checkBtn}
            disabled={disabledSave}
            onClick={handleClickCheck}
          >
            <CheckIcon />
          </IconButton>
          <IconButton sx={customStyle.closeBtn} onClick={handleClickClose}>
            <CloseIcon />
          </IconButton>
        </>
      ) : (
        <IconButton
          sx={customStyle.editBtn}
          disabled={disabledEdit}
          onClick={handleClickEdit}
        >
          <EditIcon />
        </IconButton>
      )}
    </div>
  );
};
