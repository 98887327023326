import * as React from "react";
import styles from "./left-toolbar-button.module.scss";
import Icon from "@mui/material/Icon";

export function LeftToolbarButton(
  props: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > & {
    text?: string;
    icon?: string;
    badge?: string;
  }
) {
  let propsCopy = JSON.parse(JSON.stringify(props));
  if (propsCopy.text) {
    const splitedText = propsCopy.text.split(" ");
    propsCopy.text = splitedText[0].slice(0, 2).toUpperCase();
    if (splitedText[1]) {
      propsCopy.text += splitedText[1][0].toUpperCase();
    }
  }

  return (
    <div {...props} className={styles["button"]}>
      {props.badge ? (
        <span className={styles["badge"]}>{props.badge}</span>
      ) : (
        ""
      )}

      {propsCopy.text ? (
        <span>{propsCopy.text}</span>
      ) : (
        <Icon>{propsCopy.icon}</Icon>
      )}
    </div>
  );
}
