import * as React from "react";
import styles from "./playground.module.scss";
import { useContext, useRef, useState, useEffect } from "react";

import { Page } from "../../classes/Page";
import { editor } from "../../pages/project/editor/editor";
import { iComponent } from "../../composer-tools/editor-components/EditorComponent";
import { WButton, WCircularProgress } from "../../components/wrappers/wrapper";
import { IProjectContext, ProjectContext } from "../../contexts/project";

import notificationService from "../../classes/NotificationService";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import NotesIcon from "@mui/icons-material/Notes";
import BrushIcon from "@mui/icons-material/Brush";
import CloseIcon from "@mui/icons-material/Close";
import { Menu, MenuItem, Tooltip } from "@mui/material";
import { buttons } from "../../styles/theme.module";

interface iPlayground {
  page: Page;
  pages: Page[];
  isPreview: boolean;
  pageDom: iComponent[];
  modalDom: iComponent[];
  pageIsChanged: boolean;
  editingElement: iComponent;
  showModalContainer: boolean;
  setShowModalContainer: (value: boolean) => void;
  onSetEditingCSS: (data: { index: number; component: iComponent }) => void;
  onSetEditingElement: (
    element: iComponent,
    type?: "Content" | "Design"
  ) => void;
}
export function Playground({
  page,
  pages,
  pageDom,
  modalDom,
  isPreview,
  pageIsChanged,
  editingElement,
  showModalContainer,
  setShowModalContainer,
  onSetEditingCSS,
  onSetEditingElement,
}: iPlayground) {
  const projectContext = useContext<IProjectContext>(ProjectContext);
  const [open, setOpen] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [initialWidth, setInitialWidth] = useState<any>("100%");
  const [initialScale, setInitialScale] = useState<any>();
  const [initialPosition, setInitialPosition] = useState({ x: 0, y: 0 });
  const [borderRadius, setBorderRadius] = useState(0);
  const playGroundRef = useRef<HTMLDivElement>();
  const [componentIndex, setComponentIndex] = useState<number>();
  const [publishing, setPublishing] = useState<boolean>(false);

  useEffect(() => {
    if (editingElement?.getCategory() !== "modal") return;
    setShowModalContainer(true);
  }, [editingElement]);

  function publishProject() {
    setPublishing(true);
    editor
      .publishProject(projectContext?.project?._id)
      .then((_) => {
        notificationService.succesNotification(
          "Project published successfully"
        );
      })
      .catch((err) => {
        notificationService.errorNotification("Error! Please try again");
      })
      .finally(() => setPublishing(false));
  }

  useEffect(() => {
    if (typeof isPreview != "undefined") {
      if (!isPreview) {
        let scale = parseFloat(((window.innerWidth - 660) / 1920).toFixed(1));
        setInitialPosition({
          x: ((1920 - (window.innerWidth - 660)) * scale) / 2 + 30,
          y: 30,
        });
        setInitialWidth("1920px");
        setInitialScale(scale);
        setBorderRadius(15);
      } else {
        setInitialScale(1);
      }
    }
  }, [isPreview]);

  const handleClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
    setPosition({ x: event.clientX, y: event.clientY });
    setComponentIndex(index);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const closeModalContainer = () => {
    setShowModalContainer(false);
    onSetEditingElement(null);
  };

  return (
    <div
      className={`${styles["playground"]} ${
        isPreview ? styles["preview"] : ""
      }`}
      ref={playGroundRef}
    >
      {showModalContainer ? (
        <div className={styles["modal-container"]}>
          <div
            className={styles["close-container"]}
            onClick={closeModalContainer}
          >
            <span>Close Modal Editor</span>
            <CloseIcon />
          </div>
          {modalDom.map((section, index) => section.render())}
        </div>
      ) : (
        ""
      )}
      {initialScale && (
        <TransformWrapper
          disabled={isPreview}
          initialPositionX={initialPosition.x}
          initialPositionY={initialPosition.y}
          initialScale={initialScale}
          wheel={{ step: 0.02 }}
          minScale={0.2}
        >
          <TransformComponent>
            <div
              style={{
                position: "relative",
                borderRadius: borderRadius,
                minWidth: initialWidth,
                overflow: "hidden",
                boxShadow: "0px 0px 15px rgba(0,0,0,.4)",
              }}
            >
              <div className={styles["content"]}>
                {pageDom.map((section, index) => (
                  <div
                    key={`section-${Math.floor(Math.random() * 100000000000)}`}
                    className={styles["section"]}
                  >
                    <div className={styles["element"]}>
                      {section.render()}

                      <div className={styles["menu"]}>
                        <div className={styles["group"]}>
                          <Tooltip arrow title="Move down">
                            <div
                              className={styles["button"]}
                              onClick={(e) => {
                                e.stopPropagation();
                                page.move(index, 1);
                              }}
                            >
                              <KeyboardArrowDownIcon />
                            </div>
                          </Tooltip>
                          <Tooltip arrow title="Move up">
                            <div
                              className={styles["button"]}
                              onClick={(e) => {
                                e.stopPropagation();
                                page.move(index, -1);
                              }}
                            >
                              <KeyboardArrowUpIcon />
                            </div>
                          </Tooltip>
                        </div>
                        <div className={styles["group"]}>
                          <Tooltip arrow title="Delete">
                            <div
                              className={styles["button"]}
                              onClick={(e) => {
                                e.stopPropagation();
                                page.deleteComponent(index);
                              }}
                            >
                              <DeleteForeverIcon />
                            </div>
                          </Tooltip>
                          <Tooltip arrow title="Clone">
                            <div
                              className={styles["button"]}
                              onClick={(e) => {
                                handleClick(e, index);
                                e.stopPropagation();
                              }}
                            >
                              <ContentCopyIcon />
                            </div>
                          </Tooltip>
                        </div>
                        <div className={styles["group"]}>
                          <Tooltip arrow title="Edit content ">
                            <div
                              className={styles["button"]}
                              onClick={() => {
                                if (isPreview) return;
                                onSetEditingElement(section, "Content");
                                onSetEditingCSS({ index, component: section });
                              }}
                            >
                              <NotesIcon />
                              <span>Content</span>
                            </div>
                          </Tooltip>
                          <Tooltip arrow title="Edit design ">
                            <div
                              className={styles["button"]}
                              onClick={() => {
                                if (isPreview) return;
                                onSetEditingElement(section, "Design");
                                onSetEditingCSS({ index, component: section });
                              }}
                            >
                              <BrushIcon />
                              <span>Design</span>
                            </div>
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                <Menu
                  style={{
                    position: "absolute",
                    top: position.y + "px",
                    left: position.x - 50 + "px",
                  }}
                  open={open}
                  onClose={handleClose}
                  anchorEl={undefined}
                  sx={{
                    ".MuiPopover-paper": {
                      position: "absolute",
                      top: "0 !important",
                      left: "0 !important",
                      scale: initialScale
                    },
                    ul:{
                      padding: "0 !important",
                    }
                  }}
                >
                  {pages &&
                    pages.map((p: Page, index) => (
                      <MenuItem
                        key={`${p.meta_tags.title}-${index}`}
                        onClick={(e: any) => {
                          handleClose();
                          e.stopPropagation();
                          p.cloneComponent(page.getPage()[componentIndex]);
                          p.savePage();
                        }}
                      >
                        {p.meta_tags.title}
                      </MenuItem>
                    ))}
                </Menu>
              </div>
            </div>
          </TransformComponent>
        </TransformWrapper>
      )}

      <WButton
        variant="contained"
        className={`
          ${buttons["button"]}
          ${buttons["success"]}
          ${styles["publish-button"]} 
          ${(publishing || pageIsChanged) && styles["hide"]}
        `}
        onClick={publishProject}
      >
        Publish Project
      </WButton>
      <WButton
        variant="contained"
        className={`
          ${buttons["button"]}
          ${buttons["warning"]}
          ${styles["save-button"]}
          ${(publishing || pageIsChanged) && styles["active"]}
        `}
      >
        <WCircularProgress size="1.5rem" /> Processing...
      </WButton>
    </div>
  );
}
