import * as React from "react";
import styles from "./under-contruction.module.scss";
import UnderContructionImg from "../../assets/img/under-contruction.png";

function UnderContruction() {
  return (
    <div className={styles["under-contruction"]}>
      <img src={UnderContructionImg} />
    </div>
  );
}

export default UnderContruction;
