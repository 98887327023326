import * as React from "react";
import { useState } from "react";
import {
  IMetaTagFormValues,
  PageMetaTag,
} from "../../components/page-meta-tag/page-meta-tag";
import styles from "./create-page-popover.module.scss";
import { WButton } from "../../components/wrappers/wrapper";

interface iMetaTag {
  firstPage: boolean;
  onSave: (meta: IMetaTagFormValues) => void;
}
export const CreatePagePopover = ({ onSave, firstPage }: iMetaTag) => {
  const [page, setPage] = useState<any>({
    page: "",
    slug: firstPage ? "index" : "",
    values: {
      title: "",
      description: "",
      keywords: "",
      custom: [],
    },
  });

  return (
    <div className={styles["container"]}>
      <PageMetaTag
        defaultExpanded={true}
        expanded={true}
        firstPage={firstPage}
        page={page}
        onChange={(data) => {
          setPage(data);
        }}
      ></PageMetaTag>
      <WButton
        disabled={!page.values.title.length}
        className={`${styles["button"]} ${styles["save-button"]}`}
        onClick={() => onSave(page)}
      >
        <span>Save</span>
      </WButton>
    </div>
  );
};
