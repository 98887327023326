import * as React from "react";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { WLoadingButton, WTextField } from "../../components/wrappers/wrapper";
import { PasswordRecovery } from "../../components/password-recovery/password-recovery";
import OverlayPopup, { TypeOverlayProps } from "../overlay-popup/overlay-popup";
import { Formik, Form, ErrorMessage } from "formik";
import { SocialLoginButton } from "../../components/social-login-button/social-login-button";
import { signIn } from "../../services/Authentication";
export function SignIn({
  setAuthenticationType,
  handleForgotPassword,
  signIn,
  continueWithGoogle,
  loading,
  styles,
}: {
  setAuthenticationType: (type: string) => void;
  handleForgotPassword: (email: string) => void;
  signIn: (data: signIn) => void;
  continueWithGoogle: () => void;
  loading: boolean;
  styles: any;
}) {
  const [passwordRecovery, setPasswordRecovery] = useState<boolean>(false);
  const [overlayContent, setOverlayContent] = useState<TypeOverlayProps>(null);
  const googleIcon =
    "https://storage.googleapis.com/download/storage/v1/b/hq-composer-0b0f0/o/63d297bbe682de002c1fc443?alt=media&timestamp=1674745788573";

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Required"),
    password: Yup.string().min(3, "Min 3 character!").required("Required"),
  });

  useEffect(() => {
    passwordRecovery &&
      setOverlayContent({
        title: "Password Recovery",
        onClose: () => {
          setOverlayContent(null);
          setPasswordRecovery(false);
        },
        content: (
          <PasswordRecovery
            description="Please enter your email address to recover your password."
            onRecover={(email) => {
              handleForgotPassword(email);
            }}
          ></PasswordRecovery>
        ),
        overrideStyle: {
          position: "fixed",
          width: "380px",
          height: "300px",
        },
      });
  }, [passwordRecovery]);

  return (
    <div>
      <h1>Welcome</h1>
      <Formik
        initialValues={{ email: "", password: "" }}
        validationSchema={validationSchema}
        onSubmit={(data) => {
          signIn(data);
        }}
      >
        {({ handleChange }) => (
          <Form>
            <WTextField
              fullWidth
              placeholder="E-mail"
              type="text"
              name="email"
              onChange={handleChange}
              className={styles["input"]}
            />
            <ErrorMessage
              className={styles["error-message"]}
              name="email"
              component={"span"}
            />
            <WTextField
              fullWidth
              placeholder="Password"
              type="password"
              name="password"
              onChange={handleChange}
              className={styles["input"]}
            />
            <ErrorMessage
              className={styles["error-message"]}
              name="password"
              component={"span"}
            />
            <WLoadingButton
              fullWidth
              variant="contained"
              className={styles["button"]}
              type={"submit"}
              loading={loading}
            >
              Sign in
            </WLoadingButton>
          </Form>
        )}
      </Formik>

      <div className={styles["forgetten-password-and-link"]}>
        <p
          onClick={() => {
            !loading && setPasswordRecovery(true);
          }}
          className={styles["forgotten-password"]}
        >
          Forgotten password?
        </p>
        <span
          onClick={() => {
            !loading && setAuthenticationType("sign-up");
          }}
        >
          Sign up
        </span>
      </div>
      <SocialLoginButton
        icon={googleIcon}
        text="continue with google"
        handler={() => {
          !loading && continueWithGoogle();
        }}
      />
      <OverlayPopup
        title={overlayContent?.title}
        onClose={() => {
          overlayContent?.onClose();
          setTimeout(() => setOverlayContent(null), 250);
          setPasswordRecovery(false);
        }}
        overrideStyle={overlayContent?.overrideStyle}
      >
        {overlayContent?.content}
      </OverlayPopup>
    </div>
  );
}
