import axios from "axios";
// This statement has been deleted.
;
// This statement has been deleted.
;
// This statement has been deleted.
;
// This statement has been deleted.
;
// use it carefully since its not free account token
// const OPENAI_API_KEY = "Bearer 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";
/**
 * @typedef ContentGenerateData
 * @type {Object}
 * @property {{completion:string, value:string}[]} completions - Completion
 * @property {string} definition - Definition
 */
/**
 * @typedef ContentGenerateDataRequestConfig
 * @type {Object}
 * @property {ContentGenerateData} data - body of the request
 */
/**
 * @param {ContentGenerateDataRequestConfig & Object<string,any>} config
 * @returns {Promise<any>}
 */
export function contentGenerate(config) {
    config = { ...config, method: "post", url: "https://composer-0b0f0.hq.spicaengine.com/api/fn-execute/contentGenerate" };
    axiosWriteValidator(config);
    axiosReadValidator(config);
    return axios.request(config).then(r => r.data);
}
export function generateSchemes(config) {
    config = { ...config, method: "post", url: "https://composer-0b0f0.hq.spicaengine.com/api/fn-execute/generate-schemes" };
    axiosWriteValidator(config);
    axiosReadValidator(config);
    return axios.request(config).then(r => r.data);
}
// This statement has been deleted.
;
// This statement has been deleted.
;
// This statement has been deleted.
;
// This statement has been deleted.
;
// This statement has been deleted.
;
// This statement has been deleted.
;
function axiosWriteValidator(config) {
    if (["post", "put", "patch"].includes(config.method) && !config.data) {
        console.warn("Sending empty request body for post, put, patch requests is unusual. If it's not intented, please use config.data or update your spica function.");
    }
}
function axiosReadValidator(config) {
    if (["get", "delete", "trace", "options", "head"].includes(config.method) && config.data) {
        console.warn("Sending request body for get, delete, trace, options, head requests is unusual. If it's not intented, please remove config.data or update your spica function.");
    }
}
