import * as React from "react";
import { useEffect, useState } from "react";
import styles from "./inputs.module.scss";

import MenuItem from "@mui/material/MenuItem";
import OverlayPopup, {
  TypeOverlayProps,
} from "../../../prefabs/overlay-popup/overlay-popup";
import { WButton, WTextField } from "../../wrappers/wrapper";
import { Layers, Link } from "@mui/icons-material";
import { IconMenuItem, NestedMenuItem } from "mui-nested-menu";
import { Menu } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import { buttons } from "../../../styles/theme.module";
import ModalPicker from "../../../prefabs/modal-picker/modal-picker";
import { editor } from "../../../pages/project/editor/editor";

function InputPage({ value, label, onChange }: any) {
  const [inputValue, setInputValue] = useState(value);
  const [addState, setAddState] = useState<boolean>(false);
  const [forceClose, setForceClose] = useState<boolean>(false);
  const [showURLPopover, setShowURLPopover] = useState<boolean>(false);
  const [showModalPopover, setShowModalPopover] = useState<boolean>(false);
  const [overlayContent, setOverlayContent] = useState<TypeOverlayProps>(null);
  const [externalUrl, setExternalUrl] = useState<string>(value);

  useEffect(() => {
    setDefaultExternal();
    setInputValue(value);
  }, [value]);

  const setDefaultExternal = () => {
    if (!value) return;
    let _externalUrl = "";
    const isEqual = editor.getPages().some((page) => page.slug == value);
    if (!isEqual) {
      _externalUrl = value;
    }
    setExternalUrl(_externalUrl);
  };

  const handleClose = () => {
    setDefaultExternal();
    setForceClose(false);
    setShowURLPopover(false);
    setShowModalPopover(false);
  };

  useEffect(() => {
    showURLPopover &&
      setOverlayContent({
        title: "External URL",
        onClose: handleClose,
        content: (
          <div className={styles["external-url-content"]}>
            <WTextField
              fullWidth
              placeholder="External URL"
              autoComplete={"off"}
              value={externalUrl}
              onChange={(_: any) => {
                setExternalUrl(_.target.value);
              }}
            />
            <WButton
              disabled={!externalUrl}
              variant="contained"
              onClick={() => {
                onChange(externalUrl);
                setShowURLPopover(false);
                setForceClose(true);
              }}
            >
              Add URL
            </WButton>
          </div>
        ),
        overrideStyle: {
          position: "fixed",
          width: "340px",
          height: "210px",
        },
      });

    showModalPopover &&
      setOverlayContent({
        title: "Modal Picker",
        onClose: handleClose,
        content: (
          <div>
            <ModalPicker
              addState={addState}
              setAddState={(value) => setAddState(value)}
              components={Array.from(
                editor.getModals(),
                (el) => el.getModal()[0]
              )}
              pickedComponent={(index) => {
                onChange(editor.getModal(index).name);
                setShowModalPopover(false);
                setForceClose(true);
              }}
            ></ModalPicker>
          </div>
        ),
        overrideStyle: {
          position: "fixed",
          width: "760px",
          height: "570px",
        },
      });
  }, [showURLPopover, showModalPopover, externalUrl]);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleMenuClick = (e: any) => setAnchorEl(e.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);

  return (
    <div className={styles["string"]}>
      <div className={styles["label"]}>{label}</div>
      {inputValue ? (
        <div className={styles["link-value-container"]}>
          <span>{inputValue}</span>
          <div>
            <DeleteIcon
              onClick={() => {
                onChange("");
              }}
            />
          </div>
        </div>
      ) : (
        <WButton
          className={`${buttons["button"]} ${buttons["primary"]} ${styles["button"]}`}
          onClick={handleMenuClick}
        >
          Set Link
        </WButton>
      )}

      <Menu
        className={styles["menu"]}
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
      >
        <NestedMenuItem label="Internal" parentMenuOpen={open}>
          {editor.getPages().map((page, index) => (
            <MenuItem
              onClick={() => {
                handleMenuClose();
                setInputValue(page.slug);
                onChange(page.slug);
              }}
              key={`menuItem-${index}`}
            >
              {page.meta_tags.title}
            </MenuItem>
          ))}
        </NestedMenuItem>
        <IconMenuItem
          onClick={() => {
            setShowURLPopover(true);
            handleMenuClose();
          }}
          rightIcon={<Link />}
          label="External"
        />
        <IconMenuItem
          onClick={() => {
            setShowModalPopover(true);
            handleMenuClose();
          }}
          rightIcon={<Layers />}
          label="Modal"
        />
      </Menu>

      <OverlayPopup
        title={overlayContent?.title}
        onClose={overlayContent?.onClose}
        overrideStyle={overlayContent?.overrideStyle}
        forceClose={forceClose}
      >
        {overlayContent?.content}
      </OverlayPopup>
    </div>
  );
}
export default InputPage;
