import * as React from "react";
import { useEffect, useState } from "react";
import { WMenuItem, WSelect } from "../../../components/wrappers/wrapper";
import { InputWrapper } from "./input-wrapper";
import styles from "./input.module.scss";

export const Select = ({ property, value, items, onChange }: any) => {
  const [inputValue, setInputValue] = useState<any>("");

  useEffect(() => {
    if (!value) setInputValue("");
    else setInputValue(value);
  }, [value]);

  useEffect(() => {
    onChange({ property, value: inputValue });
  }, [inputValue]);

  return (
    <InputWrapper property={property}>
      <WSelect
        size={"small"}
        className={styles["text-filed"]}
        defaultValue={""}
        displayEmpty
        value={inputValue}
        onChange={(_: any) => {
          setInputValue(_.target.value);
        }}
      >
        {items.map((item: any) => (
          <WMenuItem key={`select-${Math.random()}`} value={item}>
            {item}
          </WMenuItem>
        ))}
      </WSelect>
    </InputWrapper>
  );
};
