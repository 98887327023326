import axios from "axios";
// This statement has been deleted.
;
// This statement has been deleted.
;
// This statement has been deleted.
;
// This statement has been deleted.
;
// This statement has been deleted.
;
// This statement has been deleted.
;
// This statement has been deleted.
;
// This statement has been deleted.
;
// This statement has been deleted.
;
// This statement has been deleted.
;
// This statement has been deleted.
;
// This statement has been deleted.
;
// This statement has been deleted.
;
/**
 * @typedef PublishProjectData
 * @type {Object}
 * @property {string} authorization - Authorization token. Only identity tokens are allowed!
 * @property {string} id - Id of the project for which the publish request is being sent
 */
/**
 * @typedef RequestConfig
 * @type {Object}
 * @property {PublishProjectData} data - body of the request
 */
/**
 * @param {RequestConfig & Object<string,any>} config
 * @returns {Promise<any>}
 */
export function publishProject(config) {
    config = { ...config, method: "post", url: "https://composer-0b0f0.hq.spicaengine.com/api/fn-execute/publish-project" };
    axiosWriteValidator(config);
    axiosReadValidator(config);
    return axios.request(config).then(r => r.data);
}
// This statement has been deleted.
;
// This statement has been deleted.
;
/**
 * @returns {Promise<any>}
 */
export function addDomain(config) {
    config = { ...config, method: "post", url: "https://composer-0b0f0.hq.spicaengine.com/api/fn-execute/domain/add" };
    axiosWriteValidator(config);
    axiosReadValidator(config);
    return axios.request(config).then(r => r.data);
}
/**
 * @returns {Promise<any>}
 */
export function verifyDomain(config) {
    config = { ...config, method: "post", url: "https://composer-0b0f0.hq.spicaengine.com/api/fn-execute/domain/verify" };
    axiosWriteValidator(config);
    axiosReadValidator(config);
    return axios.request(config).then(r => r.data);
}
/**
 * @returns {Promise<any>}
 */
export function removeDomain(config) {
    config = { ...config, method: "post", url: "https://composer-0b0f0.hq.spicaengine.com/api/fn-execute/domain/remove" };
    axiosWriteValidator(config);
    axiosReadValidator(config);
    return axios.request(config).then(r => r.data);
}
// This statement has been deleted.
;
// This statement has been deleted.
;
// This statement has been deleted.
;
export function insertForm(config) {
    config = { ...config, method: "post", url: "https://composer-0b0f0.hq.spicaengine.com/api/fn-execute/project/insert-form" };
    axiosWriteValidator(config);
    axiosReadValidator(config);
    return axios.request(config).then(r => r.data);
}
function axiosWriteValidator(config) {
    if (["post", "put", "patch"].includes(config.method) && !config.data) {
        console.warn("Sending empty request body for post, put, patch requests is unusual. If it's not intented, please use config.data or update your spica function.");
    }
}
function axiosReadValidator(config) {
    if (["get", "delete", "trace", "options", "head"].includes(config.method) && config.data) {
        console.warn("Sending request body for get, delete, trace, options, head requests is unusual. If it's not intented, please remove config.data or update your spica function.");
    }
}
