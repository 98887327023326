import * as React from "react";
import styles from "./project-card.module.scss";
import SettingsIcon from "@mui/icons-material/Settings";
import LaunchIcon from "@mui/icons-material/Launch";
import { useEffect, useState } from "react";

export function ProjectCard({
  setActiveProject,
  navigateSettings,
  unreadedForms,
  project,
}: any) {
  const projectPlaceholderImage = "https://picsum.photos/536/354";
  const [unreadedFormsCount, setUnreadedFormsCount] = useState<number>(0);

  useEffect(() => {
    (unreadedForms as Promise<number>).then(res =>
      setUnreadedFormsCount(res)
    );
  }, [unreadedForms]);
  return (
    <div className={styles["project"]} onClick={setActiveProject}>
      <img src={projectPlaceholderImage} />
      <div className={styles["info"]}>
        <div className={styles["top"]}>
          <span>{project.name}</span>
          <SettingsIcon
            className={styles["settings-button"]}
            onClick={($event) => {
              $event.stopPropagation();
              setActiveProject();
              navigateSettings();
            }}
          />
        </div>
        <div className={styles["row"]}>
          <span>
            {project.domains?.length ? (
              <>
                {project.domains?.length && project.domains[0]?.used}
                <LaunchIcon />
              </>
            ) : (
              "---"
            )}
          </span>
          <p>
            {project.domains?.length - 1 > 0 &&
              `( ${project.domains?.length - 1} more URLs )`}
          </p>
        </div>
        <span>{unreadedFormsCount} New Forms</span>
      </div>
    </div>
  );
}
