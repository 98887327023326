import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { Navigate, Outlet, useParams } from "react-router-dom";
import { ILoadingContext, LoadingContext } from "../../contexts/loading";
import { ProjectContext } from "../../contexts/project";
import { IUserContext, UserContext } from "../../contexts/user";
import useRequestTracker from "../../custom-hooks/request-tracker";
import { Authentication } from "../../services/Authentication";
import { UserService } from "../../services/User";

export function AuthGuard() {
  const authentication = new Authentication();
  const userService = new UserService();

  const userContext = useContext<IUserContext>(UserContext);
  const projectContext = useContext(ProjectContext);
  const loadingContext = useContext<ILoadingContext>(LoadingContext);

  useRequestTracker();

  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const token = localStorage.getItem("token");
  const { projectId } = useParams();
  useEffect(() => {
    if (!token) {
      setLoading(false);
      return;
    }
    loadingContext.setLoading(true);
    authentication
      .verifyToken()
      .then(async (res: any) => {
        const [user] = await userService.getUserByIdentifier(res.identifier);
        userContext.setUser(user);
        setIsAuthenticated(true);
      })
      .catch((_) => {
        localStorage.clear();
        setIsAuthenticated(false);
      })
      .finally(() => {
        setLoading(false);
        loadingContext.setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (projectContext.projects && !projectContext.project && projectId)
      projectContext.setProject(
        projectContext.projects.find((item) => item._id == projectId)
      );
  }, [projectContext.projects]);

  return loading ? (
    <></>
  ) : isAuthenticated ? (
    <Outlet />
  ) : (
    <Navigate to="/authentication" replace />
  );
}
