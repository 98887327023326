import { AxiosError } from "axios";
export class HelperService {
  private slugValidationMessages = {
    duplicated: "There is a page with the same slug",
    patternErr: "The slug must consist of letters, numbers and dashes",
    slugAndTitleRequired: "Title and slug are required",
    indexNotExists: "The slug of any page must be \"index\""
  }

  getErrorMessage(error: AxiosError) {
    const response = error.response;
    const data: any = response.data;

    if (!data || response.status == 500) {
      return "Something went wrong";
    }

    if (data.err) {
      return data.err.message;
    } else if (data.message) {
      return data.message;
    } else {
      return "Something went wrong";
    }
  }

  getFormattedDate(date: Date, format: string): string {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    const tokens: { [key: string]: number | string } = {
      YYYY: year,
      MM: month,
      DD: day,
      HH: hours,
      mm: minutes,
      ss: seconds,
    };

    return format.replace(/YYYY|MM|DD|HH|mm|ss/g, (match) =>
      String(tokens[match])
    );
  }

  isSlugValidCheckByPage(slug: string, pages: any) {
    let valid = true;
    let message = "";

    let isDuplicate = pages.some(
      (el: any) => el.slug.toLowerCase() == slug.toLowerCase()
    );
    if (isDuplicate) {
      valid = false;
      message = this.slugValidationMessages.duplicated;
    }
    if (!this.isSlugPatterValid(slug)) {
      valid = false;
      message = this.slugValidationMessages.patternErr;
    }
    return { valid, message }
  }

  isSlugValidCheckByForm(form: any) {
    let valid = true;
    let message = "";

    form.forEach((item: any) => {
      if (!item.slug.trim() || !item.values.title.trim()) {
        valid = false;
        message = this.slugValidationMessages.slugAndTitleRequired;
      }
      if (!this.isSlugPatterValid(item.slug.trim())) {
        valid = false;
        message = this.slugValidationMessages.patternErr;
      }
    })

    if (!form.some((el: any) => el.slug == "index")) {
      valid = false;
      message = this.slugValidationMessages.indexNotExists;
    }

    const valueArr = form.map((item: any) => item.slug.toLowerCase());
    if (valueArr.some((item: any, idx: number) => valueArr.indexOf(item) != idx)) {
      valid = false;
      message = this.slugValidationMessages.duplicated;
    }

    return { valid, message }
  }

  private isSlugPatterValid(slug: string) {
    let pattern = /^[A-Za-z0-9\-]*$/;
    return pattern.test(slug.trim())
  }

  getRandomRGBColor(alfa?: number) {
    const r = Math.floor(Math.random() * 256);
    const g = Math.floor(Math.random() * 256);
    const b = Math.floor(Math.random() * 256);

    if (alfa) return `rgb(${r}, ${g}, ${b}, ${alfa})`;
    return `rgb(${r}, ${g}, ${b})`;
  }

}

export const helperService = new HelperService();
