
import * as React from "react";
import styles from "./section-card.module.scss";
export function SectionCard({children}: any){
    return (
        <div className={styles["container"]}>
            {children}
        </div>
    )
}

export function Title({children}: any){

    return (
        <div className={styles["title"]}>
            {children}
        </div>
    )
}


export function Content({children}: any){
    return (
        <div className={styles["content"]}>
            {children}
        </div>
    )
}

export function Column({children}: any){
    return (
        <div className={styles["column"]}>
            {children}
        </div>
    )
}

export function Header({children}: any){
    return (
        <div className={styles["header"]}>
            {children}
        </div>
    )
}

export function Explanation({children}: any){
    return (
        <div className={styles["explanation"]}>
            {children}
        </div>
    )
}

