import * as React from "react";
import styles from "./theme.module.scss";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import FontFamilySelection from "../font-family-selection/font-family-selection";

import { useState, useEffect } from "react";
import { TypeFontFamily } from "../../classes/Editor";
import { WTextField } from "../../components/wrappers/wrapper";

export const themes = [
  { primary: "#efe6d5", secondary: "#9dbeb7", tertiary: "#e73213" },
  { primary: "#018558", secondary: "#bde902", tertiary: "#fef031" },
  { primary: "#0c0e0c", secondary: "#d8323c", tertiary: "#eaebea" },
  { primary: "#d7eaf3", secondary: "#77b5d9", tertiary: "#14397d" },
  { primary: "#ff1700", secondary: "#ffa600", tertiary: "#4d6910" },
  { primary: "#a0fefb", secondary: "#494fc1", tertiary: "#fd084a" },
  { primary: "#b72818", secondary: "#bc6022", tertiary: "#e8c599" },
  { primary: "#fde4e3", secondary: "#f282b4", tertiary: "#ef415e" },
];

function Theme({
  environments,
  defaultTheme,
  onChanged,
  onPageChange,
  project,
  onSetProject,
  editor,
}: any) {
  const [colorTheme, setColorTheme] = useState(defaultTheme);

  let [newEnvironments, setNewEnvironments] = useState(environments);
  const [themeColorExpandVisible, setThemeColorExpandVisible] = useState(false);
  const [fontFamilyExpandVisible, setFontFamilyExpandVisible] = useState(false);

  useEffect(() => {
    onChanged(colorTheme, newEnvironments);
  }, [newEnvironments]);

  function changeColor(colorKey: string, colorValue: string) {
    colorTheme[colorKey] = colorValue;
    setColorTheme({ ...colorTheme });
    onChanged(colorTheme, newEnvironments);
  }

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let value: number | string = e.target.value;
    const type = e.target.type;
    value = type === "number" ? Number(value) : value;

    newEnvironments = { ...newEnvironments, [e.target.name]: value };
    setNewEnvironments({ ...newEnvironments });
    onChanged(colorTheme, newEnvironments);
  };

  const setContentWidth = (value: string) => {
    setNewEnvironments({
      ...newEnvironments,
      content_width: { ...newEnvironments.content_width, width: Number(value) },
    });
  };

  const onFullWidthCheckboxChanged = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    newEnvironments.content_width.full_width = e.target.checked;
    if (e.target.checked) newEnvironments.content_width.width = 1920;
    setNewEnvironments({ ...newEnvironments });
  };

  const checkWidthValue = () => {
    if (newEnvironments.content_width.width < 960) setContentWidth("960");
  };

  return (
    <div className={styles["container"]}>
      <div className={styles["heading"]}>
        <span>Theme</span>
      </div>

      <div className={styles["theme-settings"]}>
        <div className={styles["row"]}>
          <span>Current color theme:</span>
          <div className={styles["color-set"]}>
            <input
              type="color"
              value={colorTheme.primary}
              onChange={(e) => changeColor("primary", e.target.value)}
            />
            <input
              type="color"
              value={colorTheme.secondary}
              onChange={(e) => changeColor("secondary", e.target.value)}
            />
            <input
              type="color"
              value={colorTheme.tertiary}
              onChange={(e) => changeColor("tertiary", e.target.value)}
            />
            {!themeColorExpandVisible ? (
              <ExpandMoreIcon
                onClick={() =>
                  setThemeColorExpandVisible(!themeColorExpandVisible)
                }
              />
            ) : (
              <ExpandLessIcon
                onClick={() =>
                  setThemeColorExpandVisible(!themeColorExpandVisible)
                }
              />
            )}
          </div>
        </div>
        <div
          className={
            styles["expand"] +
            " " +
            (!themeColorExpandVisible && styles["invisible"])
          }
        >
          <div className={styles["themes"]}>
            {themes.map((theme, index) => (
              <div
                key={index}
                className={styles["theme"]}
                onClick={() => {
                  setColorTheme(theme);
                  onChanged(theme, newEnvironments);
                }}
              >
                <div
                  className={styles["theme-color"]}
                  style={{ background: theme.primary }}
                />
                <div
                  className={styles["theme-color"]}
                  style={{ background: theme.secondary }}
                />
                <div
                  className={styles["theme-color"]}
                  style={{ background: theme.tertiary }}
                />
              </div>
            ))}
          </div>
        </div>
        <div className={styles["row"]}>
          <span>Background color</span>
          <input
            name="html_background"
            onChange={handleChange}
            type="color"
            value={newEnvironments.html_background}
          />
        </div>
        <div className={styles["row"]}>
          <span>Content width (px)</span>
          <div className={styles["content-width"]}>
            <input
              checked={newEnvironments.content_width.full_width}
              onChange={(e) => onFullWidthCheckboxChanged(e)}
              type="checkbox"
              id="fullwidth"
            />
            <label htmlFor="fullwidth"> Full Width </label>
            <div className={styles["content-width-wrapper"]}>
              <WTextField
                type="number"
                size="small"
                onBlur={checkWidthValue}
                value={
                  !newEnvironments.content_width.full_width &&
                  newEnvironments.content_width.width
                }
                onChange={(e) => setContentWidth(e.target.value)}
                disabled={newEnvironments.content_width.full_width}
              />
            </div>
          </div>
        </div>
        <div className={styles["row"]}>
          <span>Font family</span>
          <div
            className={styles["font-family"]}
            onClick={() => setFontFamilyExpandVisible(!fontFamilyExpandVisible)}
          >
            {project.theme_config.fonts.family}
            {!fontFamilyExpandVisible ? (
              <ExpandMoreIcon
                onClick={() =>
                  setFontFamilyExpandVisible(!fontFamilyExpandVisible)
                }
              />
            ) : (
              <ExpandLessIcon />
            )}
          </div>
        </div>
        <div
          className={
            styles["expand"] +
            " " +
            (!fontFamilyExpandVisible && styles["invisible"])
          }
        >
          <FontFamilySelection
            defaultValue={project.theme_config.fonts}
            onChange={(fontFamily: TypeFontFamily) => {
              project.theme_config.fonts = fontFamily;
              onSetProject({ ...project });
              editor.changeThemeFonts(fontFamily);
            }}
          ></FontFamilySelection>
        </div>
      </div>
    </div>
  );
}

export default Theme;
