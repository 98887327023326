import * as React from "react";
import { useRef, useEffect, useState } from "react";
import styles from "./component-representer.module.scss";

function ComponentRepresenter({ preview, clicked }: any) {
  const [scale, setScale] = useState(0);
  const [previewHeight, setPreviewHeight] = useState(0);

  useEffect(() => {
      let height = ref.current.clientHeight;
      let width = ref.current.clientWidth;
      let scale = previewRef.current.clientWidth / width;
      setPreviewHeight(scale * height);
      setScale(scale);
  }, []);

  const ref = useRef(null);
  const previewRef = useRef(null);
  return (
    <div
      className={styles["container"]}
      onClick={() => {
        clicked();
      }}
    >
      <div ref={previewRef} className={styles["preview"]} style={{height: previewHeight + "px"}}>
        <div style={{transform: `scale(${scale})`}}>
          <div ref={ref}>{preview}</div>
        </div>
      </div>
    </div>
  );
}

export default ComponentRepresenter;
