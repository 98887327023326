import {
  Pages,
  pages,
  initialize,
  projects,
  Projects,
  user,
  User,
  Forms,
  forms,
  Modals,
  modals,
  CSS_Classes,
  css_classes,
  Webhooks,
  webhooks
} from "./bucket";
import { Modal } from "./Modal";
import { Page } from "./Page";

export interface iDatabase {
  addPage(user_id: string, project_id: string, page: Page): Promise<Pages>;
  addModal(user_id: string, project_id: string, modal: Modal): Promise<Modals>;
  removePage(page: Page): Promise<any>;
  removeModal(modal: Modal): Promise<any>;
  updatePage(page: Page): Promise<any>;
  updateModal(modal: Modal): Promise<any>;
  updateProject(project: Projects): Promise<Projects>;
  attachPageToProject(project_id: string, page_id: string): Promise<Projects>;
  attachModalToProject(project_id: string, modal_id: string): Promise<Modals>;
  getProject(project_id: string): Promise<Projects>;
  getUserByIdentifier(id: string): Promise<User[]>;
  updateUser(id: string, user_data: User): Promise<User>;
  addCssClass(class_name: string, user: string, project: string): Promise<CSS_Classes>;
  updateCssClass(css_class_id: string, css: string): Promise<CSS_Classes>;
  updateForm(id: string, form_data: Forms): Promise<Forms>;
  removeForm(id: string): Promise<Forms>;
  getSystemCssClasses():  Promise<any>;
}

export class Database implements iDatabase {
  private _mocked = false;
  constructor(url: string, token: string) {
    initialize({ identity: token });
  }

  async addPage(
    user_id: string,
    project_id: string,
    page: Page
  ): Promise<Pages> {
    if (this._mocked) return null;

    let createdPage: Pages = await pages.insert({
      name: page.name,
      slug: page.slug,
      localization: page.localization.map((locale) => {
        return {
          language: locale.language,
          json: JSON.stringify(locale.builder.getPage()),
        };
      }),
      user: user_id,
      meta_tags: page.meta_tags,
      custom_script: page.custom_script,
    });

    this.attachPageToProject(project_id, createdPage._id);
    return createdPage;
  }

  async addModal(
    user_id: string,
    project_id: string,
    modal: Modal
  ): Promise<Modals> {
    if (this._mocked) return null;

    let createdModal: Pages = await modals.insert({
      name: modal.name,
      localization: modal.localization.map((locale) => {
        return {
          language: locale.language,
          modal_json: JSON.stringify(locale.builder.getModal()),
        };
      }),
      user: user_id,
    });

    this.attachModalToProject(project_id, createdModal._id);
    return createdModal;
  }

  async removePage(page: Page): Promise<any> {
    if (this._mocked) return null;
    return await pages.remove(page.id);
  }

  async removeModal(modal: Modal): Promise<any> {
    if (this._mocked) return null;
    return await modals.remove(modal.id);
  }

  async updatePage(page: Page) {
    if (this._mocked) return;

    await pages.patch({
      _id: page.id,
      slug: page.slug,
      localization: page.localization.map((localization) => {
        let json = JSON.stringify(
          localization.builder.getPage().map((component) => {
            return {
              type: component.getName(),
              props: component.getProps(),
              cssClasses: component.getCSSClasses(),
              children: "",
            };
          })
        );
        return { language: localization.language, json: json };
      }),
      meta_tags: page.meta_tags,
      custom_script: page.custom_script,
    });
  }

  async updateModal(modal: Modal) {
    if (this._mocked) return;

    await modals.patch({
      _id: modal.id,
      localization: modal.localization.map((localization) => {
        let modal_json = JSON.stringify(
          localization.builder.getModal().map((component) => {
            return {
              type: component.getName(),
              props: component.getProps(),
              cssClasses: component.getCSSClasses(),
              children: "",
            };
          })
        );
        return { language: localization.language, modal_json };
      }),
    });
  }

  async attachPageToProject(
    project_id: string,
    page_id: string
  ): Promise<Projects> {
    const project = await projects.get(project_id);
    project.pages = project.pages || [];
    project.pages.push(page_id);
    return projects.patch(project);
  }

  async attachModalToProject(
    project_id: string,
    modal_id: string
  ): Promise<Projects> {
    const project = await projects.get(project_id);
    project.modals = project.modals || [];
    project.modals.push(modal_id);
    return projects.patch(project);
  }

  getProjects() {
    return projects.getAll({ queryParams: { filter: { status: "active" }, relation: true } });
  }
  getProject(project_id: string): Promise<Projects> {
    return projects.get(project_id, { queryParams: { relation: true } });
  }

  updateProject(project: Projects): Promise<Projects> {
    return projects.update({ _id: project._id, ...project });
  }
  patchProject(project: Partial<Projects>): Promise<Projects> {
    return projects.patch({ _id: project._id, ...project });
  }

  addProject(
    owner_id: string,
    name: string,
    description: string,
    features: string[],
    autogeneration: boolean = false
  ) {
    localStorage.setItem("language", "en");
    const pages: { _id: string & Pages }[] = [];
    const modals: { _id: string & Modals }[] = [];
    const newProject: Projects = {
      owner: owner_id,
      name,
      description,
      features,
      pages,
      modals,
      theme_config: {
        colors: {
          primary: "white",
          secondary: "blue",
          tertiary: "black",
        },
        fonts: {
          family: "Roboto",
        },
        environments: {
          html_background: "#ffffff",
          border_radius: 6,
          content_width: { full_width: true, width: 1920 },
          box_shadow: {
            horizontal_length: 0,
            blur_radius: 8,
            color: "#000000",
            opacity: 20,
            spread_radius: 0,
            vertical_length: 2,
          },
        },
      },
      current_language: "en",
      languages: ["en"],
    };
    return projects.insert(newProject);
  }

  deleteProject(id: string) {
    return projects.patch({_id: id, status: "terminated"});
  }

  getUserByIdentifier(identifier: string): Promise<User[]> {
    return user.getAll({ queryParams: { filter: { identifier } } }) as any;
  }

  updateUser(id: string, user_data: User) {
    return user.patch({ _id: id, ...user_data });
  }

  getForms(project_id: string, skip?: number, limit?: number): Promise<Forms[]> {
    return forms.getAll({
      queryParams: { filter: { project: project_id }, skip, limit, sort: {_id: -1} },
    }) as any;
  }

  getUnreadedForms(project_id: string): Promise<Forms[]> {
    return forms.getAll({
      queryParams: { filter: { project: project_id, seen: false }},
    }) as any;
  }

  updateForm(id: string, form_data: Forms){
    return forms.patch({_id: id, ...form_data })
  }

  removeForm(id: string){
    return forms.remove(id);
  }

  getWebhooks(project_id: string): Promise<Webhooks[]> {
    return webhooks.getAll({
      queryParams: { filter: { project: project_id }},
    }) as any;
  }

  removeWebhooks(id: string){
    return webhooks.remove(id);
  }
  
  addWebhooks(
    url: string,
    project: string,
    is_active: boolean,
    action: string,
    target: string,
  ) {
    const newWebhooks: Webhooks = {
      url: url,
      project: project,
      is_active: is_active,
      action: action as any,
      target: target as any,
    };
    return webhooks.insert(newWebhooks);
  }

  updateWebhooks(
    id: string,
    url: string,
    project: string,
    is_active: boolean,
    action: string,
    target: string,){
    const updateWebhook: Webhooks = {
      url: url,
      project: project,
      is_active: is_active,
      action: action as any,
      target: target as any,
    };
    return webhooks.patch({_id: id, ...updateWebhook })
  }

  async addCssClass(class_name: string, user: string, project: string): Promise<CSS_Classes> {
    if (this._mocked) return null;
    
    return css_classes.insert({class_name, user, project, css: ''})
  }

  async updateCssClass(css_class_id: string, css: string): Promise<Pages> {
    if (this._mocked) return null;
    
    return css_classes.patch({_id: css_class_id, css})
  }

  async getSystemCssClasses(): Promise<any> {
    return css_classes.getAll({queryParams: {filter: {system: true}}})
  }
}
