import * as React from "react";
import * as Yup from "yup";
import { WLoadingButton, WTextField } from "../../components/wrappers/wrapper";
import { SocialLoginButton } from "../../components/social-login-button/social-login-button";
import { ErrorMessage, Form, Formik } from "formik";
import { signUp } from "../../services/Authentication";

export function SignUp({
  setAuthenticationType,
  signUp,
  continueWithGoogle,
  loading,
  styles,
}: {
  setAuthenticationType: (type: string) => void;
  signUp: (data: signUp) => void;
  continueWithGoogle: () => void;
  loading: boolean;
  styles: any;
}) {
  const googleIcon =
    "https://storage.googleapis.com/download/storage/v1/b/hq-composer-0b0f0/o/63d297bbe682de002c1fc443?alt=media&timestamp=1674745788573";
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    email: Yup.string().email("Invalid email").required("Required"),
    password: Yup.string().min(3, "Min 3 character!").required("Required"),
  });
  return (
    <div>
      <h1>Join Us</h1>
      <Formik
        initialValues={{ name: "", email: "", password: "" }}
        validationSchema={validationSchema}
        onSubmit={(data) => {
          signUp(data);
        }}
      >
        {({ handleChange }) => (
          <Form>
            <WTextField
              className={styles["input"]}
              placeholder="Name"
              type="text"
              name="name"
              fullWidth
              onChange={handleChange}
            />
            <ErrorMessage
              component="span"
              className={styles["error-message"]}
              name="name"
            />
            <WTextField
              className={styles["input"]}
              placeholder="E-mail"
              type="text"
              name="email"
              fullWidth
              onChange={handleChange}
            />
            <ErrorMessage
              component="span"
              className={styles["error-message"]}
              name="email"
            />

            <WTextField
              className={styles["input"]}
              placeholder="Password"
              type="password"
              name="password"
              fullWidth
              onChange={handleChange}
            />
            <ErrorMessage
              component="span"
              className={styles["error-message"]}
              name="password"
            />

            <WLoadingButton
              fullWidth
              variant="contained"
              className={styles["button"]}
              type={"submit"}
              loading={loading}
            >
              Sign up
            </WLoadingButton>
          </Form>
        )}
      </Formik>

      <p
        onClick={() => {
          !loading && setAuthenticationType("sign-in");
        }}
        className={styles["link"]}
      >
        Sign in
      </p>
      <SocialLoginButton
        icon={googleIcon}
        text="continue with google"
        handler={() => {
          !loading && continueWithGoogle();
        }}
      />
    </div>
  );
}
