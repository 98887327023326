import * as React from "react";
import { Length } from "./length";
import { Color } from "./color";
import { Select } from "./select";
import styles from "./input.module.scss";
import { useEffect, useState } from "react";
import { WButton } from "../../../components/wrappers/wrapper";
import { buttons } from "../../../styles/theme.module";

export const Borders = ({ value, onChange }: any) => {
  const borderSide = {
    "border-top": "border-top",
    "border-bottom": "border-bottom",
    "border-right": "border-right",
    "border-left": "border-left",
    border: "border",
  };

  const [side, setSide] = useState<string>("0");
  const [properties, setProperties] = useState({ width: "", style: "", color: "" });

  useEffect(() => {
    if (!side) return;
    let temp = {
      property: side,
      value: `${properties.width} ${properties.style} ${properties.color}`,
    };

    if (!temp.value.trim()) return;

    onChange(temp);
  }, [properties]);

  useEffect(() => {
    if (!side) return;

    let _width = "";
    let _style = "";
    let _color = "";

    if (value[side]) {
      const valuesArr = value[side].split(" ");
      _width = valuesArr[0];
      _style = valuesArr[1];
      _color = valuesArr[2];
    }

    setProperties({ width: _width, style: _style, color: _color });
  }, [side]);

  const clearProperties = () => {
    setSide("0");
    onChange({
      property: side,
      value: undefined,
    });
  };

  return (
    <div className={styles["borders-container"]}>
      <div className={styles["box"]}>
        {Object.keys(borderSide).map((el, index) => (
          <div
            key={`border-box-${index}`}
            onClick={() => setSide(el)}
            className={`${styles["border-box"]} ${styles[el]} ${
              side == el && styles["active"]
            }`}
          ></div>
        ))}
      </div>
      
    <div className={`${styles["properties-container"]} ${!Object.keys(borderSide).includes(side) && styles["disabled"]}`}>
        <Length
        property="Width"
        value={properties.width}
        onChange={(_: any) => setProperties({ ...properties, width: _.value })}
        />
        <Select
        property="Style"
        value={properties.style}
        items={[
            "none",
            "hidden",
            "dotted",
            "dashed",
            "solid",
            "double",
            "groove",
            "ridge",
            "inset",
            "outset",
            "initial",
            "inherit",
        ]}
        onChange={(_: any) => setProperties({ ...properties, style: _.value })}
        />
        <Color
        property="Color"
        value={properties.color}
        onChange={(_: any) => setProperties({ ...properties, color: _.value })}
        />
        <WButton fullWidth className={`${styles["button"]} ${buttons["button"]} ${buttons["primary"]}`} onClick={clearProperties}>
        <span>Clear</span>
        </WButton>
    </div>
      
    </div>
  );
};
