import * as React from "react";
import * as all_css_properties from "./all-css-properties.json";
import { Color } from "./components/color";
import { Length } from "./components/length";
import { Select } from "./components/select";
import { Image } from "./components/image";
import { Time } from "./components/time";
import { Label } from "./components/label";
import { Number } from "./components/number";

export const getPropType = (value: string) => {
  let type: string = PROPERTY_TYPE.SELECT;
  const types = ["length", "color", "time", "label", "number"];

  let _continue = true;
  for (let item of types) {
    //@ts-ignore
    if (_continue && all_css_properties[value].syntax.includes(`[${item}`)) {
      type = item;
      _continue = false;
    }
  }
  return type;
};

export const getPropItems = (value: string) => {
  //@ts-ignore
  return all_css_properties[value].values;
};

export enum PROPERTY_CATEGORY {
  TYPOGRAHY = "Typography",
  LAYOUT = "Layout",
  SIZE = "Size",
  MARGIN_PADDING = "Margin & Padding",
  POSITION = "Position",
  BACKGROUND = "Background",
  OTHER = "Other",
  CUSTOM = "Custom",
  BORDERS = "Borders",
}

export enum PROPERTY_TYPE {
  COLOR = "color",
  NUMBER = "number",
  LENGTH = "length",
  SELECT = "select",
  IMAGE = "image",
  TIME = "time",
  LABEL = "label",
}

export const CSS_PROPERTIES = {
  [PROPERTY_CATEGORY.TYPOGRAHY]: {
    "font-family": {
      type: PROPERTY_TYPE.SELECT,
      items: [
        "Arial",
        "Verdana",
        "Tahoma",
        "Trebuchet MS",
        "Times New Roman",
        "Georgia ",
        "Garamond",
        "Courier New",
        "Brush Script MT",
      ],
    },
    "font-weight": {
      type: PROPERTY_TYPE.SELECT,
      items: [
        "normal",
        "bold",
        "bolder",
        "lighter",
        "100",
        "200",
        "300",
        "400",
        "500",
        "600",
        "700",
        "800",
        "900",
        "initial",
        "inherit",
      ],
    },
    "font-size": {
      type: PROPERTY_TYPE.LENGTH,
      items: [""],
    },
    color: {
      type: PROPERTY_TYPE.COLOR,
      items: [""],
    },
    "line-height": {
      type: PROPERTY_TYPE.LENGTH,
      items: [""],
    },
    "letter-spacing": {
      type: PROPERTY_TYPE.LENGTH,
      items: [""],
    },
    "word-spacing": {
      type: PROPERTY_TYPE.LENGTH,
      items: [""],
    },
    "text-align": {
      type: PROPERTY_TYPE.SELECT,
      items: ["left", "right", "center", "justify", "initial", "inherit"],
    },
  },
  [PROPERTY_CATEGORY.LAYOUT]: {
    display: {
      type: PROPERTY_TYPE.SELECT,
      items: [
        "inline",
        "block",
        "contents",
        "flex",
        "grid",
        "inline-block",
        "inline-flex",
        "inline-grid",
        "inline-table",
        "list-item",
        "run-in",
        "table",
        "table-caption",
        "table-column-group",
        "table-header-group",
        "table-footer-group",
        "table-row-group",
        "table-cell",
        "table-column",
        "table-row",
        "none",
        "initial",
        "inherit",
      ],
    },
  },
  [PROPERTY_CATEGORY.SIZE]: {
    width: {
      type: PROPERTY_TYPE.LENGTH,
      items: [""],
    },
    height: {
      type: PROPERTY_TYPE.LENGTH,
      items: [""],
    },
    "max-width": {
      type: PROPERTY_TYPE.LENGTH,
      items: [""],
    },
    "max-height": {
      type: PROPERTY_TYPE.LENGTH,
      items: [""],
    },
    "min-width": {
      type: PROPERTY_TYPE.LENGTH,
      items: [""],
    },
    "min-height": {
      type: PROPERTY_TYPE.LENGTH,
      items: [""],
    },
  },
  [PROPERTY_CATEGORY.MARGIN_PADDING]: {},
  [PROPERTY_CATEGORY.BORDERS]: {},
  [PROPERTY_CATEGORY.BACKGROUND]: {
    "background-image": {
      type: PROPERTY_TYPE.IMAGE,
      items: [""],
    },
    "background-color": {
      type: PROPERTY_TYPE.COLOR,
      items: [""],
    },
    "background-size": {
      type: PROPERTY_TYPE.SELECT,
      items: ["cover", "contain", "initial", "inherit", "unset"],
    },
    "background-repeat": {
      type: PROPERTY_TYPE.SELECT,
      items: [
        "repeat",
        "repeat-x",
        "repeat-y",
        "no-repeat",
        "space",
        "round",
        "initial",
        "inherit",
      ],
    },
  },
  [PROPERTY_CATEGORY.OTHER]: {
    cursor: {
      type: PROPERTY_TYPE.SELECT,
      items: [
        "alias",
        "all-scroll",
        "auto",
        "cell",
        "context-menu",
        "col-resize",
        "copy",
        "crosshair",
        "default",
        "e-resize",
        "ew-resize",
        "grab",
        "grabbing",
        "help",
        "move",
        "n-resize",
        "ne-resize",
        "nesw-resize",
        "ns-resize",
        "nw-resize",
        "nwse-resize",
        "no-drop",
        "none",
        "not-allowed",
        "pointer",
        "progress",
        "row-resize",
        "s-resize",
        "se-resize",
        "sw-resize",
        "text",
        "URL",
        "vertical-text",
        "w-resize",
        "wait",
        "zoom-in",
        "zoom-out",
        "initial",
        "inherit",
      ],
    },
  },
  [PROPERTY_CATEGORY.CUSTOM]: {},
};

export const propertyInputTypes: any = {
  [PROPERTY_TYPE.COLOR]: (props: any) => (
    <Color property={props.property} value={props.value} onChange={props.onChange} />
  ),
  [PROPERTY_TYPE.LENGTH]: (props: any) => (
    <Length property={props.property} value={props.value} onChange={props.onChange} />
  ),
  [PROPERTY_TYPE.SELECT]: (props: any) => (
    <Select
      items={props.items}
      property={props.property}
      value={props.value}
      onChange={props.onChange}
    />
  ),
  [PROPERTY_TYPE.IMAGE]: (props: any) => (
    <Image
      items={props.items}
      property={props.property}
      value={props.value}
      onChange={props.onChange}
    />
  ),
  [PROPERTY_TYPE.TIME]: (props: any) => (
    <Time
      items={props.items}
      property={props.property}
      value={props.value}
      onChange={props.onChange}
    />
  ),
  [PROPERTY_TYPE.LABEL]: (props: any) => (
    <Label
      items={props.items}
      property={props.property}
      value={props.value}
      onChange={props.onChange}
    />
  ),
  [PROPERTY_TYPE.NUMBER]: (props: any) => (
    <Number
      items={props.items}
      property={props.property}
      value={props.value}
      onChange={props.onChange}
    />
  ),
};

export const getDefaultProps = () => {
  let defaultProps: string[] = [
    "margin",
    "margin-bottom",
    "margin-left",
    "margin-right",
    "margin-top",
    "padding",
    "padding-bottom",
    "padding-left",
    "padding-right",
    "padding-top",
    "background",
    "background-color",
    "background-size",
    "background-repeat",
    "border",
    "border-left",
    "border-top",
    "border-right",
    "border-bottom",
    "border-width",
    "border-style",
    "border-color",
    "border-left-width",
    "border-left-style",
    "border-left-color",
    "border-right-width",
    "border-right-style",
    "border-right-color",
    "border-bottom-width",
    "border-bottom-style",
    "border-bottom-color",
    "border-top-width",
    "border-top-style",
    "border-top-color",
  ];

  Object.entries(CSS_PROPERTIES).forEach((cat) => {
    defaultProps = defaultProps.concat(Object.keys(cat[1]));
  });

  return defaultProps;
};
