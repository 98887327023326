import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Page } from "../../classes/Page";
import ButtonNavigator from "../../components/button-navigator/button-navigator";
import styles from "./navigator-pages.module.scss";
import { LeftToolbarButton } from "../../components/left-toolbar-button/left-toolbar-button";
function NavigatorPages({
  pages,
  activePageIndex,
  onPageChanged,
  onAddPage,
  onDeletePage,
}: any) {
  const navigate = useNavigate();

  const setPageTitle = (title: string) => {
    if (!title) return;

    const titleSplit = title.split(" ");
    let titleTemp = titleSplit[0].slice(0, 2);
    if (titleSplit[1]) {
      titleTemp = `${titleSplit[0].charAt(0)}${titleSplit[1].charAt(0)}`;
    }

    return titleTemp.toUpperCase();
  };
  return (
    <div className={styles["container"]}>
      {pages.map((page: Page, index: number) => (
        <div key={index} className={styles["button-container"]}>
          {index == activePageIndex && (
            <div
              className={styles["cancel-button"]}
              onClick={() => {
                onDeletePage(index);
              }}
            >
              x
            </div>
          )}
          <ButtonNavigator
            active={index == activePageIndex}
            onClick={() => {
              onPageChanged(index);
            }}
          >
            <LeftToolbarButton
              text={
                (setPageTitle(page.meta_tags?.title) || index + 1) as string
              }
            ></LeftToolbarButton>
          </ButtonNavigator>
        </div>
      ))}
      <ButtonNavigator
        active={true}
        onClick={() => {
          onAddPage();
        }}
      >
        <LeftToolbarButton icon="add"></LeftToolbarButton>
      </ButtonNavigator>
    </div>
  );
}

export default NavigatorPages;
