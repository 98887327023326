import axios from "axios";
// This statement has been deleted.
;
// This statement has been deleted.
;
// This statement has been deleted.
;
// This statement has been deleted.
;
// This statement has been deleted.
;
// This statement has been deleted.
;
// This statement has been deleted.
;
// This statement has been deleted.
;
/**
 * @returns {Promise<any>}
 */
export function createSubscription(config) {
    config = { ...config, method: "post", url: "https://composer-0b0f0.hq.spicaengine.com/api/fn-execute/stripe/subscription/create" };
    axiosWriteValidator(config);
    axiosReadValidator(config);
    return axios.request(config).then(r => r.data);
}
// This statement has been deleted.
;
// This statement has been deleted.
;
// This statement has been deleted.
;
// This statement has been deleted.
;
// This statement has been deleted.
;
/**
 * @returns {Promise<any>}
 */
export function getPaymentMethod(config) {
    config = { ...config, method: "get", url: "https://composer-0b0f0.hq.spicaengine.com/api/fn-execute/stripe/payment/method" };
    axiosWriteValidator(config);
    axiosReadValidator(config);
    return axios.request(config).then(r => r.data);
}
/**
 * @returns {Promise<any>}
 */
export function addPaymentMethod(config) {
    config = { ...config, method: "post", url: "https://composer-0b0f0.hq.spicaengine.com/api/fn-execute/stripe/payment/create" };
    axiosWriteValidator(config);
    axiosReadValidator(config);
    return axios.request(config).then(r => r.data);
}
// This statement has been deleted.
;
/**
 * @returns {Promise<any>}
 */
export function deletePaymentMethod(config) {
    config = { ...config, method: "post", url: "https://composer-0b0f0.hq.spicaengine.com/api/fn-execute/stripe/payment/method/delete" };
    axiosWriteValidator(config);
    axiosReadValidator(config);
    return axios.request(config).then(r => r.data);
}
/**
 * @returns {Promise<any>}
 */
export function updatePaymentMethod(config) {
    config = { ...config, method: "post", url: "https://composer-0b0f0.hq.spicaengine.com/api/fn-execute/stripe/payment/method/update" };
    axiosWriteValidator(config);
    axiosReadValidator(config);
    return axios.request(config).then(r => r.data);
}
// This statement has been deleted.
;
function axiosWriteValidator(config) {
    if (["post", "put", "patch"].includes(config.method) && !config.data) {
        console.warn("Sending empty request body for post, put, patch requests is unusual. If it's not intented, please use config.data or update your spica function.");
    }
}
function axiosReadValidator(config) {
    if (["get", "delete", "trace", "options", "head"].includes(config.method) && config.data) {
        console.warn("Sending request body for get, delete, trace, options, head requests is unusual. If it's not intented, please remove config.data or update your spica function.");
    }
}
