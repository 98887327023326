import * as React from "react";
import styles from "./editor.module.scss";
import { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";

import { Page } from "../../../classes/Page";
import { Modal } from "../../../classes/Modal";
import { Editor } from "../../../classes/Editor";
import { UserContext } from "../../../contexts/user";
import { loadFont } from "../../../custom-hooks/load-font";
import { EventEmitter } from "../../../services/EventEmitter";
import { Navigator } from "../../../prefabs/navigator/navigator";
import { AutoContentService } from "../../../services/AutoContent";
import { Playground } from "../../../prefabs/playground/playground";
import { Localization } from "../../../classes/Localization/Localization";
import { ILoadingContext, LoadingContext } from "../../../contexts/loading";
import { IProjectContext, ProjectContext } from "../../../contexts/project";
import { CSS_Classes, Modals, Pages, Projects } from "../../../classes/bucket";
import { iComponent } from "../../../composer-tools/editor-components/EditorComponent";
import { ComponentElements } from "../../../prefabs/component-elements/component-elements";
import {
  ComposerModal,
  ModalService,
} from "../../../composer-tools/composer-base-components/modal";
import OverlayPopup, {
  TypeOverlayProps,
} from "../../../prefabs/overlay-popup/overlay-popup";

import Registerables from "../../../composer-tools/editor-components/registerables";
import notificationService from "../../../classes/NotificationService";
import ComponentPicker from "../../../prefabs/component-picker/component-picker";

export const editor: Editor = new Editor();
export const eventEmitter = new EventEmitter();
Registerables(editor.getComponentRegistery());

function ProjectEditor() {
  const locale = new Localization();
  const loadingContext = useContext<ILoadingContext>(LoadingContext);
  const projectContext = useContext<IProjectContext>(ProjectContext);
  const userContext = useContext(UserContext);

  const [components, setComponents] = useState({});
  const [activePageIndex, setActivePageIndex] = useState<number>();
  const [pages, setPages] = useState<Page[]>();
  const [page, setPage] = useState<Page>();
  const [modals, setModals] = useState<Modal[]>();
  const [cssClasses, setCssClasses] = useState<CSS_Classes[]>();
  const [pageDom, setPageDom] = useState<iComponent[]>([]);
  const [pageIsChanged, setPageIsChanged] = useState<boolean>(false);
  const [showModalContainer, setShowModalContainer] = useState<boolean>(false);
  const [project, setProject] = useState<Projects>();
  const [editingType, setEditingType] = useState<"Content" | "Design">(
    "Content"
  );

  const [overlayContent, setOverlayContent] = useState<TypeOverlayProps>(null);
  const [forceClosePopover, setForceClosePopover] = useState<boolean>(false);
  const [disableClosePopover, setDisableClosePopover] =
    useState<boolean>(false);
  const [editingElement, setEditingElement] = useState<iComponent>(null);
  const [modal, setModal] = useState<iComponent>();
  const [open, setOpen] = useState(false);
  const [modalInstance, setModalInstance] = useState<Modal>(null)
  const [modalDom, setModalDom] = useState<iComponent[]>([]);

  useEffect(() => {
    const modal = ModalService.subscribe("open", handleOpen);
    return () => {
      modal.unsubscribe();
    };
  });

  useEffect(() => {
    const modal = ModalService.subscribe("close", handleClose);
    return () => {
      modal.unsubscribe();
    };
  });

  const [editingCSS, setEditingCSS] = useState<{
    index: number;
    component: iComponent;
  }>(null);

  const { projectId, mod } = useParams();

  const [forceReload, setForceReload] = useState(0);
  const [isPreview, setIsPreview] = useState<boolean>();

  useEffect(() => {
    let _isPreview = false;
    if (mod == "preview") _isPreview = true;
    setIsPreview(_isPreview);
  }, []);

  const handleOpen = ({ modal }: { modal: iComponent }) => {
    setModal(modal);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const pageSubscription = eventEmitter.subscribe(
      "changePage",
      (data: { action: string; index: number }) => {
        setActivePageIndex(data.index);
      }
    );
    return () => {
      pageSubscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    loadingContext.setLoading(true);
    if (!projectContext.project) return;
    setProject(projectContext.project);
    const availableComponents = { ...editor.getAvailableComponents() };
    delete availableComponents.modal;
    setComponents(availableComponents);
    const getPages = async () => {
      locale.currentLanguage = projectContext.project.current_language;
      let projectClean = await editor.getProjectFromDb(projectId);
      const pages = editor.clonePages(projectClean?.pages as Pages[]);
      const modals = editor.cloneModals(projectClean?.modals as Modals[]);
      const cssClasses = await editor.cloneCssClasses(
        projectClean?.css_classes as CSS_Classes[]
      );
      editor.languages = projectContext.project.languages;
      editor.changeThemeColors(projectContext.project.theme_config.colors);
      editor.changeThemeEnvironments(
        projectContext.project.theme_config.environments
      );
      const font = projectContext.project.theme_config.fonts || {
        family: "Roboto",
      };
      loadFont([font.family]);
      editor.changeThemeFonts(font);
      setPages(pages);
      setModals(modals);
      setCssClasses(cssClasses);
      if (pages.length) {
        const redirectPage = activePageIndex || 0;
        setActivePageIndex(redirectPage);
        const p = editor.getPage(redirectPage);
        if (editingCSS != null) {
          setEditingCSS({
            index: editingCSS.index,
            component: p.getPage()[editingCSS.index],
          });
        }
        setPage(p);
      }
      loadingContext.setLoading(false);
    };
    getPages();
  }, [projectContext.project, forceReload]);

  useEffect(() => {
    if (!pageIsChanged) return;

    Promise.all([editor.saveProject(project), page?.savePage(), modalInstance?.saveModal()])
      .then((_) => {
        setPageIsChanged(false);
      })
      .catch((_) => {
        notificationService.errorNotification("Error! Please try again");
      });
  }, [pageIsChanged]);

  useEffect(() => {
    if (page) {
      setPageDom([...page.getPage()]);
      let subscription = page.subscribe((component, action, pageJson) => {
        if (project.autogeneration && action == "add") {
          loadingContext.setLoading(true);
          const autoContentService = new AutoContentService();
          autoContentService
            .componentContent(project, component)
            .finally(() => {
              page.savePage();
              loadingContext.setLoading(false);
              setPageIsChanged(true);
              setPageDom([...pageJson]);
            });
          return;
        }
        setPageIsChanged(true);
        setPageDom([...pageJson]);
      });
      return () => {
        subscription.unsubscribe();
      };
    }
  }, [page]);

  useEffect(() => {
    if(!modalInstance) return;

    setModalDom([...modalInstance.getModal()]);
    let subscription = modalInstance.subscribe((component, action, modalJson) => {
      setPageIsChanged(true);
      setModalDom([...modalJson]);
    })
    return () => {
      subscription.unsubscribe();
    };
  }, [modalInstance])

  useEffect(() => {
    const _page = editor.getPage(activePageIndex);
    
    setPage(_page);
    if(isPreview) editor.applyCustomScript(_page?.custom_script);
  }, [activePageIndex]);

  async function onCSSChanged(cssClassId: string, css: string) {
    editor.updateCssClass(cssClassId, css);
    editor.applyStyleCSS();
  }

  async function handleAddCssClass(
    component: iComponent,
    index: number,
    sectionName: string,
    className: string,
    value: { id: string; class: string }[]
  ) {
    if (className) {
      const cssClass = await editor.addCssClass(
        className,
        userContext.user._id,
        project._id
      );
      value.push({ id: cssClass._id, class: className });
      const newCssClassesArr = project.css_classes
        ? [...project.css_classes, cssClass._id]
        : [cssClass._id];
      project.css_classes = newCssClassesArr;
      setProject(project);
      editor.saveProject(project);
      setForceReload(forceReload + 1);
    }

    page.updateComponentCssClasses(component, index, sectionName, value);
    page.savePage();
  }

  return (
    <div>
      <div
        className={`
        ${styles["editor"]} 
        ${isPreview ? styles["preview"] : ""} 
        ${modalInstance ? styles["components-menu"] : ""}
        `}
      >
        <div className={styles["navigation-and-components"]}>
          <Navigator
            page={page}
            pages={pages}
            modals={modals}
            project={project}
            isPreview={isPreview}
            cssClasses={cssClasses}
            activePageIndex={activePageIndex}
            onSetPages={(pages) => setPages(pages)}
            onSetModals={(modals) => setModals(modals)}
            onSetProject={(project) => setProject(project)}
            onReload={() => setForceReload(forceReload + 1)}
            onPageChange={(value) => setPageIsChanged(value)}
            onSetOverlayContent={(value) => setOverlayContent(value)}
            setActivePageIndex={(index) => {
              setActivePageIndex(index);
              setEditingElement(null);
              setModalInstance(null);
              setShowModalContainer(false)
            }}
            onForceClosePopover={(value) => setForceClosePopover(value)}
            onSetEditingElement={(element, index) => {
              if(element?.getCategory() === "modal"){
                setModalInstance(editor.getModal(editor.selectedModalIndex))
                setEditingCSS({component: element, index})
              }
              setEditingElement(element)}
            }
            onSetDisableClosePopover={(value) => setDisableClosePopover(value)}
          />
          <div className={styles["component-picker"]}>
            <ComponentPicker
              components={components}
              pickedComponent={(component: iComponent) => {
                page.addComponent(component);
              }}
            ></ComponentPicker>
          </div>
        </div>
        <Playground
          page={page}
          pages={pages}
          pageDom={pageDom}
          modalDom={modalDom}
          isPreview={isPreview}
          pageIsChanged={pageIsChanged}
          editingElement={editingElement}
          showModalContainer={showModalContainer}
          setShowModalContainer={(value) => setShowModalContainer(value)}
          onSetEditingCSS={(data) => setEditingCSS(data)}
          onSetEditingElement={(element, type) => {
            if(!element) {
              setModalInstance(null)
              setShowModalContainer(false)
            }
            setEditingElement(element);
            setEditingType(type);
          }}
        />
        <ComponentElements
          component={editingElement}
          onPropChanged={(propKey: string, propValue: any) => {
            if (editingElement.getCategory() == "modal") {
              modalInstance.updateModalComponent(editingElement, propKey, propValue);
            } else {
              page.updateComponent(editingElement, propKey, propValue);
            }
          }}
          editingCSS={editingCSS}
          cssClasses={cssClasses}
          onCSSChanged={(cssClassId: string, css: string) =>
            onCSSChanged(cssClassId, css)
          }
          handleAddCssClass={handleAddCssClass}
          page={page}
          isPreview={isPreview}
          type={editingType}
        />
        <OverlayPopup
          title={overlayContent?.title}
          onClose={() => {
            overlayContent?.onClose();
            setTimeout(() => setOverlayContent(null), 250);
          }}
          overrideStyle={overlayContent?.overrideStyle}
          disableClose={disableClosePopover}
          forceClose={forceClosePopover}
        >
          {overlayContent?.content}
        </OverlayPopup>
        <ComposerModal open={open} onClose={handleClose}>
          {modal && modal.render()}
        </ComposerModal>
      </div>
    </div>
  );
}

export default ProjectEditor;
