import { Projects } from "../classes/bucket";

class ProjectService {
  project: Projects;
  constructor() {}

  setProject(project: Projects) {
    this.project = project;
  }
  
  getProjectId() {
    return this.project._id;
  }
}
export default new ProjectService();
