import * as React from "react";
import { useEffect, useState } from "react";
import ComponentRepresenter from "../../components/component-representer/component-representer";
import { iComponent } from "../../composer-tools/editor-components/EditorComponent";
import styles from "./component-picker.module.scss";

function ComponentPicker({ components, pickedComponent }: any) {
  const [categories, setCategories] = useState([]);
  const [signatures, setSignatures] = useState(components);
  const [currentCategory, setCurrentCategory] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState<string>(null);

  const [timer, setTimer] = useState(null);

  const handleMouseEnter = (category: string) => {
    const newTimer = setTimeout(() => {
      setCurrentCategory(components[category]);
      setSelectedCategory(category);
    }, 100);
    setTimer(newTimer);
  };

  const handleMouseLeave = () => {
    clearTimeout(timer);
  };

  useEffect(() => {
    setCategories(Object.keys(components));
    setSignatures(components);
  }, [components]);

  return (
    <div
      className={
        styles["container"] + " " + (selectedCategory ? styles["active"] : "")
      }
    >
      <div
        onMouseLeave={() => {
          setSelectedCategory(null);
        }}
      >
        <div className={styles["components"]}>
          <h2>Components</h2>
          <div className={styles["categories"]}>
            {categories.map((category, index) => (
              <div
                key={`category-${index}`}
                className={
                  styles["item"] +
                  " " +
                  (selectedCategory == category ? styles["active"] : "")
                }
                onMouseEnter={() => {
                  handleMouseEnter(category);
                }}
                onMouseLeave={handleMouseLeave}
              >
                {category}
              </div>
            ))}
          </div>
        </div>
        <div
          className={
            styles["items"] + " " + (selectedCategory ? styles["active"] : "")
          }
        >
          {currentCategory.map((component: iComponent, index: number) => (
            <div className={styles["item"]} key={`component-${Math.random()}`}>
              <ComponentRepresenter
                preview={component.render()}
                category={component.getName()}
                clicked={() => {
                  setSelectedCategory(null);
                  pickedComponent(currentCategory[index]);
                }}
              ></ComponentRepresenter>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ComponentPicker;
