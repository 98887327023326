import * as React from "react";
import { useEffect, useState } from "react";
import { AddImagePopover } from "../../../components/add-image-popover/add-image-popover";
import { WButton, WIconButton } from "../../../components/wrappers/wrapper";
import OverlayPopup, { TypeOverlayProps } from "../../overlay-popup/overlay-popup";
import { InputWrapper } from "./input-wrapper";
import styles from "./input.module.scss";
import { Delete } from "@mui/icons-material";
import { buttons } from "../../../styles/theme.module";

export const Image = ({ property, value, onChange }: any) => {
  const [count, setCount] = useState<number>(1);
  const [forceClose, setForceClose] = useState<boolean>(false);
  const [showPopover, setShowPopover] = useState<boolean>(false);
  const [overlayContent, setOverlayContent] = useState<TypeOverlayProps>(null);

  useEffect(() => {
    showPopover &&
      setOverlayContent({
        title: "Add Image",
        onClose: () => {
          setForceClose(false);
          setShowPopover(false);
          setCount(count + 1);
        },
        content: (
          <AddImagePopover
            forceRender={count}
            onChangeLink={(data: string) => onChange({ property, value: `url(${data})` })}
            onClosePopover={handleForceClose}
          />
        ),
        overrideStyle: {
          position: "fixed",
          width: "650px",
          height: "500px",
        },
      });
  }, [showPopover]);

  const handleForceClose = () => {
    setShowPopover(false);
    setForceClose(true);
  };

  const handleShowPopover = () => {
    setShowPopover(true);
    setCount(count + 1);
  };
  return (
    <InputWrapper property={property}>
      {value ? (
        <div className={styles["link-value-container"]}>
          <span>{value}</span>
          <WIconButton>
            <Delete onClick={() => onChange({ property, value: "" })} />
          </WIconButton>
        </div>
      ) : (
        <WButton fullWidth className={`${styles["button"]} ${buttons["button"]} ${buttons["primary"]}`} onClick={handleShowPopover}>
          <span>Choose Image</span>
        </WButton>
      )}
      <OverlayPopup
        title={overlayContent?.title}
        onClose={overlayContent?.onClose}
        overrideStyle={overlayContent?.overrideStyle}
        forceClose={forceClose}
      >
        {overlayContent?.content}
      </OverlayPopup>
    </InputWrapper>
  );
};
