import * as React from "react";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

interface ICloseButton {
  disabled?: boolean;
  handler: () => void;
}
export const CloseButton: React.FC<ICloseButton> = ({ disabled=false, handler }) => {
  return (
    <IconButton disabled={disabled} onClick={handler}>
      <CloseIcon />
    </IconButton>
  );
};
