import * as React from "react";
import { useContext } from "react";
import { WStack } from "../../components/wrappers/wrapper";
import { LoadingContext } from "../../contexts/loading";
import styles from "./loading.module.scss";

export function LoadingContainer() {
  const loadingContext = useContext(LoadingContext);
  return (
    <WStack
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      className={`${styles["container"]} ${
        loadingContext.loading ? styles["show"] : ""
      }`}
    >
      <img src="/logo512.png" />
    </WStack>
  );
}
