import * as React from "react";
import { useEffect, useState } from "react";
import styles from "./inputs.module.scss";
function InputNumber({value,label,onChange}: any) {
  const [inputValue, setInputValue] = useState(value);

  useEffect(() => {
    setInputValue(value);
  }, [value])
  
  return <div className={styles["number"]}>
    <div className={styles["label"]}>{label}</div>
    <input className={styles["input"]} type="number" value={inputValue} onChange={(_) => {setInputValue(_.target.value);onChange(_.target.value)}}></input>
  </div>;
}
export default InputNumber;
