import * as React from "react";
import { FC, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styles from "./analytic-line-chart.module.scss";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

interface iAnalyticChart {
  label: string;
  data: any;
  date: Date[];
  isStacked?: boolean;
  onDateChanged?: (data: any) => void;
}
const AnalyticLineChart: FC<iAnalyticChart> = ({
  label,
  data,
  date,
  isStacked,
  onDateChanged,
}) => {
  const [dateRange, setDateRange] = useState(date);
  const [startDate, endDate] = dateRange;

  const stakedOptions = {
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };

  const options = {
    responsive: true,
    tension: 0.3,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
    ...(isStacked ? stakedOptions : {}),
  };
  return (
    <div className={styles["chart-body"]}>
      <div className={styles["head"]}>
        <span className={styles["label"]}>{label}</span>
        <DatePicker
          selectsRange={true}
          startDate={startDate}
          endDate={endDate}
          onChange={(update) => {
            setDateRange(update);
            onDateChanged(update);
          }}
          isClearable={true}
          dateFormat="MMMM dd, yyyy"
        />
      </div>
      <Line options={options} data={data} />
    </div>
  );
};

export default AnalyticLineChart;
