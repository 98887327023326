import * as React from "react";
import styles from "./navigation-bar.module.scss";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

interface iTab {
  label: string;
  path: string;
}
const tabs: iTab[] = [
  {
    label: "Overview",
    path: "overview",
  },
  {
    label: "Domains",
    path: "domain",
  },
  {
    label: "SEO",
    path: "seo",
  },
  {
    label: "Analytics",
    path: "analytic",
  },
  {
    label: "Forms",
    path: "form",
  },
  {
    label: "Webhooks",
    path: "webhook",
  },
];
export function NavigationBar() {
  const pathArr = window.location.pathname.split("/");
  const active = pathArr.pop();

  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<string>(active);

  const onChangeTab = (tab: iTab) => {
    const path = pathArr.join("/") + `/${tab.path}`;
    setActiveTab(tab.path);
    navigate(path);
  };

  return (
    <div className={styles["container"]}>
      {tabs.map((tab, index) => (
        <span
          key={`${tab}-${index}`}
          className={activeTab == tab.path ? styles["active"] : ""}
          onClick={() => {
            onChangeTab(tab);
          }}
        >
          {tab.label}
        </span>
      ))}
    </div>
  );
}
