import { Editor } from "../classes/Editor";
import { themes } from "../prefabs/theme/theme";
import { FunctionService } from "../classes/Function";
import { iComponent } from "../composer-tools/editor-components/EditorComponent";

const googleFonts = [
  "Roboto",
  "Open Sans",
  "Montserrat",
  "Lato",
  "Poppins",
  "Source Sans Pro",
  "Raleway",
  "Noto Sans",
  "Inter",
  "Roboto Slab",
  "Merriweather",
  "Playfair Display",
];
export class AutoContentService {
  private functionService = new FunctionService();

  counter: number = 0;
  cmpObjArr: { completion: string; value: string }[] = [];
  completions: { completion: string; value: string }[] = [];

  constructor() {}

  projectGenerate = async (editor: Editor, project: any) => {
    const components = this.prepareComponents(editor);
    project = await this.setProjectTheme(editor, project);

    for (const component of components) {
      const props = component.getProps();
      this.prepareCompletions(props);
    }

    const res = await this.functionService.contentGenerate(
      this.cmpObjArr,
      this.getProductDefinition(project)
    );
    this.completions = res.result;

    project.pages = await this.addPage(editor, project);

    for (const component of components) {
      project.pages[0].addComponent(component);
    }

    for (const component of project.pages[0].getPage()) {
      const props = component.getProps();
      this.prepareCompletions(props);
    }

    project.pages[0].savePage();
    this.resetStates();

    return project;
  };

  setProjectTheme = async (editor: Editor, project: any) => {
    const _project = {
      ...project,
      theme_config: {
        ...project.theme_config,
        colors: themes[this.randomNumber(themes.length)],
        fonts: {
          family: googleFonts[this.randomNumber(googleFonts.length)],
        },
      },
    };
    return editor.saveProject({ ..._project });
  };

  prepareComponents = (editor: Editor) => {
    const components = [];
    const availableComponents = { ...editor.getAvailableComponents() };

    const header = availableComponents.header;
    const callToAction = availableComponents.callToAction;
    const feature = availableComponents.feature;
    const content = availableComponents.content;
    const pricing = availableComponents.pricing;
    const team = availableComponents.team;
    const testimonials = availableComponents.testimonials;
    const footer = availableComponents.footer;

    // components.push(header[this.randomNumber(header.length)]);
    // components.push(callToAction[this.randomNumber(callToAction.length)]);
    // components.push(feature[this.randomNumber(feature.length)]);
    // components.push(content[this.randomNumber(content.length)]);
    // components.push(pricing[this.randomNumber(pricing.length)]);
    // components.push(team[this.randomNumber(team.length)]);
    // components.push(testimonials[this.randomNumber(testimonials.length)]);
    // components.push(footer[this.randomNumber(footer.length)]);

    components.push(header[6]);
    components.push(callToAction[this.randomNumber(callToAction.length)]);
    components.push(feature[1]);
    components.push(content[0]);
    components.push(pricing[4]);
    components.push(team[12]);
    components.push(testimonials[1]);

    return components;
  };

  addPage = async (editor: Editor, project: any) => {
    return editor.addPage(
      project.owner,
      project._id,
      `name${Math.random()}`,
      "index",
      {
        title: "Home",
        description: "",
        keywords: "",
        custom: [],
      },
      ""
    );
  };

  randomNumber = (min: number) => {
    return Math.floor(Math.random() * min);
  };

  prepareCompletions = (props: any) => {
    for (let prop of props) {
      if (typeof prop.value == "object") {
        this.scanProps([prop.type, prop.value], prop);
      } else {
        if (prop.completion) {
          this.cmpObjArr.push({ completion: prop.completion, value: "" });
          if (this.completions.length && this.completions[this.counter]) {
            prop.value = this.completions[this.counter].value;
            this.counter++;
          }
        }
      }
    }
  };

  scanProps = (arr: any, props: any) => {
    for (let el of Object.entries(arr[1])) {
      if (el[0] == "completion") {
        this.cmpObjArr.push({ completion: el[1] as string, value: "" });
        if (this.completions.length && this.completions[this.counter]) {
          arr[1].value = this.completions[this.counter].value;
          this.counter++;
        }
      }
      if (typeof el[1] == "object") {
        this.scanProps(el, props);
      }
    }
  };

  componentContent = async (project: any, component: iComponent) => {
    const props = component.getProps();
    this.prepareCompletions(props);
    const res = await this.functionService.contentGenerate(
      this.cmpObjArr,
      this.getProductDefinition(project)
    );
    this.completions = res.result;
    this.prepareCompletions(props);
    this.resetStates();
    return component;
  };

  componentItemContent = async (project: any, item: any) => {
    this.prepareCompletions(item);
    const res = await this.functionService.contentGenerate(
      this.cmpObjArr,
      this.getProductDefinition(project)
    );
    this.completions = res.result;
    this.prepareCompletions(item);
    this.resetStates();
    return item;
  }

  regenerateContent = async (project: any, value: string) => {
    const completion = [{ completion: value, value: "" }];
    const res = await this.functionService.contentGenerate(
      completion,
      ""
    );
    return res?.result[0]?.value;
  };

  getProductDefinition = (project: any) => {
    return `Product name: ${project.name}. Product Industry: ${project.industry}. ${
      project.description
    } Features: ${project.features.join(", ")}`;
  };

  resetStates = () => {
    this.counter = 0;
    this.completions = [];
    this.cmpObjArr = [];
  };
}
