import { useNavigate } from "react-router-dom";
import notificationService from "../classes/NotificationService";
import { Authentication } from "../services/Authentication";

let isActive = false;

const useRequestTracker = () => {
  const navigate = useNavigate();
  const authentication = new Authentication();

  if (isActive) return;

  const observer = new PerformanceObserver((entries) => {
    entries.getEntriesByType("resource").forEach((res) => {
      const response = res.toJSON();

      if (
        response.initiatorType == "xmlhttprequest" &&
        response.responseStatus == 401
      ) {
        authentication.logOut();
        navigate("/authentication");
        notificationService.warningNotification("JWT expired");
      }
    });
  });
  observer.observe({ entryTypes: ["resource"] });

  isActive = true;
};

export default useRequestTracker;
