import * as React from "react";
import { useEffect, useState } from "react";
import { WMenuItem, WSelect, WTextField } from "../../../components/wrappers/wrapper";
import { InputWrapper } from "./input-wrapper";
import styles from "./input.module.scss";
import { Suffix } from "./suffix";

const units = ["px", "rem", "%", "em", "vw", "vh"];
export const Length = ({ property, value, onChange }: any) => {
  const [inputValue, setInputValue] = useState<any>();
  const [unit, setUnit] = useState<string>("px");

  const getUnit = (value: string) => {
    for (let item of units) {
      if (value?.endsWith(item)) {
        return item;
      }
    }
  };

  useEffect(() => {
    if (!value) setInputValue("");
    else {
      if(!getUnit(value)) return;
      setUnit(getUnit(value) || "px");
      value = value
        .replace("px", "")
        .replace("rem", "")
        .replace("%", "")
        .replace("em", "")
        .replace("vw", "")
        .replace("vh", "");
      setInputValue(value);
    }
  }, [value]);

  useEffect(() => {
    onChange({ property, value: inputValue ? `${inputValue}${unit}` : "" });
  }, [inputValue, unit]);

  return (
    <InputWrapper property={property}>
      <WTextField
        fullWidth
        value={inputValue}
        size={"small"}
        onChange={(_: any) => {
          setInputValue(_.target.value);
        }}
        className={styles["text-filed"]}
        sx={{
          "&.MuiFormControl-root": {
            ".MuiOutlinedInput-root": {
              padding: 0,
            },
          },
        }}
        suffix={
          <Suffix>
            <WSelect
              className={styles["unit-select"]}
              defaultValue={"px"}
              displayEmpty
              value={unit}
              onChange={(_: any) => {
                setUnit(_.target.value);
              }}
            >
              {units.map((item, index) => (
                <WMenuItem key={`unit-${index}`} value={item}>
                  {item}
                </WMenuItem>
              ))}
            </WSelect>
          </Suffix>
        }
      ></WTextField>
    </InputWrapper>
  );
};
