import * as React from "react";
import styles from "./input.module.scss";

export const InputWrapper = ({ property, children }: any) => {
  return (
    <div className={styles["input-wrapper"]}>
      <span className={styles["input-label"]}>{property}</span>
      {children}
    </div>
  );
};
