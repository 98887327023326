import * as React from "react";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
import Autocomplete from "@mui/material/Autocomplete";
import styles from "./component-css-popover.module.scss";

import { CSSGUI } from "../css-gui/css-gui";
import { useEffect, useState } from "react";
import { CSS_Classes } from "../../classes/bucket";
import { editor } from "../../pages/project/editor/editor";
import { WCircularProgress, WTooltip } from "../../components/wrappers/wrapper";
import { iComponent } from "../../composer-tools/editor-components/EditorComponent";

function ComponentCSSPopover({
  component,
  projectCssClasses,
  onCSSChanged,
  onClassAdded,
  onClassDisposed,
}: {
  component: iComponent;
  projectCssClasses: CSS_Classes[];
  onCSSChanged: (className: string, packedClassName: string) => void;
  onClassAdded: (
    sectionName: string,
    className: string,
    value: { id: string; class: string }[]
  ) => void;
  onClassDisposed: (
    sectionName: string,
    value: { id: string; class: string }[]
  ) => void;
}) {
  let autocompleteRef: any;
  const [css, setCss] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [className, setClassName] = useState<string>("");
  const [classError, setClassError] = useState<boolean>(false);
  const [cssClasses, setCssClasses] = useState<CSS_Classes[]>([]);
  const [selectedSection, setSelectedSection] = useState<string>();
  const [selectedClass, setSelectedClass] = useState<CSS_Classes>();

  useEffect(() => {
    setSelectedClass(undefined);
    if (!selectedSection) {
      setCssClasses([]);
      return;
    }
    const sections = component.getCSSClasses();
    if (!Object.keys(sections).length) return;

    const _cssClasses: CSS_Classes[] = [];
    sections[selectedSection].forEach((el: { id: string; class: string }) => {
      _cssClasses.push(editor.getCssClass(el.id));
    });

    setCssClasses(_cssClasses);
  }, [selectedSection, projectCssClasses]);

  const hoverEffect = (
    isTrue: boolean,
    cssClass: string,
    packedClass: string
  ) => {
    let elems = Array.from(
      document.getElementsByClassName(
        packedClass
      ) as HTMLCollectionOf<HTMLElement>
    );
    for (var i = 0; i < elems.length; i++) {
      if (isTrue) {
        elems[i].style.border = "1px dashed #4FBAEC";
        elems[i].style.boxShadow = "box-shadow: 0px 0px 5px white";
      } else {
        elems[i].style.removeProperty("border");
        elems[i].style.removeProperty("box-shadow");
      }
    }
  };

  function handleClassNameKeyDown(ev: any) {
    if (ev.keyCode === 13 && className.trim()) {
      autocompleteRef?.blur();
      const _className = className.trim().replace(/ /g, "-").toLowerCase();
      if (_className.charAt(0).match(/[a-z]/i) == null) {
        setClassError(true);
        return;
      }
      setClassError(false);
      const candidate = projectCssClasses.find(
        (el) => el.class_name === _className
      );
      if (candidate) {
        handleOnChangeAutocomplete({
          id: candidate._id,
          lable: candidate.class_name,
        });
        return;
      }
      onClassAdded(
        selectedSection,
        _className,
        Array.from(cssClasses, (el) => ({ id: el._id, class: el.class_name }))
      );
      setClassName("");
    }
  }

  function handlesSelectSection(section: string) {
    if (selectedSection === section) {
      section = "";
    }
    setSelectedSection(section);
  }

  function handleSelectCssClass(cssClass: CSS_Classes) {
    if (cssClass.system) return;
    if (selectedClass?._id === cssClass._id) {
      setSelectedClass(undefined);
      setCss({});
    } else {
      setLoading(true);
      setSelectedClass(undefined);
      setTimeout(() => {
        setSelectedClass(cssClass);
        setCss(cssClass?.css ? JSON.parse(cssClass.css) : {});
        setLoading(false);
      }, 200);
    }
  }

  function handleOnChangeAutocomplete(value: { id: string; lable: string }) {
    autocompleteRef?.blur();
    if (!value) return;
    const candidate = cssClasses.find((el) => el._id === value.id);
    if (candidate) {
      setClassName("");
      return;
    }

    const cssClass = editor.getCssClass(value.id);
    const _cssClasses = [...cssClasses, cssClass];
    setCssClasses(_cssClasses);
    setClassName("");
    onClassAdded(
      selectedSection,
      "",
      Array.from(_cssClasses, (el) => ({ id: el._id, class: el.class_name }))
    );
  }

  function handleClassDispose(cssClass: CSS_Classes) {
    const _cssClasses = cssClasses.filter((el) => el._id !== cssClass._id);
    setCssClasses(_cssClasses);
    onClassDisposed(
      selectedSection,
      Array.from(_cssClasses, (el) => ({ id: el._id, class: el.class_name }))
    );
    setSelectedClass(undefined);
  }

  return (
    <div className={styles["container"]}>
      <div className={styles["components"]}>
        {Object.entries(component.getExportedCSSClasses()).map(
          ([section, packedClass]: any, index: number) => (
            <div
              key={`section-${index}`}
              onClick={() => handlesSelectSection(section)}
              className={`${styles["css-section"]} ${
                selectedSection === section ? styles["active"] : ""
              }`}
              onMouseEnter={() => hoverEffect(true, section, packedClass)}
              onMouseLeave={() => hoverEffect(false, section, packedClass)}
            >
              {section}
            </div>
          )
        )}
      </div>
      <div className={`${styles["components"]} ${styles["css-classes"]}`}>
        {selectedSection ? (
          <Autocomplete
            fullWidth
            size={"small"}
            disablePortal
            defaultValue={className}
            value={className}
            options={editor
              .getCssClasses()
              .map((el) => ({ id: el._id, label: el.class_name }))}
            renderInput={(params: any) => (
              <TextField
                error={classError}
                helperText={
                  classError ? "The class name must start with a letter." : ""
                }
                inputRef={(input) => {
                  autocompleteRef = input;
                }}
                {...params}
                className={styles["input"]}
                type="text"
                placeholder="Add new class..."
                onChange={(e) => {
                  setClassName(e.target.value);
                }}
              />
            )}
            onChange={(event: any, value: any) =>
              handleOnChangeAutocomplete(value)
            }
            onKeyDown={(e) => handleClassNameKeyDown(e)}
          />
        ) : (
          ""
        )}
        {cssClasses.map((cssClass: CSS_Classes, index: number) => (
          <WTooltip title={cssClass.system && "System class is not editable"}>
            <div
              key={`class-${index}`}
              className={`${styles["css-class"]} ${
                selectedClass?._id === cssClass._id ? styles["active"] : ""
              }`}
              onClick={() => handleSelectCssClass(cssClass)}
            >
              <CloseIcon
                className={`${styles["close-icon"]}`}
                onClick={(event) => {
                  event.stopPropagation();
                  handleClassDispose(cssClass);
                }}
              />
              <span>{cssClass.class_name}</span>
            </div>
          </WTooltip>
        ))}
      </div>
      {selectedClass && (
        <div className={styles["edit-panel"]}>
          <CSSGUI
            values={css}
            changeCss={(css: any) => {
              if (!Object.keys(css).length || !selectedClass) return;
              onCSSChanged(selectedClass?._id, JSON.stringify(css));
            }}
          />
        </div>
      )}
      {loading ? (
        <div className={styles["edit-panel"]}>
          <WCircularProgress size="2.5rem" />
        </div>
      ) : (
        !selectedClass && (
          <div className={styles["edit-panel"]}>
            <span className={styles["placeholder-span"]}>
              {selectedSection
                ? "Add a new CSS class or select an existing one to activate the custom styles menu."
                : "Please select a section to add a class."}
            </span>
          </div>
        )
      )}
    </div>
  );
}

export default ComponentCSSPopover;
