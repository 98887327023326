import { iComponent, TypeUsableComponentProps } from "../composer-tools/editor-components/EditorComponent";
import { Page } from "./Page";

export type TypeBuilderActions = "add" | "update" | "updateCSSClasses" | "delete" | "move" | "clone";
export type ModalBuilderObserver = (component: iComponent, action: TypeBuilderActions, modalJson: iComponent[]) => void
export class ModalBuilder {
    modal: iComponent[] = [];
    subscriptions: ModalBuilderSubscription[] = [];
    factory = new ComponentFactory();

    constructor(modal?: iComponent[]) {
        if(modal) this.modal = modal;
    }

    getModal(): iComponent[] {
        return this.modal;
    }

    addModal(component: iComponent): iComponent {
        let shadowComponent = this.factory.create(component);
        this.modal.push(shadowComponent);
        return shadowComponent;
    }

    update(component: iComponent, propKey: string, propValue: any) {
        component.setProp(propKey, propValue);
        this.emit(component, "update")
    }

    updateCSSClasses(component: iComponent, sectionName: string, value: {id: string, class: string}[]){
        component.setCSSClasses(sectionName, value);
        this.emit(component, "updateCSSClasses")
    }

    deleteModal(index: number){
        this.modal.splice(index, 1);
    }

    async cleanModal(page: Page, name: string){
        for(const component of page.getPage()){
            const props = component.getProps();
            for(let prop of props){
              if(typeof prop.value == "object")
                await recursive(page, [prop.type, prop.value], prop);
            }
        }

        async function recursive(page: Page, arr: any, prop: TypeUsableComponentProps) {
            if(typeof arr[1] !=  "object") return;
      
            if (arr[1]?.type == "page" && arr[1].value == name ) {
              arr[1].value = ""
              await page.savePage()
            }
      
            if(arr[1] && typeof arr[1] == "object"){
              for (let el of Object.entries(arr[1])) {
                if (typeof el[1] == "object") {
                  recursive(page, el, prop);
                }
              }
            }
          }
    }

    subscribe(callback: ModalBuilderObserver) {
        let subscriptionIndex = this.subscriptions.length;
        let subscription = new ModalBuilderSubscription(callback, subscriptionIndex, this);
        this.subscriptions.push(subscription);
        return subscription;
    }

    unsubscribe(subscriptionIndex: number) {
        this.subscriptions = this.subscriptions.splice(subscriptionIndex, 1);
    }

    private emit(component: iComponent, action: TypeBuilderActions) {
        this.subscriptions.forEach((subscription: ModalBuilderSubscription) => subscription.callback(component, action, this.modal))
    }
}

class ModalBuilderSubscription {
    cb: ModalBuilderObserver;
    builder: ModalBuilder;
    subscriptionIndex: number;

    constructor(cb: ModalBuilderObserver, index: number, builder: ModalBuilder) {
        this.cb = cb;
        this.builder = builder;
        this.subscriptionIndex = index;
    }

    callback: ModalBuilderObserver = (component, action, pageJson) => {
        this.cb(component, action, pageJson);
    }

    unsubscribe() {
        this.builder.unsubscribe(this.subscriptionIndex);
    }
}

export class ComponentFactory {

    create(component: iComponent): iComponent {
        let newInstance = new (component.constructor as { new(): iComponent })();

        return newInstance;
    }

    clone(component: iComponent): iComponent {
        let createdCopy: iComponent = this.create(component);
        component.getProps().map(prop => {
            createdCopy.setProp(prop.key, prop.value)
        })
        Object.entries(component.getCSSClasses()).map(([key,value]) => {
            createdCopy.setCSSClasses(key, value as any)
        })
        return createdCopy;
    }
}