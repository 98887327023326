import { iComponent } from "../composer-tools/editor-components/EditorComponent";
import { iDatabase } from "./Database";
import { 
  CommandAddComponent, 
  CommandChangeOrder, 
  CommandDeleteComponent, 
  CommandCloneComponent, 
  CommandUpdateComponentCssClasses, 
  Localization, 
  LocalizationInvoker,
} from "./Localization/Localization";
import { PageBuilder, PageBuilderObserver } from "./PageBuilder";

export type TypeMetaTags = {
  title?: string;
  description?: string;
  keywords?: string;
  custom?: string[];
};
export type TypeLocalization = {
  language?: string;
  builder?: PageBuilder;
}
export class Page {
  db: iDatabase;
  localizationInvoker: LocalizationInvoker = new LocalizationInvoker(this);
  locale: Localization = new Localization();
  constructor(db: iDatabase, name: string, slug: string, meta_tags: TypeMetaTags, custom_script: string) {
    this.db = db;
    this.name = name;
    this.slug = slug;
    this.meta_tags = meta_tags;
    this.custom_script = custom_script;
  }

  private _id: string;
  public get id(): string {
    return this._id;
  }
  public set id(value: string) {
    this._id = value;
  }
  private _name: string;
  public get name(): string {
    return this._name;
  }
  public set name(value: string) {
    this._name = value;
  }
  private _slug: string;
  public get slug(): string {
    return this._slug;
  }
  public set slug(value: string) {
    this._slug = value;
  }
  private _meta_tags: TypeMetaTags;
  public get meta_tags(): TypeMetaTags {
    return this._meta_tags;
  }
  public set meta_tags(value: TypeMetaTags) {
    this._meta_tags = value;
  }

  private _custom_script: string;
  public get custom_script(): string {
    return this._custom_script;
  }
  public set custom_script(value: string) {
    this._custom_script = value;
  }

  private _localization: TypeLocalization[] = [];
  public get localization(): TypeLocalization[] {
    return this._localization;
  }
  public set localization(value: TypeLocalization[]) {
    this._localization = value;
  }

  currentBuilder(): PageBuilder{
    return this.localization.filter(localization => localization.language == this.locale.currentLanguage)[0].builder;
  }

  subscribe(callBack: PageBuilderObserver) {
    return this.currentBuilder().subscribe((component, action, pageJson) =>
      callBack(component, action, pageJson)
    );
  }

  getPage(): iComponent[] {
    return this.currentBuilder().getPage();
  }

  addComponent(component: iComponent): void {
    this.currentBuilder().add(component);
    this.localizationInvoker.addCommand(new CommandAddComponent(component));
  }

  updateComponent(
    component: iComponent,
    propKey: string,
    propValue: any
  ): void {
    this.currentBuilder().update(component, propKey, propValue);
  }

  updateComponentCssClasses(component: iComponent, index: number, sectionName: string, value: {id: string, class: string}[]) {
    this.currentBuilder().updateCSSClasses(component, sectionName, value);
    this.localizationInvoker.addCommand(new CommandUpdateComponentCssClasses(index, sectionName, value));
  }

  deleteComponent(index: number): void {
    this.currentBuilder().delete(index);
    this.localizationInvoker.addCommand(new CommandDeleteComponent(index));
  }

  move(index: number, to: -1 | 1): void {
    this.currentBuilder().move(index, to);
    this.localizationInvoker.addCommand(new CommandChangeOrder(index, to));
  }

  cloneComponent(component: iComponent) {
    this.currentBuilder().clone(component);
    this.localizationInvoker.addCommand(new CommandCloneComponent(component));
  }

  savePage(): Promise<any> {
    this.localizationInvoker.execute();
    return this.db.updatePage(this);
  }
}
