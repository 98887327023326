import { CSS_Classes } from "./bucket";
import { iDatabase } from "./Database";

export interface iCSSClassNameRegistry {
  addCssClass(className: string, userId: string, project: string): Promise<CSS_Classes>;
  updateCssClass(cssClassId: string, css: string): Promise<CSS_Classes>;
}

export class CSSClassNameRegistry {
  db: iDatabase;
  constructor(db: iDatabase) {
    this.db = db;
  }

  public addCssClass(class_name: string, user: string, project: string): Promise<CSS_Classes>{
    return this.db.addCssClass(class_name, user, project)
  }

  public updateCssClass(cssClassId: string, css: string): Promise<CSS_Classes>{
    return this.db.updateCssClass(cssClassId, css)
  }
}