import * as React from "react";
import { WButton } from "../wrappers/wrapper";
import styles from "./social-login-button.module.scss";

export function SocialLoginButton({
  handler,
  icon,
  text,
}: {
  handler: () => void;
  icon: string;
  text: string;
}) {
  return (
    <WButton fullWidth onClick={handler} className={styles["button"]}>
      <img src={icon} />
      <span>{text}</span>
    </WButton>
  );
}
