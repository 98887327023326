import ComponentsRegistery from "./ComponentRegistery";
import DownloadComponent1 from "./download/download1/download1";
import DownloadComponent2 from "./download/download2/download2";
import DownloadComponent3 from "./download/download3/download3";
import DownloadComponent4 from "./download/download4/download4";
import DownloadComponent5 from "./download/download5/download5";
import DownloadComponent6 from "./download/download6/download6";
import DownloadComponent7 from "./download/download7/download7";
import DownloadComponent8 from "./download/download8/download8";
import DownloadComponent9 from "./download/download9/download9";
import DownloadComponent10 from "./download/download10/download10";
import DownloadComponent11 from "./download/download11/download11";
import ListComponent1 from "./list/list1/list1";
import ListComponent2 from "./list/list2/list2";
import ListComponent3 from "./list/list3/list3";
import ListComponent4 from "./list/list4/list4";
import ContentComponent1 from "./content/content1/content1";
import ContentComponent2 from "./content/content2/content2";
import ContentComponent3 from "./content/content3/content3";
import ContentComponent4 from "./content/content4/content4";
import ContentComponent5 from "./content/content5/content5";
// import ContentComponent6 from "./content/content5/content6";
import ContentComponent7 from "./content/content7/content7";
import ContentComponent8 from "./content/content8/content8";
import ContentComponent9 from "./content/content9/content9";
import ContentComponent10 from "./content/content10/content10";
import SliderComponent1 from "./slider/slider1/slider1";
import SliderComponent2 from "./slider/slider2/slider2";
import SliderComponent3 from "./slider/slider3/slider3";
import SliderComponent4 from "./slider/slider4/slider4";
import SliderComponent5 from "./slider/slider5/slider5";
import SliderComponent6 from "./slider/slider6/slider6";
import SliderComponent7 from "./slider/slider7/slider7";
import PricingComponent1 from "./pricing-table/pricing-table1/pricing-table1";
import PricingComponent2 from "./pricing-table/pricing-table2/pricing-table2";
import PricingComponent3 from "./pricing-table/pricing-table3/pricing-table3";
import PricingComponent4 from "./pricing-table/pricing-table4/pricing-table4";
import PricingComponent5 from "./pricing-table/pricing-table5/pricing-table5";
import PricingComponent6 from "./pricing-table/pricing-table6/pricing-table6";
import PricingComponent7 from "./pricing-table/pricing-table7/pricing-table7";
import FaqComponent1 from "./faq/faq1/faq1";
import FaqComponent2 from "./faq/faq2/faq2";
import FaqComponent3 from "./faq/faq3/faq3";
import FaqComponent4 from "./faq/faq4/faq4";
import FaqComponent5 from "./faq/faq5/faq5";
import FaqComponent6 from "./faq/faq6/faq6";
import TeamComponent1 from "./team/team-1/team1";
import TeamComponent2 from "./team/team-2/team2";
import TeamComponent3 from "./team/team-3/team3";
import TeamComponent4 from "./team/team-4/team4";
import TeamComponent5 from "./team/team-5/team5";
import TeamComponent6 from "./team/team-6/team6";
import TeamComponent7 from "./team/team-7/team7";
import TeamComponent8 from "./team/team-8/team8";
import TeamComponent9 from "./team/team-9/team9";
import TeamComponent10 from "./team/team-10/team10";
import TeamComponent11 from "./team/team-11/team11";
import TeamComponent12 from "./team/team-12/team12";
import TeamComponent13 from "./team/team-13/team13";
import TeamComponent14 from "./team/team-14/team14";
import TeamComponent15 from "./team/team-15/team15";
import HeaderComponent1 from "./header/header1/header1";
import HeaderComponent2 from "./header/header2/header2";
import HeaderComponent3 from "./header/header3/header3";
import HeaderComponent4 from "./header/header4/header4";
import HeaderComponent5 from "./header/header5/header5";
import HeaderComponent6 from "./header/header6/header6";
import HeaderComponent7 from "./header/header7/header7";
import HeaderComponent8 from "./header/header8/header8";
import HeaderComponent9 from "./header/header9/header9";
import HeaderComponent10 from "./header/header10/header10";
import HeaderComponent11 from "./header/header11/header11";
import HeaderComponent12 from "./header/header12/header12";
import FeatureComponent1 from "./feature/feature1/feature1";
import FeatureComponent2 from "./feature/feature2/feature2";
import FeatureComponent3 from "./feature/feature3/feature3";
import FeatureComponent4 from "./feature/feature4/feature4";
import FeatureComponent5 from "./feature/feature5/feature5";
import FeatureComponent6 from "./feature/feature6/feature6";
import FeatureComponent7 from "./feature/feature7/feature7";
import FeatureComponent8 from "./feature/feature8/feature8";
import FeatureComponent9 from "./feature/feature9/feature9";
import FeatureComponent10 from "./feature/feature10/feature10";
import FeatureComponent11 from "./feature/feature11/feature11";
import FeatureComponent12 from "./feature/feature12/feature12";
import FeatureComponent13 from "./feature/feature13/feature13";
import FeatureComponent14 from "./feature/feature14/feature14";
import CallToAction1 from "./call_to_action/call_to_action1/call_to_action1";
import CallToAction2 from "./call_to_action/call_to_action2/call_to_action2";
import CallToAction3 from "./call_to_action/call_to_action3/call_to_action3";
import CallToAction4 from "./call_to_action/call_to_action4/call_to_action4";
import CallToAction5 from "./call_to_action/call_to_action5/call_to_action5";
import CallToAction6 from "./call_to_action/call_to_action6/call_to_action6";
import CallToAction7 from "./call_to_action/call_to_action7/call_to_action7";
import CallToAction8 from "./call_to_action/call_to_action8/call_to_action8";
import CallToAction9 from "./call_to_action/call_to_action9/call_to_action9";
import CallToAction10 from "./call_to_action/call_to_action10/call_to_action10";
import LogoComponent1 from "./logo-clouds/logo-comp1/logo-comp1";
import LogoComponent2 from "./logo-clouds/logo-comp2/logo-comp2";
import LogoComponent3 from "./logo-clouds/logo-comp3/logo-comp3";
import LogoComponent4 from "./logo-clouds/logo-comp4/logo-comp4";
import TestimonialsComponent1 from "./testimonials/testimonials1/testimonials1";
import TestimonialsComponent2 from "./testimonials/testimonials2/testimonials2";
import TestimonialsComponent3 from "./testimonials/testimonials3/testimonials3";
import TestimonialsComponent4 from "./testimonials/testimonials4/testimonials4";
import TestimonialsComponent5 from "./testimonials/testimonials5/testimonials5";
import TestimonialsComponent6 from "./testimonials/testimonials6/testimonials6";
import TestimonialsComponent7 from "./testimonials/testimonials7/testimonials7";
import StatsComponent1 from "./stats/stats1/stats1";
import StatsComponent2 from "./stats/stats2/stats2";
import StatsComponent3 from "./stats/stats3/stats3";
import StatsComponent4 from "./stats/stats4/stats4";
import StatsComponent5 from "./stats/stats5/stats5";
import StatsComponent6 from "./stats/stats6/stats6";
import StatsComponent7 from "./stats/stats7/stats7";
import ContactsComponent1 from "./contacts/contacts1/contacts1";
import ContactsComponent2 from "./contacts/contacts2/contacts2";
import ContactsComponent3 from "./contacts/contacts3/contacts3";
import ContactsComponent4 from "./contacts/contacts4/contacts4";
import ContactsComponent5 from "./contacts/contacts5/contacts5";
import ContactsComponent6 from "./contacts/contacts6/contacts6";
import FooterComponent1 from "./footer/footer1/footer1";
import FooterComponent2 from "./footer/footer2/footer2";
import FooterComponent3 from "./footer/footer3/footer3";
import FooterComponent4 from "./footer/footer4/footer4";
import FooterComponent5 from "./footer/footer5/footer5";
import FooterComponent6 from "./footer/footer6/footer6";
import FooterComponent7 from "./footer/footer7/footer7";
import NavbarComponent1 from "./navigators/navbar1/navbar1";
import NavbarComponent2 from "./navigators/navbar2/navbar2";
import NavbarComponent3 from "./navigators/navbar3/navbar3";
import TermsOfUseModal from "./modal/terms-of-use/terms-of-use";
import NoticeModal from "./modal/notice-modal/notice";


function Registerables(composer: ComponentsRegistery) {
  composer.register([
    new DownloadComponent1(),
    new DownloadComponent2(),
    new DownloadComponent3(),
    new DownloadComponent4(),
    new DownloadComponent5(),
    new DownloadComponent6(),
    new DownloadComponent7(),
    new DownloadComponent8(),
    new DownloadComponent9(),
    new DownloadComponent10(),
    new DownloadComponent11(),
    new ListComponent1(),
    new ListComponent2(),
    new ListComponent3(),
    new ListComponent4(),
    new ContentComponent1(),
    new ContentComponent2(),
    new ContentComponent3(),
    new ContentComponent4(),
    new ContentComponent5(),
    // new ContentComponent6(),
    new ContentComponent7(),
    new ContentComponent8(),
    new ContentComponent9(),
    new ContentComponent10(),
    new SliderComponent1(),
    new SliderComponent2(),
    new SliderComponent3(),
    new SliderComponent4(),
    new SliderComponent5(),
    new SliderComponent6(),
    new SliderComponent7(),
    new PricingComponent1(),
    new PricingComponent2(),
    new PricingComponent3(),
    new PricingComponent4(),
    new PricingComponent5(),
    new PricingComponent6(),
    new PricingComponent7(),
    new FaqComponent1(),
    new FaqComponent2(),
    new FaqComponent3(),
    new FaqComponent4(),
    new FaqComponent5(),
    new FaqComponent6(),
    new TeamComponent1(),
    new TeamComponent2(),
    new TeamComponent3(),
    new TeamComponent4(),
    new TeamComponent5(),
    new TeamComponent6(),
    new TeamComponent7(),
    new TeamComponent8(),
    new TeamComponent9(),
    new TeamComponent10(),
    new TeamComponent11(),
    new TeamComponent12(),
    new TeamComponent13(),
    new TeamComponent14(),
    new HeaderComponent1(),
    new HeaderComponent2(),
    new HeaderComponent3(),
    new HeaderComponent4(),
    new HeaderComponent5(),
    new HeaderComponent6(),
    new HeaderComponent7(),
    new HeaderComponent8(),
    new HeaderComponent9(),
    new HeaderComponent10(),
    new HeaderComponent11(),
    new HeaderComponent12(),
    new NavbarComponent1(),
    new NavbarComponent2(),
    new NavbarComponent3(),
    new FeatureComponent1(),
    new FeatureComponent2(),
    new FeatureComponent3(),
    new FeatureComponent4(),
    new FeatureComponent5(),
    new FeatureComponent6(),
    new FeatureComponent7(),
    new FeatureComponent8(),
    new FeatureComponent9(),
    new FeatureComponent10(),
    new FeatureComponent11(),
    new FeatureComponent12(),
    new FeatureComponent13(),
    new FeatureComponent14(),
    new CallToAction1(),
    new CallToAction2(),
    new CallToAction3(),
    new CallToAction4(),
    new CallToAction5(),
    new CallToAction6(),
    new CallToAction7(),
    new CallToAction8(),
    new CallToAction9(),
    new CallToAction10(),
    new LogoComponent1(),
    new LogoComponent2(),
    new LogoComponent3(),
    new LogoComponent4(),
    new TestimonialsComponent1(),
    new TestimonialsComponent2(),
    new TestimonialsComponent3(),
    new TestimonialsComponent4(),
    new TestimonialsComponent5(),
    new TestimonialsComponent6(),
    new TestimonialsComponent7(),
    new StatsComponent1(),
    new StatsComponent2(),
    new StatsComponent3(),
    new StatsComponent4(),
    new StatsComponent5(),
    new StatsComponent6(),
    new StatsComponent7(),
    new ContactsComponent1(),
    new ContactsComponent2(),
    new ContactsComponent3(),
    new ContactsComponent4(),
    new ContactsComponent5(),
    new ContactsComponent6(),
    new FooterComponent1(),
    new FooterComponent2(),
    new FooterComponent3(),
    new FooterComponent4(),
    new FooterComponent5(),
    new FooterComponent6(),
    new FooterComponent7(),
    new TermsOfUseModal(),
    new NoticeModal()
  ]);
}

export default Registerables;
