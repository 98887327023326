import * as React from "react";
import styles from "./footer.module.scss";

export function Footer() {
  const logo =
    "https://storage.googleapis.com/download/storage/v1/b/hq-composer-0b0f0/o/643534d768c3c2002cd2dfdd?alt=media&timestamp=1681208536516";
  return (
    <div className={styles["main"]}>
      <div className={styles["container"]}>
        <div className={styles["left"]}>
          <img src={logo} />

          <div className={styles["links"]}>
            <div className={styles["row"]}>
              <span>Change log</span>
              <span>Privacy Policy</span>
              <span>Terms of Service</span>
            </div>
            <span>Copyright © 2023 Teknodev LTD. All rights reserved.</span>
          </div>
        </div>
        <div className={styles["right"]}>
          <div className={styles["text"]}>
            <span>Status: </span>
            <p className={styles["green"]}>Online</p>
          </div>
          <div className={styles["text"]}>
            <span>News: </span>
            <p>New components added to the library</p>
          </div>
        </div>
      </div>
    </div>
  );
}
