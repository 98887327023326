import * as React from "react";
import styles from "./analytic-track.module.scss";

import { Projects } from "../../classes/bucket";
import { FC, useContext, useState } from "react";
import { ProjectContext } from "../../contexts/project";
import { EditComponent } from "../../components/edit-component/edit-component";

type iAnalyticTrack = {
  onSave: (project: Projects) => void;
};

export const AnalyticTrack: FC<iAnalyticTrack> = ({ onSave }) => {
  const projectContext = useContext(ProjectContext);

  const [editMode, setEditMode] = useState({
    google_analytics: false,
    google_tag_manager: false,
    facebook_pixel: false,
  });
  const [data, setData] = useState({
    google_analytics:
      projectContext.project.tracking_id?.google_analytics || "",
    google_tag_manager:
      projectContext.project.tracking_id?.google_tag_manager || "",
    facebook_pixel: projectContext.project.tracking_id?.facebook_pixel || "",
  });

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const inputItem = [
    {
      label: "Google Analytics",
      name: "google_analytics",
      placeholder: "G-EC090J66YQ",
      value: data.google_analytics,
      onChange: handleInputChange,
      disabled: !editMode.google_analytics,
    },
    {
      label: "Google Tag Manager ID",
      name: "google_tag_manager",
      placeholder: "GTM-N842GS",
      value: data.google_tag_manager,
      onChange: handleInputChange,
      disabled: !editMode.google_tag_manager,
    },
    {
      label: "Facebook Pixel",
      name: "facebook_pixel",
      placeholder: "11111111",
      value: data.facebook_pixel,
      onChange: handleInputChange,
      disabled: !editMode.facebook_pixel,
    },
  ];

  const handleEditClick = (name: string) => {
    setEditMode((prevMode: any) => ({
      ...prevMode,
      [name]: true,
    }));
  };

  const handleCheckClick = (name: string) => {
    setEditMode((prevMode: any) => ({
      ...prevMode,
      [name]: false,
    }));

    const _project = {
      ...projectContext.project,
      tracking_id: {
        google_analytics: data.google_analytics,
        google_tag_manager: data.google_tag_manager,
        facebook_pixel: data.facebook_pixel,
      },
    };

    onSave(_project);
  };
  const handleCloseClick = (name: string) => {
    setEditMode((prevMode: any) => ({
      ...prevMode,
      [name]: false,
    }));
  };

  return (
    <div className={styles["tracking-ids-container"]}>
      <span>{projectContext.project.name}</span>
      {inputItem.map((item, index) => (
        <div key={`input-item-${index}`} className={styles["row"]}>
          <div className={styles["title-container"]}>
            <span>{item.label}</span>
            <span>:</span>
          </div>
          <div className={styles["input-container"]}>
            <input
              name={item.name}
              placeholder={`e.g. ${item.placeholder}`}
              type="text"
              value={item.value}
              onChange={item.onChange}
              disabled={item.disabled}
            ></input>
            <EditComponent
              disabledSave={item?.name.length < 2}
              onSave={() => {
                handleCheckClick(item.name);
              }}
              onEdit={() => {
                handleEditClick(item.name);
              }}
              onClose={() => {
                handleCloseClick(item.name);
              }}
            />
          </div>
        </div>
      ))}
    </div>
  );
};
