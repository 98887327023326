import TextField from "@mui/material/TextField";
import * as React from "react";
import { useState, useEffect } from "react";
import styles from "./password-recovery.module.scss";

interface iPasswordRecovery {
  description: string;
  onRecover: (email: string) => void;
}

export function PasswordRecovery({ description, onRecover }: iPasswordRecovery) {
  const [email, setEmail] = useState<string>()
  const [disabled, setDisabled] = useState<boolean>(true)
  const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

  useEffect(() => {
    if(emailPattern.test(email)){
      setDisabled(false)
    } else{
      setDisabled(true) 
    }
  }, [email])

  return (
    <div className={styles["password-recovery"]}>
      <p>{description}</p>
      <TextField className={styles["input"]} onChange={(_)=>setEmail(_.target.value)} label="Email" variant="outlined" />
      <button disabled={disabled} onClick={() =>onRecover(email)}>Recover</button>
    </div>
  );
}
