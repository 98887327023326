import * as React from "react";
import styles from "./authentication.module.scss";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SignIn } from "../../prefabs/sign-in/sign-in";
import { SignUp } from "../../prefabs/sign-up/sign-up";
import { UserService } from "../../services/User";
import { Authentication, signIn, signUp } from "../../services/Authentication";
import notificationService from "../../classes/NotificationService";
import { IUserContext, UserContext } from "../../contexts/user";
import { User } from "../../classes/bucket";
import { helperService } from "../../services/HelperService";

const authentication = new Authentication();

function handleForgotPassword(email: string) {
  const userService = new UserService();
  userService
    .forgotPassword(email)
    .then((res) => {
      notificationService.succesNotification(res.message);
    })
    .catch((err) => {
      const errorMessage = helperService.getErrorMessage(err);
      notificationService.errorNotification(errorMessage);
    });
}

export function AuthenticationPage() {
  const userContext = React.useContext<IUserContext>(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token) {
      navigate("/");
    }
  }, []);

  const [authenticationType, setAuthenticationType] = useState("sign-in");
  const [loading, setLoading] = useState<boolean>(false);

  const signIn = async (data: signIn) => {
    setLoading(true);
    authentication
      .singIn(data)
      .then(({ user }: { user: User }) => {
        userContext.setUser(user);
        navigate("/");
      })
      .catch((err: any) => {
        const errorMessage = helperService.getErrorMessage(err);
        notificationService.errorNotification(errorMessage);
      })
      .finally(() => setLoading(false));
  };

  const signUp = (data: signUp) => {
    setLoading(true);
    authentication
      .singUp(data)
      .then((res: { message: string }) => {
        setAuthenticationType("sign-in");
        notificationService.succesNotification(res.message);
      })
      .catch((err) => {
        const errorMessage = helperService.getErrorMessage(err);
        notificationService.errorNotification(errorMessage);
      })
      .finally(() => setLoading(false));
  };

  const continueWithGoogle = () => {
    authentication.loginWithGoogle().then(({ user }: { user: User }) => {
      userContext.setUser(user);
      navigate("/");
    });
  };

  return (
    <div className={styles["container"]}>
      <div className={styles["box"]}>
        {authenticationType == "sign-in" ? (
          <SignIn
            handleForgotPassword={handleForgotPassword}
            setAuthenticationType={setAuthenticationType}
            signIn={signIn}
            continueWithGoogle={continueWithGoogle}
            loading={loading}
            styles={styles}
          />
        ) : (
          <SignUp
            setAuthenticationType={setAuthenticationType}
            signUp={signUp}
            continueWithGoogle={continueWithGoogle}
            loading={loading}
            styles={styles}
          />
        )}
      </div>
    </div>
  );
}
